import * as React from "react";
import i18n from "../../../i18n";
import Select from "react-select";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { ShipmentDetailAPI, ShipmentAPI, ShipmentDetail } from "../../../interfaces/Shipment";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteShipmentParams, DEVICES_SHIPPING_ROUTE, FORBIDDEN_ROUTE } from "../../../const/Routes";
import { CURUS_SHIPMENT_STATE_ASSIGNED } from "../../../const/Identity";
import { BranchOffice } from "../../../interfaces/Office";
import { MESSAGE_WARNING, MESSAGE_SUCCESS } from "../../../const/Message";
import { generateShipment } from "../../../services/Shipment";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { cleanProperty } from "../../../utils/Formatter";
import { getOfficesList } from "../../../services/Office";
import { SHIPMENT_GENERATE_PERM } from "../../../const/Permission";
import { SelectOption } from "../../../interfaces/Identity";

export interface FormShipmentProps extends RouteComponentProps<RouteShipmentParams>, AppContextProps { }
export interface FormShipmentState {
    shipment: ShipmentAPI;
    branchOffices: SelectOption[];
    tableOptions: any;
    buttonCreateDisabled: boolean;
}

export class FormShipmentMain extends React.Component<FormShipmentProps, FormShipmentState>{
    correlative: number = 1;

    constructor(props: FormShipmentProps) {
        super(props);
        this.back = this.back.bind(this);
        this.handleDestinationCode = this.handleDestinationCode.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.addDetail = this.addDetail.bind(this);
        this.create = this.create.bind(this);

        this.state = {
            buttonCreateDisabled: false,
            shipment: {
                shipmentCorrelative: null,
                originCode: this.props.authUser!.branchOffice!.code,
                destinationCode: '',
                senderCorrelative: 0,
                receiverCorrelative: null,
                shipmentState: CURUS_SHIPMENT_STATE_ASSIGNED,
                sendDate: null,
                receptionDate: null,
                updateDate: new Date().getTime(),
                comment: '',
                details: [],
                types: []
            },
            branchOffices: [],
            tableOptions: {
                noDataText: i18n.t('table-empty')
            },
        }
    }

    componentDidMount() {
        const seriales: string = this.props.history.location.state.seriales;
        if (!this.props.havePermission(SHIPMENT_GENERATE_PERM) || seriales === undefined) {
            this.props.history.replace(FORBIDDEN_ROUTE);
        }

        this.props.showLoading(true);
        getOfficesList()
            .then((offices: BranchOffice[]) => {
                const notInclude: string[] = [this.props.authUser!.branchOffice!.code, process.env.REACT_APP_DEFAULT_BRANCH_OFFICE!];
                offices = offices.filter((office: BranchOffice) => !notInclude.includes(cleanProperty(office.code)));
                const branchOffices: SelectOption[] = offices.map((office: BranchOffice) => {
                    const code: string = cleanProperty(office.code);
                    const name: string = cleanProperty(office.name);
                    return { label: code + ' - ' + name, value: code };
                });
                this.setState({ branchOffices });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });

        console.log(seriales);
        const ranges: string[] = seriales.split(',');
        const details: ShipmentDetailAPI[] = [];
        ranges.forEach((range: string) => {
            const bounds: string[] = range.split('-');
            details.push({
                boxCode: '',
                initialSerialNumber: bounds[0],
                finalSerialNumber: bounds[1],
            });
        });
        this.setState(state => ({ ...state, shipment: { ...state.shipment, details } }));
    }

    back() {
        this.props.history.goBack();
    }

    addDetail() {
        const details: ShipmentDetailAPI[] = this.state.shipment.details.slice();
        const serialNumbers: string[] = [];
        if (details.some((detail: ShipmentDetailAPI) => {
            if (detail.initialSerialNumber.length === 0 ||
                detail.finalSerialNumber.length === 0 ||
                serialNumbers.includes(detail.initialSerialNumber) ||
                serialNumbers.includes(detail.finalSerialNumber)
            ) return true;

            serialNumbers.push(detail.initialSerialNumber);
            serialNumbers.push(detail.finalSerialNumber);
            return false;
        })) {
            this.props.setMessage({ message: i18n.t('shipment-detail-invalid'), type: MESSAGE_WARNING });
            return;
        }

        details.push({
            boxCode: '',
            initialSerialNumber: '',
            finalSerialNumber: '',
            deviceQuantity: 1,
            comment: '',
        });
        this.correlative = this.correlative + 1;
        this.setState(state => ({ ...state, shipment: { ...state.shipment, details } }));
    }

    handleDestinationCode(item: any) {
        const destinationCode = item.value;
        this.setState(state => ({ ...state, shipment: { ...state.shipment, destinationCode } }));
    }

    handleComment(e: any) {
        const comment = e.target.value;
        this.setState(state => ({ ...state, shipment: { ...state.shipment, comment } }));
    }

    create() {
        this.setState({ buttonCreateDisabled: true });
        this.props.showLoading(true);
        generateShipment(this.state.shipment)
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipment-generating'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.props.history.replace(DEVICES_SHIPPING_ROUTE);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {
        let isDisabled = false;
        return (
            <div>
                <div className="card overflow-card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('shipment-generate')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('origin')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" name="originCode" className="form-control" id="originCode" readOnly={true} defaultValue={this.props.authUser!.branchOffice!.code} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('destination')}:</label>
                                <div className="col-sm-4">
                                    <Select onChange={this.handleDestinationCode} placeholder={i18n.t('option-select')} options={this.state.branchOffices} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('comment')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" name="comment" className="form-control" id="comment" onChange={this.handleComment} value={this.state.shipment.comment} />
                                </div>
                            </div>
                            <br />
                        </form>
                    </div>
                </div>
                {this.state.shipment && <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('details')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <button className="btn btn-primary" onClick={this.addDetail}>{i18n.t('add')}</button>
                        <button className="btn btn-primary">{i18n.t('remove')}</button>
                        <br />
                        <BootstrapTable
                            data={this.state.shipment.details}
                            bordered={true}
                            cellEdit={{ mode: 'click', blurToSave: true }}
                            options={this.state.tableOptions}
                            selectRow={{ mode: 'radio' }}>
                            <TableHeaderColumn dataField='boxCode' dataSort={true} width="150" editable={true}>{i18n.t('box-code')}</TableHeaderColumn>
                            <TableHeaderColumn isKey={true} dataField='initialSerialNumber' dataSort={true} editable={false} dataAlign={'center'} width="150">{i18n.t('serial-number-initial')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='finalSerialNumber' dataSort={true} editable={false} dataAlign={'center'} width="150">{i18n.t('serial-number-final')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>}
                <div className="card">
                    <div className="card-body">
                        <button type="button" onClick={this.back} className="btn btn-info">{i18n.t('back')}</button>
                        {this.props.havePermission(SHIPMENT_GENERATE_PERM) && <button disabled={isDisabled} type="button" onClick={this.create} className="btn btn-info">{i18n.t('create')}</button>}
                    </div>
                </div>
            </div>
        )
    }
}

export const FormShipment = withAppContext(FormShipmentMain);