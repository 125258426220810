import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BASE_PERM } from "../../../const/Permission";
import { BalanceAdd } from "./Add";
import { BalanceList } from "./List";

export interface BalanceDeviceProps extends RouteComponentProps, AppContextProps{ }
export interface BalanceDeviceState {
    step: number;
 }

export class BalanceDeviceMain extends React.Component<BalanceDeviceProps, BalanceDeviceState>{
    reachTheLastPage: boolean = false;
    constructor(props: BalanceDeviceProps) {
        super(props);
        this.state = {
            step: 0
        };
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(BASE_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('make-balance')}</a></li>}
                        {this.props.havePermission(BASE_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('see-balances')}</a></li>}
                    </ul>
                </div>
                {this.state.step === 0 && this.props.havePermission(BASE_PERM) &&
                <BalanceAdd/>
                }
                {this.state.step === 1 && this.props.havePermission(BASE_PERM) &&
                <BalanceList/>
                }
            </div>
        )
    }
}

export const BalanceDevice = withAppContext(BalanceDeviceMain);
