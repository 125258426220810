import * as React from "react";
import i18n from "../../i18n";
import { LicensesList } from "./Licenses";
import { SystemParametersList } from "./Parameters";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { SYSTEM_PARAMETERS_LIST_PERM, LICENSE_LIST_PERM, EQUIVALENCE_PERM } from "../../const/Permission";
import { EquivalenceList } from "./equivalence/List";

export interface SystemProps extends RouteComponentProps, AppContextProps { }

export interface SystemState {
    step: number;
}

export class SystemMain extends React.Component<SystemProps, SystemState> {
    lockGeneralRequests: boolean = false;
    lockSystemRequests: boolean = false;

    constructor(props: SystemProps) {
        super(props);

        this.state = {
            step: -1,
        }
    }

    componentDidMount() {
        var step = -1;

        if (this.props.havePermission(EQUIVALENCE_PERM)) step = 0;
        if (step === -1 && this.props.havePermission(SYSTEM_PARAMETERS_LIST_PERM)) step = 1;

        this.setState({ step });
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(EQUIVALENCE_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('cw-equivalence')}</a></li>}
                        {/*this.props.havePermission(SYSTEM_PARAMETERS_LIST_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('system-parameters')}</a></li>*/}
                        {/*this.props.havePermission(LICENSE_LIST_PERM) && <li className={this.state.step === 2 ? "active" : ""}><a onClick={() => this.setStep(2)} href="#" className="nav-link" >{i18n.t('system-licenses')}</a></li>*/}
                    </ul>
                </div>
                <div className="panel-body">
                    {this.state.step === 0 && this.props.havePermission(EQUIVALENCE_PERM) && <EquivalenceList />}
                    {/*this.state.step === 1 && this.props.havePermission(SYSTEM_PARAMETERS_LIST_PERM) && <SystemParametersList />*/}
                    {/*this.state.step === 2 && this.props.havePermission(LICENSE_LIST_PERM) && <LicensesList />*/}
                </div>
            </div>
        )
    }
}

export const System = withAppContext(SystemMain);