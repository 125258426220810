import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { SideMenuItem } from "../../interfaces/Menu";
import { USER_ID_PARAM, USER_GROUP_PARAM, ROLE_ID_PARAM, RouteAllParams, OFFICE_ID_PARAM ,DOCUMENT_ID_PARAM, DEVICETYPE_ID_PARAM} from "../../const/Routes";

export interface ItemProps extends RouteComponentProps<RouteAllParams> {
    item: SideMenuItem;
    handleChangeClick: Function;
    alone?: boolean;
}
export interface ItemState {
    finalPath: string;
}

export class ItemMain extends React.Component<ItemProps, ItemState> {
    constructor(props: ItemProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.select = this.select.bind(this);

        const finalPath = this.replaceParams(this.props.item.path);
        this.state = { finalPath }
    }

    handleClick(e: any): void {
        e.preventDefault();
        const menuId = e.currentTarget.id;
        this.props.handleChangeClick(menuId);
    }

    select() {
        return this.props.location.pathname === this.state.finalPath || this.props.item.select.some(path => {
            return this.replaceParams(path) === this.props.location.pathname;
        });
    }

    replaceParams(path: string): string {
        if (this.props.match.params.userGroup) path = path.replace(USER_GROUP_PARAM, this.props.match.params.userGroup);
        if (this.props.match.params.userId) path = path.replace(USER_ID_PARAM, this.props.match.params.userId);
        if (this.props.match.params.roleId) path = path.replace(ROLE_ID_PARAM, this.props.match.params.roleId);
        if (this.props.match.params.officeId) path = path.replace(OFFICE_ID_PARAM, this.props.match.params.officeId);
        if (this.props.match.params.docId) path = path.replace(DOCUMENT_ID_PARAM, this.props.match.params.docId);
        if (this.props.match.params.deviceTypeId) path = path.replace(DEVICETYPE_ID_PARAM, this.props.match.params.deviceTypeId);
        return path;
    }

    render() {
        return (
            <li onClick={this.handleClick} id={this.props.item.id} className={this.select() ? "active" : ""}>
                <Link to={this.state.finalPath}>
                    {this.props.alone && <span className={"icon fa " + this.props.item.icon} ></span>}
                    <span className="title">{this.props.item.text}</span>
                </Link>
            </li>
        )
    }
}

export const Item = withRouter(ItemMain);
