import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import {
    STATE_OFFICE_REPORT_ROUTE, CONTROL_REPORTS_ROUTE, FORBIDDEN_ROUTE
} from "../../const/Routes";
import { REPORTS_AND_CONTROL_MENU_ITEMS } from "../../const/Menu";
import { MainMenu } from "../../components/MainMenu";
import { StateOffice } from "../../components/reports/StateOffice";
import { ReportContextProps, ReportContextProvider } from "../../context/ReportContext";

interface ReportContainerProps extends RouteComponentProps, AppContextProps { }
interface ReportContainerState {
    reportContext: ReportContextProps;
}

export class ReportContainerMain extends React.Component<ReportContainerProps, ReportContainerState> {
    constructor(props: ReportContainerProps) {
        super(props);

        this.state = {
            reportContext: {
                branchOffices: []
            }
        }
    }

    render() {
        return (
            <ReportContextProvider value={this.state.reportContext}>
                <Switch>
                    <Route path={STATE_OFFICE_REPORT_ROUTE} exact component={StateOffice} />
                    <Route path={CONTROL_REPORTS_ROUTE} render={() => <MainMenu menuItems={REPORTS_AND_CONTROL_MENU_ITEMS} />} />
                    <Redirect to={FORBIDDEN_ROUTE} />
                </Switch>
            </ReportContextProvider>

        )
    }

}
export const ReportContainer = withAppContext(ReportContainerMain);