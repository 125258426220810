import * as React from "react";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router";
import i18n from "../../i18n";
import { AppContextProps, withAppContext } from "./../../context/AppContext";
import { ALL_EVENT_TABLE_COLUMNS, EVENTS_TABLE_COLUMN_WIDTH, EVENTS_TABLE_COLUMN_ALIGN, EVENTS_TABLE_HEADERS, SYSTEM_EVENT_TABLE_COLUMNS, SUMMARY_EVENT_TABLE_COLUMNS } from "../../const/Event";
import { GeneralEvent, SearchEvent } from "../../interfaces/Event";
import { getAuditData, GENERAL_EVENT_AMOUNT_PER_REQUEST, getAuditSystemData } from "../../services/Event";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { getTimestamp } from "../../utils/Formatter";
import { TABLE_ROW_SELECTION } from "../../const/Table";
import { EventFilter } from "./Filter";
import { EVENTS_GENERAL_PERM, EVENTS_SYSTEM_PERM } from "../../const/Permission";

export interface AuditProps extends RouteComponentProps, AppContextProps { }

export interface AuditState {
    step: number;
    generalEvents: GeneralEvent[];
    systemEvents: GeneralEvent[];
    tableOptions: any;
}

export class AuditMain extends React.Component<AuditProps, AuditState> {
    lockGeneralRequests: boolean = false;
    lockSystemRequests: boolean = false;

    constructor(props: AuditProps) {
        super(props);
        this.getFileName = this.getFileName.bind(this);
        this.getGeneralEvents = this.getGeneralEvents.bind(this);
        this.getSystemEvents = this.getSystemEvents.bind(this);

        this.state = {
            step: 0,
            generalEvents: [],
            systemEvents: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1,
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('csv-save')} btnContextual='btn-info' onClick={onClick} /> }
            }
        }
    }

    componentWillMount() {
        //setear la fecha de  busqueda por defecto
        var curr = new Date();
        var date = curr.toISOString().substr(0, 10);
        curr.setDate(curr.getDate()+1);
        var tomorrow = curr.toISOString().substr(0, 10);

        if (this.props.havePermission(EVENTS_GENERAL_PERM)) this.getGeneralEvents({ firstResult: 0, startDate: date, endDate: tomorrow });
        if (this.props.havePermission(EVENTS_SYSTEM_PERM)) this.getSystemEvents({ firstResult: 0, startDate: date, endDate: tomorrow });
    }

    componentDidMount() {
        const step = this.props.havePermission(EVENTS_GENERAL_PERM) ? 0 : 1;
        this.setState({ step });
    }

    getGeneralEvents(search: SearchEvent) {
        this.props.showLoading(true);
        getAuditData(search)
            .then((generalEvents: GeneralEvent[]) => {
                this.setState({ generalEvents });
                this.props.showLoading(false);
                if (generalEvents.length < GENERAL_EVENT_AMOUNT_PER_REQUEST) this.lockGeneralRequests = true;
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    getSystemEvents(search: SearchEvent) {
        /*
        this.props.showLoading(true);
        getAuditSystemData({ firstResult: 0 })
            .then((systemEvents: GeneralEvent[]) => {
                this.setState({ systemEvents });
                this.props.showLoading(false)
                if (systemEvents.length < GENERAL_EVENT_AMOUNT_PER_REQUEST) this.lockSystemRequests = true;
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
            */
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    onGeneralPageChange(page: number, sizePerPage: number) {
        const pages = Math.floor(this.state.generalEvents.length / sizePerPage) + (this.state.generalEvents.length % GENERAL_EVENT_AMOUNT_PER_REQUEST === 0 ? 0 : 1);
        if (page === pages && !this.lockGeneralRequests) {
            this.props.showLoading(this.lockGeneralRequests = true);
            getAuditData({ firstResult: this.state.generalEvents.length })
                .then((registries: GeneralEvent[]) => {
                    if (registries.length > 0) {
                        this.setState(state => {
                            return { generalEvents: [...state.generalEvents, ...registries] }
                        }, () => {
                            if (registries.length === GENERAL_EVENT_AMOUNT_PER_REQUEST) this.lockGeneralRequests = false;
                            this.props.showLoading(false);
                        });
                    } else {
                        this.props.showLoading(false);
                    }
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    onSystemPageChange(page: number, sizePerPage: number) {
        const pages = Math.floor(this.state.systemEvents.length / sizePerPage) + (this.state.systemEvents.length % GENERAL_EVENT_AMOUNT_PER_REQUEST === 0 ? 0 : 1);
        if (page === pages && !this.lockSystemRequests) {
            this.props.showLoading(this.lockSystemRequests = true);
            getAuditSystemData({ firstResult: this.state.systemEvents.length })
                .then((registries: GeneralEvent[]) => {
                    if (registries.length > 0) {
                        this.setState(state => {
                            return { systemEvents: [...state.systemEvents, ...registries] }
                        }, () => {
                            if (registries.length === GENERAL_EVENT_AMOUNT_PER_REQUEST) this.lockSystemRequests = false;
                            this.props.showLoading(false);
                        });
                    } else {
                        this.props.showLoading(false);
                    }
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    getFileName() {
        return 'events_' + getTimestamp() + '.csv';
    }

    render() {
        const generalColumns: JSX.Element[] = SUMMARY_EVENT_TABLE_COLUMNS.map((colName, index) => {
            return <TableHeaderColumn
                isKey={colName === 'eventCorrelative'}
                dataField={colName}
                dataSort={true}
                width={EVENTS_TABLE_COLUMN_WIDTH[colName]}
                headerAlign="center"
                dataAlign={EVENTS_TABLE_COLUMN_ALIGN[colName]}
                csvHeader={i18n.t(EVENTS_TABLE_HEADERS[colName])}
                key={index}
                row={1}>
                {i18n.t(EVENTS_TABLE_HEADERS[colName])}
            </TableHeaderColumn>
        });
        const generalBody: JSX.Element[] = [
            <TableHeaderColumn key="h0" headerAlign="center" row={0} colSpan={5} export={false}>{i18n.t('event')}</TableHeaderColumn>,
            <TableHeaderColumn key="h5" headerAlign="center" row={0} colSpan={2} export={false}>{i18n.t('branch-office')}</TableHeaderColumn>,
            <TableHeaderColumn key="h6" headerAlign="center" row={0} colSpan={1} export={false}>{i18n.t('channel')}</TableHeaderColumn>,
            ...generalColumns
        ];

        const systemColumns: JSX.Element[] = SYSTEM_EVENT_TABLE_COLUMNS.map((colName, index) => {
            return <TableHeaderColumn
                isKey={colName === 'eventCorrelative'}
                dataField={colName}
                dataSort={true}
                width={EVENTS_TABLE_COLUMN_WIDTH[colName]}
                headerAlign="center"
                dataAlign={EVENTS_TABLE_COLUMN_ALIGN[colName]}
                csvHeader={i18n.t(EVENTS_TABLE_HEADERS[colName])}
                key={index}
                row={1}>
                {i18n.t(EVENTS_TABLE_HEADERS[colName])}
            </TableHeaderColumn>
        });
        const systemBody: JSX.Element[] = [
            <TableHeaderColumn key="h0" headerAlign="center" row={0} colSpan={5} export={false}>{i18n.t('event')}</TableHeaderColumn>,
            <TableHeaderColumn key="h6" headerAlign="center" row={0} colSpan={3} export={false}>{i18n.t('channel')}</TableHeaderColumn>,
            ...systemColumns
        ];

        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(EVENTS_GENERAL_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('events-users')}</a></li>}
                        {/*this.props.havePermission(EVENTS_SYSTEM_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('events-system')}</a></li>*/}
                    </ul>
                </div>
                <div className="panel-body">
                    <EventFilter filter={this.state.step === 0 ? this.getGeneralEvents : this.getSystemEvents} step={this.state.step} />
                    <div className="card margin-card-top">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('recent-activity')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {this.state.step === 0 && this.props.havePermission(EVENTS_GENERAL_PERM) &&
                                <BootstrapTable
                                    data={this.state.generalEvents}
                                    options={{
                                        ...this.state.tableOptions,
                                        onPageChange: this.onGeneralPageChange.bind(this)
                                    }}
                                    bordered={true}
                                    pagination hover
                                    csvFileName={this.getFileName()}
                                    exportCSV
                                    selectRow={TABLE_ROW_SELECTION}>
                                    {generalBody}
                                </BootstrapTable>}
                            {/*this.state.step === 1 && this.props.havePermission(EVENTS_SYSTEM_PERM) &&
                                <BootstrapTable
                                    data={this.state.systemEvents}
                                    options={{
                                        ...this.state.tableOptions,
                                        onPageChange: this.onSystemPageChange.bind(this)
                                    }}
                                    bordered={true}
                                    pagination hover
                                    csvFileName={this.getFileName()}
                                    exportCSV
                                    selectRow={TABLE_ROW_SELECTION}>
                                    {systemBody}
                                </BootstrapTable>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const Audit = withAppContext(AuditMain);