import { NotificationAPI, Notification } from "../interfaces/Notification";
import i18n from "../i18n";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { API_PREFIX } from "../const/Routes";

export function getNotifications(): Promise<Notification[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/notifications' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getNotifications");
            return response.data.map(parseNotification);
        })
        .catch(apiErrorCallback);
}

export function markNotificationAsRead(noticeId: number): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/notifications/' + noticeId + '/read',
    })
        .then(() => {
            console.log('[OK] markNotificationAsRead');
            return true;
        })
        .catch(apiErrorCallback);
}

function parseNotification(notificationAPI: NotificationAPI): Notification {
    return {
        notificationCorrelative: notificationAPI.notificationCorrelative,
        notificationDate: new Date(notificationAPI.notificationDate),
        message: notificationAPI.message || i18n.t('no-data'),
        action: notificationAPI.action,
        read: notificationAPI.read,
        readDate: notificationAPI.read ? new Date(notificationAPI.readDate) : i18n.t('no-data'),
    }
}
