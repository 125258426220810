export const IDENTITY_CONFIG: any = require('../config/' + process.env.REACT_APP_ENV + '.json');

export const STATE_CURRENT = 'ACTIVE';
export const STATE_CANCELED = 'DELETED';
export const STATE_CANCELED_2 = 'CANCELED';
export const STATE_HOLD = 'LOCKED';
export const STATE_HOLD_PENDING = 'IN_ACTIVATION';
export const STATE_PENDING = 'PENDING';
export const STATE_UNAVAILABLE = 'UNAVAILABLE';

export const CURUS_DEVICE_ANY = 'ALL';
export const CURUS_DEVICE_GRID = 'GRID';
export const CURUS_DEVICE_PASSWORD = 'PASSWORD';
export const CURUS_DEVICE_SYSTEM = 'SYSTEM';
export const CURUS_DEVICE_TOKEN = 'TOKEN';
export const CURUS_DEVICE_TOKENRO = 'TOKENRO';

export const CURUS_SHIPMENT_STATE_GENERATED = 'GT';
export const CURUS_SHIPMENT_STATE_GENERATING = 'GN';
export const CURUS_SHIPMENT_STATE_ASSIGNED = 'AG';
export const CURUS_SHIPMENT_STATE_REQUESTED = 'RQ';
export const CURUS_SHIPMENT_STATE_SENT = 'ST';
export const CURUS_SHIPMENT_STATE_RECEIVED = 'RV';
export const CURUS_SHIPMENT_STATE_RECEIVING = 'RN';
export const CURUS_SHIPMENT_STATE_CANCELED = 'CD';

export const CURUS_CREDIT_CARD_GOLD = 'GOLD';
export const CURUS_CREDIT_CARD_PLATINUM = 'PLATINUM';
export const CURUS_CREDIT_CARD_DIAMOND = 'DIAMOND';

export const EXTERNAL_EVENTS_SANTANDER = 'acl';

export const CLIENT_NOT_EXIST_CODE = 1104;

export const LANGUAGE_ES = 'es_ES';
export const LANGUAGE_EN = 'en_EN';

export const COLUMM_STICKY_STYLE = { 
    position: 'sticky', 
    zIndex: 2, 
    backgroundColor: 'white' 
};
