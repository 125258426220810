import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { API_PREFIX } from "../const/Routes";
import { AxiosResponse } from "axios";
import { DeviceType, DeviceTypeAPI, DeviceTypeConfiguration } from "../interfaces/DeviceType";
import { OfficesByDeviceType } from "../interfaces/Shipment";

export function parseDeviceType(deviceTypeFromAPI: DeviceTypeAPI): DeviceType {
    return {
        codeDeviceType: deviceTypeFromAPI.code,
        nameDeviceType: deviceTypeFromAPI.name,
        available: deviceTypeFromAPI.available
    }
}

export function parseDeviceTypeAPI(deviceType: DeviceType): DeviceTypeAPI {
    return {
        code: deviceType.codeDeviceType,
        name: deviceType.nameDeviceType,
        available: deviceType.available
    }
}

export function getDeviceTypeList(): Promise<DeviceType[]> {
    return helperAxios(API_PREFIX + '/devices/types')
        .then((response: AxiosResponse) => {
            console.log('[OK] getDeviceTypeList');
            return response.data.map(parseDeviceType);
        })
        .catch(apiErrorCallback);
}

export function getDeviceTypeConfigurationsByType(deviceTypeId: string): Promise<DeviceTypeConfiguration> {
    return helperAxios(API_PREFIX + '/devices/types/' + deviceTypeId)
        .then((response: AxiosResponse) => {
            console.log('[OK] getDeviceTypeConfigurationsByType');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function updateDeviceType(deviceType: DeviceType): Promise<boolean> {
    const deviceTypeAPI: DeviceTypeAPI = parseDeviceTypeAPI(deviceType);
    return helperAxios({
        method: 'POST',
        url: API_PREFIX + '/devices/types/update/' + deviceTypeAPI.code,
        data: deviceTypeAPI
    })
        .then(() => {
            console.log("[OK] updateDeviceType");
            return true;
        })
        .catch(apiErrorCallback);
}

export function getAssignedOffices(deviceTypeId: string): Promise<OfficesByDeviceType> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/devices/types/' + deviceTypeId + '/offices'
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getAssignedOffices');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function createDeviceType(deviceType: DeviceType): Promise<boolean> {
    const deviceTypeAPI: DeviceTypeAPI = parseDeviceTypeAPI(deviceType);
    return helperAxios({
        method: 'POST',
        url: API_PREFIX + '/devices/types/create',
        data: deviceTypeAPI
    })
        .then(() => {
            console.log("[OK] createDeviceType");
            return true;
        })
        .catch(apiErrorCallback);
}

export function assignOffices(officesByDeviceType: OfficesByDeviceType): Promise<boolean> {
    return helperAxios({
        method: 'POST',
        url: API_PREFIX + '/devices/types/offices',
        data: officesByDeviceType
    })
        .then(() => {
            console.log("[OK] assignOffices");
            return true;
        })
        .catch(apiErrorCallback);
}