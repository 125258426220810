import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { RolePermissionGroup, Role, RolePermission } from "../../interfaces/Role";
import { getRole, updateRole, getPermissionGroups, createNewRole } from "../../services/Role";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { ROLES_ROUTE, RouteRoleParams, ROLE_SHOW_ROUTE, ROLE_MODIFY_ROUTE } from "../../const/Routes";
import { PermissionGroup } from "./permission/PermissionGroup";
import i18n from "../../i18n";
import { AdminCodeRole } from "../../utils/Config";
import { ROLE_DELETE_PERM } from "../../const/Permission";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../const/Message";

interface FormRoleProps extends RouteComponentProps<RouteRoleParams>, AppContextProps { }
interface FormRoleState {
    permissionGroups: RolePermissionGroup[];
    allPermissions: RolePermission[];
    codeRole: string;
    roleName: string;
    externalRoleName: string,
    needTwoFactorAuth: boolean,
    rolePermissions: RolePermission[];
    roleActive: boolean;
    prevRole: Role | undefined;
}

export class FormRoleMain extends React.Component<FormRoleProps, FormRoleState>{
    constructor(props: FormRoleProps) {
        super(props);
        this.back = this.back.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.fetchPermissionGroups = this.fetchPermissionGroups.bind(this);
        this.handleInputRoleName = this.handleInputRoleName.bind(this);
        this.handleInputCodeRole = this.handleInputCodeRole.bind(this);
        this.handleInputRoleActive = this.handleInputRoleActive.bind(this);
        this.addPermissions = this.addPermissions.bind(this);
        this.removePermissions = this.removePermissions.bind(this);

        this.handleInputNeedTwoFactorAuth = this.handleInputNeedTwoFactorAuth.bind(this);
        this.handleInputExternalName = this.handleInputExternalName.bind(this);

        this.state = {
            permissionGroups: [],
            allPermissions: [],
            codeRole: '',
            roleName: '',
            externalRoleName: '',
            needTwoFactorAuth: false,
            rolePermissions: [],
            roleActive: true,
            prevRole: undefined,
        }
    }

    componentDidMount() {
        this.fetchPermissionGroups();
        if (this.props.match.params.roleId) this.fetchRole();
    }

    fetchRole() {
        this.props.showLoading(true);
        getRole(this.props.match.params.roleId)
            .then((prevRole: Role) => {
                const rolePermissions: RolePermission[] = prevRole ? prevRole.permisions : []
                this.setState({ prevRole, rolePermissions, roleActive: prevRole.roleActive, externalRoleName: prevRole.externalRoleName, needTwoFactorAuth: prevRole.needTwoFactorAuth }, () => this.props.showLoading(false));
                if (prevRole.codeRole === AdminCodeRole) {
                    this.props.setMessage({ message: i18n.t('role-modify-forbidden', { role: prevRole.roleName }), type: MESSAGE_WARNING });
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    fetchPermissionGroups() {
        this.props.showLoading(true);
        getPermissionGroups()
            .then((permissionGroups: RolePermissionGroup[]) => {
                const allPermissions: RolePermission[] = [];
                permissionGroups.forEach(group => allPermissions.push(...group.permissions));
                this.setState({ permissionGroups, allPermissions }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    back() {
        this.props.history.push(ROLES_ROUTE);
    }

    create() {
        if (this.state.codeRole.length === 5 && this.state.roleName !== "" && this.state.rolePermissions.length !== 0) {
            this.props.showLoading(true);
            const permisions = this.state.rolePermissions.map(item => item.permissionCode)
            createNewRole(this.state.codeRole, this.state.roleName, permisions, this.state.externalRoleName, this.state.needTwoFactorAuth)
                .then((response: boolean) => {
                    if (response) {
                        this.props.setMessage({ message: i18n.t('role-created'), show: true, type: MESSAGE_SUCCESS });
                        this.props.history.push(ROLES_ROUTE);
                    }
                    this.props.showLoading(false);
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        } else if (this.state.rolePermissions.length === 0) {
            this.props.setMessage({ message: i18n.t('role-permissions-set'), show: true, type: MESSAGE_WARNING });
        } else {
            this.props.setMessage({ message: i18n.t('role-code-invalid'), show: true, type: MESSAGE_WARNING });
        }
    }

    update() {
        this.props.showLoading(true);
        const permisions = this.state.rolePermissions.map(item => item.permissionCode)
        updateRole(this.state.prevRole!.codeRole, this.state.prevRole!.roleName, this.state.roleActive, permisions, this.state.externalRoleName, this.state.needTwoFactorAuth)
            .then((response: boolean) => {
                this.props.history.push(ROLES_ROUTE);
                this.props.setMessage({ message: i18n.t('role-updated'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    handleInputCodeRole(e: any): void {
        e.preventDefault();
        const codeRole = e.target.value;
        this.setState(state => ({ ...state, codeRole }));
    }

    handleInputRoleName(e: any): void {
        e.preventDefault();
        const roleName = e.target.value;
        this.setState(state => ({ ...state, roleName }));
    }

    handleInputRoleActive(e: any): void {
        const roleActive = e.target.checked;
        this.setState({ roleActive });
    }

    handleInputNeedTwoFactorAuth(e: any): void {
        const needTwoFactorAuth = e.target.checked;
        this.setState({ needTwoFactorAuth });
    }

    handleInputExternalName(e: any): void {
        const externalRoleName = e.target.value;
        this.setState({ externalRoleName });
    }

    addPermissions(permissionGroups: RolePermission[]) {
        const rolePermissions: RolePermission[] = [...this.state.rolePermissions, ...permissionGroups];
        this.setState({ rolePermissions });
    }

    removePermissions(permissionGroups: RolePermission[]) {
        const rolePermissions: RolePermission[] = this.state.rolePermissions.filter(current => {
            return permissionGroups.find(permission => current.permissionCode === permission.permissionCode) === undefined
        });
        this.setState({ rolePermissions });
    }

    render() {
        const permission: JSX.Element[] = this.state.permissionGroups.map((group: RolePermissionGroup) => {
            return <PermissionGroup
                key={group.permissionType}
                prevPermissions={this.state.rolePermissions}
                allPermissions={this.state.allPermissions}
                permissionGroup={group}
                addPermissions={this.addPermissions}
                removePermissions={this.removePermissions} />
        });

        return (
            <div>
                <div className="card">
                    <div className="card-body form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">{i18n.t('code')}: </label>
                            <div className="col-sm-2">
                                <input type="text" readOnly={this.state.prevRole !== undefined} name="codeRole" className="form-control" id="codeRole" maxLength={5}
                                    onChange={this.handleInputCodeRole} defaultValue={this.state.prevRole ? this.state.prevRole.codeRole : ''}></input>
                            </div>
                            <label className="col-sm-2 control-label">{i18n.t('name')}: </label>
                            <div className="col-sm-2">
                                <input type="text" readOnly={this.state.prevRole !== undefined} name="roleName" className="form-control" id="roleName"
                                    onChange={this.handleInputRoleName} defaultValue={this.state.prevRole ? this.state.prevRole.roleName : ''}></input>
                            </div>
                            <label className="col-sm-1 control-label">{i18n.t('active')}: </label>
                            <div className="col-sm-1">
                                <div className="form-control">
                                    {this.props.havePermission(ROLE_DELETE_PERM) && this.state.prevRole !== undefined && this.state.prevRole.codeRole !== AdminCodeRole && this.props.match.path === ROLE_MODIFY_ROUTE && <input type="checkbox" name="roleActive" id="roleActive"
                                        onChange={this.handleInputRoleActive} checked={this.state.roleActive}></input>}
                                    {this.state.roleActive ? i18n.t('yes') : i18n.t('no')}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('permissions')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-horizontal">
                            {permission}
                            <br />
                            <div className="form-group">
                                <div className="col-sm-offset-2 col-sm-10">
                                    <button type="button" onClick={this.back} className="btn btn-primary">{i18n.t('back')}</button>
                                    {this.props.match.path !== ROLE_SHOW_ROUTE &&
                                        <button type="button" onClick={this.state.prevRole ? this.update : this.create} className="btn btn-info" disabled={this.state.prevRole && this.state.prevRole.codeRole === AdminCodeRole}>
                                            {this.state.prevRole ? i18n.t('update') : i18n.t('create')}
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const FormRole = withAppContext(FormRoleMain);