import { AxiosResponse } from "axios";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { EventAPI, GeneralEvent, SoftTokenEvent, UserEvent, CardEvent, PasswordEvent, SearchEvent, ShipmentEvent, ShipmentEventAPI, CardEventExternal } from "../interfaces/Event";
import { cleanProperty, formatUserId } from "../utils/Formatter";
import i18n from "../i18n";
import { SOFTTOKEN_STATES } from "../const/SoftToken";
import { TYPE_USER, USER_API, CLIENT_API, API_PREFIX } from "../const/Routes";
import { DEVICE_TYPES } from "../const/Device";
import { CARD_STATES } from "../const/GridCard";
import { PASSWORD_STATES } from "../const/Password";
import { EVENT_STATES, EVENT_ACTIONS } from "../const/Event";
import { DOCUMENT_TYPES } from "../const/Document";
import { CURUS_DEVICE_TOKEN, CURUS_DEVICE_GRID, CURUS_DEVICE_PASSWORD, CURUS_DEVICE_ANY, CURUS_DEVICE_SYSTEM } from "../const/Identity";
import { SHIPMENT_STATES } from "../const/Shipment";

export const GENERAL_EVENT_AMOUNT_PER_REQUEST = 300;
export const SOFTTOKEN_EVENT_AMOUNT_PER_REQUEST = 20;
export const CARD_EVENT_AMOUNT_PER_REQUEST = 20;
export const USER_EVENT_AMOUNT_PER_REQUEST = 100;

export function getAuditData(search: SearchEvent): Promise<GeneralEvent[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/events/',
        data: {
            ...search,
            maxResults: search.maxResults || GENERAL_EVENT_AMOUNT_PER_REQUEST,
            deviceType: search.challengeType || CURUS_DEVICE_ANY,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getAuditData');
            return response.data.map(parseEvent);
        })
        .catch(apiErrorCallback);
}

export function getAuditSystemData(search: SearchEvent): Promise<GeneralEvent[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/events/system',
        data: { ...search, deviceType: CURUS_DEVICE_SYSTEM, maxResults: search.maxResults || GENERAL_EVENT_AMOUNT_PER_REQUEST }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getAuditSystemData');
            return response.data.map(parseEvent);
        })
        .catch(apiErrorCallback);
}

export function getSoftTokenEvents(userId: string, group: string, userType: string, index: number, amount?: number): Promise<SoftTokenEvent[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API;
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId.toUpperCase() + '/softtokens/events',
        data: {
            maxResults: amount || SOFTTOKEN_EVENT_AMOUNT_PER_REQUEST,
            firstResult: index,
            deviceType: CURUS_DEVICE_TOKEN,
            genericSearch: null,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getSoftTokenEvents');
            return response.data.map(parseSoftTokenEvent);
        })
        .catch(apiErrorCallback);
}

export function getCardEvents(userId: string, group: string, userType: string, index: number, amount?: number): Promise<CardEvent[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API;
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId + '/gridcard/event',
        data: {
            maxResults: amount || CARD_EVENT_AMOUNT_PER_REQUEST,
            firstResult: index,
            deviceType: CURUS_DEVICE_GRID,
            genericSearch: null,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getCardEvents');
            return response.data.map(parseCardEvent);
        })
        .catch(apiErrorCallback);
}

export function getCardEventsExternal(userId: string, group: string, userType: string, index: number, amount?: number): Promise<CardEventExternal[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API;
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId + '/gridcard/event/external',
        data: {
            maxResults: amount || CARD_EVENT_AMOUNT_PER_REQUEST,
            firstResult: index,
            deviceType: CURUS_DEVICE_GRID,
            genericSearch: null,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getCardEventsExternal');
            return response.data.map(parseCardEventExternal);
        })
        .catch(apiErrorCallback);
}

export function getPasswordEvents(userId: string, group: string, userType: string, index: number, amount?: number): Promise<PasswordEvent[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API;
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId + '/passwords/events',
        data: {
            maxResults: amount || CARD_EVENT_AMOUNT_PER_REQUEST,
            firstResult: index,
            deviceType: CURUS_DEVICE_PASSWORD,
            genericSearch: null,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getPasswordEvents');
            return response.data.map(parsePasswordEvent);
        })
        .catch(apiErrorCallback);
}

export function getUserEvents(userId: string, group: string, userType: string, index: number): Promise<UserEvent[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId.toUpperCase() + '/events',
        data: {
            maxResults: USER_EVENT_AMOUNT_PER_REQUEST,
            firstResult: index,
            deviceType: CURUS_DEVICE_ANY,
            genericSearch: null,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getUserEvents');
            return response.data.map(parseUserEvent);
        })
        .catch(apiErrorCallback);
}

export async function getShipmentsEvents(): Promise<ShipmentEvent[]> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/shipments/events'
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getShipmentsEvents');
            return response.data.map(parseShipmentEvents);
        })
        .catch(apiErrorCallback);
}

export async function getShipmentEvents(shipmentCorrelative: number): Promise<ShipmentEvent[]> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/shipments/' + shipmentCorrelative + '/events'
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getShipmentEvents');
            return response.data.map(parseShipmentEvents);
        })
        .catch(apiErrorCallback);
}

export async function getStockLoadEvents(): Promise<ShipmentEvent[]> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/shipments/events/load'
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getStockLoadEvents');
            return response.data.map(parseShipmentEvents);
        })
        .catch(apiErrorCallback);
}

export function getUserEventsGeneric(userId: string, group: string, userType: string, search: SearchEvent): Promise<UserEvent[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId.toUpperCase() + '/events',
        data: {
            ...search,
            maxResults: search.maxResults || GENERAL_EVENT_AMOUNT_PER_REQUEST,
            deviceType: search.challengeType || CURUS_DEVICE_ANY,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getUserEventsGeneric');
            return response.data.map(parseUserEvent);
        })
        .catch(apiErrorCallback);
}

function parseEvent(eventAPI: EventAPI): GeneralEvent {
    const eventActionDate: Date = new Date(eventAPI.eventActionDate);
    const cleanUserId: string = cleanProperty(eventAPI.userId);
    const userId: string = formatUserId(cleanUserId);
    const cleanUserClientId: string = cleanProperty(eventAPI.userClientId);
    const userClientId: string = formatUserId(cleanUserClientId) || i18n.t('no-data');
    const comment: string = cleanProperty(eventAPI.comment);
    const status: string = EVENT_STATES[cleanProperty(eventAPI.challengeState)];
    const docType: string = DOCUMENT_TYPES[cleanProperty(eventAPI.clientDocType)];
    return {
        branchOfficeCode: cleanProperty(eventAPI.branchOfficeCode),
        branchOfficeName: cleanProperty(eventAPI.branchOfficeName),
        challengeSerialNumber: cleanProperty(eventAPI.challengeSerialNumber),
        challengeState: i18n.t(status) || i18n.t('no-data'),
        deviceType: i18n.t(DEVICE_TYPES[eventAPI.deviceType.trim()]),
        channelCode: cleanProperty(eventAPI.channelCode),
        channelName: cleanProperty(eventAPI.channelName),
        clientDocId: cleanProperty(eventAPI.clientDocId),
        clientDocType: i18n.t(docType) || i18n.t('no-data'),
        comment: i18n.t(comment) || comment,
        docSignedCorrelative: cleanProperty(eventAPI.docSignedCorrelative),
        eventAction: i18n.t(EVENT_ACTIONS[cleanProperty(eventAPI.eventAction)]),
        eventActionDate,
        eventCorrelative: eventAPI.eventCorrelative,
        eventDetails: cleanProperty(eventAPI.eventDetails),
        ipAddress: cleanProperty(eventAPI.ipAddress),
        userClientId,
        userId,
    }
}

function parseSoftTokenEvent(eventAPI: EventAPI): SoftTokenEvent {
    const eventActionDate: Date = new Date(eventAPI.eventActionDate);
    const cleanUserId: string = cleanProperty(eventAPI.userId);
    const userId: string = formatUserId(cleanUserId);
    const comment: string = cleanProperty(eventAPI.comment);
    const status: string = SOFTTOKEN_STATES[cleanProperty(eventAPI.challengeState)];
    return {
        branchOfficeCode: cleanProperty(eventAPI.branchOfficeCode),
        branchOfficeName: cleanProperty(eventAPI.branchOfficeName),
        challengeSerialNumber: cleanProperty(eventAPI.challengeSerialNumber),
        challengeState: i18n.t(status) || i18n.t('no-data'),
        channelCode: cleanProperty(eventAPI.channelCode),
        channelName: cleanProperty(eventAPI.channelName),
        comment: i18n.t(comment) || comment,
        eventAction: i18n.t(EVENT_ACTIONS[cleanProperty(eventAPI.eventAction)]),
        eventActionDate,
        eventCorrelative: eventAPI.eventCorrelative,
        eventDetails: cleanProperty(eventAPI.eventDetails),
        ipAddress: cleanProperty(eventAPI.ipAddress),
        userId,
    }
}

function parseCardEventExternal(eventAPI: EventAPI): CardEventExternal {
    return {
        numeroHistorico: eventAPI.externalData.numeroHistorico,
        numeroDocumentoPersona: eventAPI.externalData.numeroDocumentoPersona,
        codigoTipoDocumentoPersona: eventAPI.externalData.codigoTipoDocumentoPersona,
        codigoTipoClaveCliente: eventAPI.externalData.codigoTipoClaveCliente,
        fechaEvento: new Date(eventAPI.externalData.fechaEvento),
        codigoEvento: eventAPI.externalData.codigoEvento,
        codigoEstadoClaveCliente: 'grid-card-acl-cod_est_clv-' + eventAPI.externalData.codigoEstadoClaveCliente,
        numeroDocumentoPersonaActivo: eventAPI.externalData.numeroDocumentoPersonaActivo,
        codigoTipoDocumentoPersonaActivacion: cleanProperty(eventAPI.externalData.codigoTipoDocumentoPersonaActivacion),
        codigoUsuarioBancoActivacion: cleanProperty(eventAPI.externalData.codigoUsuarioBancoActivacion),
        glosaDireccionUsuarioActivacion: eventAPI.externalData.glosaDireccionUsuarioActivacion,
        codigoSucursalEntrega: eventAPI.externalData.codigoSucursalEntrega,
        numeroFolioSobreEntrega: eventAPI.externalData.numeroFolioSobreEntrega
    }
}

function parseCardEvent(eventAPI: EventAPI): CardEvent {
    const eventActionDate: Date = new Date(eventAPI.eventActionDate);
    const cleanUserId: string = cleanProperty(eventAPI.userId);
    const userId: string = formatUserId(cleanUserId);
    const comment: string = cleanProperty(eventAPI.comment);
    const status: string = CARD_STATES[cleanProperty(eventAPI.challengeState)];
    const docType: string = DOCUMENT_TYPES[cleanProperty(eventAPI.clientDocType)];
    return {
        branchOfficeCode: cleanProperty(eventAPI.branchOfficeCode),
        branchOfficeName: cleanProperty(eventAPI.branchOfficeName),
        challengeSerialNumber: cleanProperty(eventAPI.challengeSerialNumber),
        challengeState: i18n.t(status) || i18n.t('no-data'),
        channelCode: cleanProperty(eventAPI.channelCode),
        channelName: cleanProperty(eventAPI.channelName),
        comment: i18n.t(comment) || comment,
        eventAction: i18n.t(EVENT_ACTIONS[cleanProperty(eventAPI.eventAction)]),
        eventActionDate,
        eventCorrelative: eventAPI.eventCorrelative,
        eventDetails: cleanProperty(eventAPI.eventDetails),
        ipAddress: cleanProperty(eventAPI.ipAddress),
        userId,
        clientDocId: cleanProperty(eventAPI.clientDocId),
        clientDocType: i18n.t(docType) || i18n.t('no-data'),
    }
}

function parsePasswordEvent(eventAPI: EventAPI): PasswordEvent {
    const eventActionDate: Date = new Date(eventAPI.eventActionDate);
    const cleanUserId: string = cleanProperty(eventAPI.userId);
    const userId: string = formatUserId(cleanUserId);
    const comment: string = cleanProperty(eventAPI.comment);
    const status: string = PASSWORD_STATES[cleanProperty(eventAPI.challengeState)];
    return {
        branchOfficeCode: cleanProperty(eventAPI.branchOfficeCode),
        branchOfficeName: cleanProperty(eventAPI.branchOfficeName),
        challengeSerialNumber: cleanProperty(eventAPI.challengeSerialNumber),
        challengeState: i18n.t(status) || i18n.t('no-data'),
        channelCode: cleanProperty(eventAPI.channelCode),
        channelName: cleanProperty(eventAPI.channelName),
        comment: i18n.t(comment) || comment,
        eventAction: i18n.t(EVENT_ACTIONS[cleanProperty(eventAPI.eventAction)]),
        eventActionDate,
        eventCorrelative: eventAPI.eventCorrelative,
        eventDetails: cleanProperty(eventAPI.eventDetails),
        ipAddress: cleanProperty(eventAPI.ipAddress),
        userId,
    }
}

function parseUserEvent(eventAPI: EventAPI): UserEvent {
    const eventActionDate: Date = new Date(eventAPI.eventActionDate);
    const cleanUserId: string = cleanProperty(eventAPI.userId);
    const userId: string = formatUserId(cleanUserId);
    const comment: string = cleanProperty(eventAPI.comment);
    const status: string = EVENT_STATES[cleanProperty(eventAPI.challengeState)];
    const docType: string = DOCUMENT_TYPES[cleanProperty(eventAPI.clientDocType)];
    return {
        branchOfficeCode: cleanProperty(eventAPI.branchOfficeCode),
        branchOfficeName: cleanProperty(eventAPI.branchOfficeName),
        challengeSerialNumber: cleanProperty(eventAPI.challengeSerialNumber),
        challengeState: i18n.t(status) || i18n.t('no-data'),
        deviceType: i18n.t(DEVICE_TYPES[eventAPI.deviceType.trim()]) || i18n.t('no-data'),
        channelCode: cleanProperty(eventAPI.channelCode),
        channelName: cleanProperty(eventAPI.channelName),
        clientDocId: cleanProperty(eventAPI.clientDocId),
        clientDocType: i18n.t(docType) || i18n.t('no-data'),
        comment: i18n.t(comment) || comment,
        docSignedCorrelative: cleanProperty(eventAPI.docSignedCorrelative),
        eventAction: i18n.t(EVENT_ACTIONS[cleanProperty(eventAPI.eventAction)]),
        eventActionDate,
        eventCorrelative: eventAPI.eventCorrelative,
        eventDetails: cleanProperty(eventAPI.eventDetails),
        ipAddress: cleanProperty(eventAPI.ipAddress),
        userId,
    }
}

function parseShipmentEvents(shipmentEventAPI: ShipmentEventAPI): ShipmentEvent {
    return {
        shipmentCorrelative: shipmentEventAPI.key.shipmentCorrelative,
        date: shipmentEventAPI.key.date ? new Date(shipmentEventAPI.key.date) : i18n.t('no-data'),
        shipmentState: SHIPMENT_STATES[cleanProperty(shipmentEventAPI.shipmentState)],
        userId: formatUserId(shipmentEventAPI.userId, shipmentEventAPI.userGroup),
        officeCode: cleanProperty(shipmentEventAPI.officeCode),
        ipAddress: cleanProperty(shipmentEventAPI.ipAddress),
        details: shipmentEventAPI.details,
        deviceType: (shipmentEventAPI.types && shipmentEventAPI.types[0]) ? shipmentEventAPI.types[0].name : i18n.t('no-data'),
        providerId: shipmentEventAPI.providerId ? shipmentEventAPI.providerId : i18n.t('no-data'),
        boxCode: shipmentEventAPI.boxCode ? shipmentEventAPI.boxCode : i18n.t('no-data')
    }
}
