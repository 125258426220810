import { Password, PasswordAPI, PasswordService, PasswordServiceAPI } from "../interfaces/Password";
import { cleanProperty } from "../utils/Formatter";
import { PASSWORD_STATES, PASSWORD_SERVICES_NAMES } from "../const/Password";
import i18n from "../i18n";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";

export function getUserPasswords(userId: string, group: string): Promise<Password[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/users/' + group + '/' + userId + '/passwords' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getUserPasswords");
            return response.data.map(parsePassword);
        })
        .catch(apiErrorCallback);
}

export function getPasswordServices(userId: string, group: string): Promise<PasswordService[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/users/' + group + '/' + userId + '/passwords/services' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getPasswordServices");
            return response.data.map(parsePasswordService);
        })
        .catch(apiErrorCallback);
}

export function unlockPassword(userId: string, group: string, name: string): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/users/' + group + '/' + userId + '/passwords/unlock',
        data: { name, state: 'OK' }
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] unlockPassword");
            return true;
        })
        .catch(apiErrorCallback);
}

export function toggleService(userId: string, group: string, name: string, active: boolean): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/users/' + group + '/' + userId + '/passwords/services',
        data: { code: name, state: active ? 'OK' : 'LOCKED' }
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] toggleService");
            return true;
        })
        .catch(apiErrorCallback);
}

function parsePassword(password: PasswordAPI): Password {
    return {
        name: cleanProperty(password.name),
        state: PASSWORD_STATES[cleanProperty(password.state)],
        lockoutDate: password.lockoutDate ? new Date(password.lockoutDate) : i18n.t('no-data'),
        lastAuth: password.lastAuth ? new Date(password.lastAuth) : i18n.t('no-data'),
        failureCount: password.failureCount,
    }
}

function parsePasswordService(service: PasswordServiceAPI): PasswordService {
    return {
        code: cleanProperty(service.code),
        name: PASSWORD_SERVICES_NAMES[cleanProperty(service.code)],
        state: PASSWORD_STATES[cleanProperty(service.state)],
    }
}