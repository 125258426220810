import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { AppContextProps, withAppContext } from "../context/AppContext";
import { SideMenuItem } from "../interfaces/Menu";
import { MAIN_ROUTE } from "../const/Routes";
import { Item } from "./side/Item";

export interface SideMenuProps extends RouteComponentProps, AppContextProps {
    items: SideMenuItem[];
    userId?: string;
}
export interface SideMenuState { }

export class SideMenuMain extends React.Component<SideMenuProps, SideMenuState>{
    setItem: boolean = true;

    constructor(props: SideMenuProps) {
        super(props);
        this.handleChangeClick = this.handleChangeClick.bind(this);
    }

    componentWillUpdate(prevProps: SideMenuProps) {
        if (prevProps.items !== this.props.items) {
            this.setItem = true;
        }
    }

    /**
     * funcion al hacer click en los items del menu y cambiar lo que se muestra en el container
     */
    handleChangeClick(menuId: string) {
        this.setState({ selected: menuId });
    }

    render() {
        const items: JSX.Element[] = this.props.items.map((item: SideMenuItem) => {
            if (item.permissions.length === 0 || !this.props.havePermission(...item.permissions)) {
                return <div key={item.id} />;
            }
            return (<Item
                key={item.id}
                item={item}
                handleChangeClick={this.handleChangeClick}
                alone={true} />)
        });

        const logoImg = require('./side/images/' + process.env.REACT_APP_ICON!);

        return (
            <div key="leftMenu" className="side-menu sidebar-inverse">
                <nav className="navbar navbar-default" role="navigation">
                    <div className="side-menu-container">
                        <div className="navbar-header">
                            <Link className="navbar-brand" to={MAIN_ROUTE}>
                                <div className="icon ">
                                    <img width="36" height="36" src={logoImg} />
                                </div>
                                &ensp;&ensp;{this.props.authUser && <div className="title">{process.env.REACT_APP_NAME}</div>}
                            </Link>
                            <button type="button" className="navbar-expand-toggle pull-right visible-xs">
                                <i className="fa fa-times icon"></i>
                            </button>
                        </div>
                        <ul className="nav navbar-nav">
                            {items}
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}

export const SideMenu = withAppContext(SideMenuMain);