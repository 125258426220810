import i18n from "../i18n";

export const LICENSE_TYPES: { [key: string]: string } = {
    "USER": i18n.t('users'),
    "SOFTTOKEN": i18n.t('soft-tokens'),
    "SOFTTOKEN_TRANSACTION_VERIFICATION": i18n.t('soft-token-transaction-verification'),
    "SMARTCREDENTIAL": i18n.t('smart-credentials'),
    "MOBILE_SMARTCREDENTIAL": i18n.t('smart-credentials-mobile'),
    "SMARTCREDENTIAL_IDENTITY_ASSURED": i18n.t('smart-credential-identity-assured'),
    "BIOMETRIC": i18n.t('biometrics'),
}

export const GET_NOTIFICATIONS_LAPSE = 120000;