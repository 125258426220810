import i18n from "../i18n";
import { GridCard, GridCardAPI } from "../interfaces/GridCard";
import { CARD_STATES } from "../const/GridCard";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { DocumentSource, Document } from "../interfaces/Document";
import { parseDocumentSource, parseDocument } from "./Document";
import { STATE_CANCELED, STATE_CURRENT } from "../const/Identity";

export function getUserCardList(userId: string, group: string): Promise<GridCard[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/users/' + group + '/' + userId + '/gridcard' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getUserCardList");
            return response.data.map(parseCard);
        })
        .catch(apiErrorCallback);
}

export function updateCardState(userId: string, group: string, serialNumber: string, state: string, comment?: string): Promise<boolean> {
    return helperAxios({
        method: 'POST',
        url: '/api/v2/users/' + group + '/' + userId + '/gridcard/' + serialNumber,
        data: { serialNumber, state, comment }
    })
        .then(() => {
            console.log("[OK] updateCardState: to " + state);
            return true;
        })
        .catch(apiErrorCallback);
}

export function userCardAssign(userId: string, group: string, serialNumber: string): Promise<DocumentSource> {
    return helperAxios({
        method: 'POST',
        url: '/api/v2/users/' + group + '/' + userId + '/gridcard/assign',
        data: { serialNumber, state: STATE_CURRENT }
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] userCardAssign");
            return parseDocumentSource(response.data);
        })
        .catch(apiErrorCallback);
}

export function userCardUnassign(userId: string, group: string, serialNumber: string): Promise<boolean> {
    return helperAxios({
        method: 'POST',
        url: '/api/v2/users/' + group + '/' + userId + '/gridcard/unassign',
        data: { serialNumber, state: STATE_CANCELED }
    })
        .then(() => {
            console.log("[OK] userCardUnassign");
            return true;
        })
        .catch(apiErrorCallback);
}

export function gridCardDocuments(userId: string, group: string): Promise<Document[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/users/' + group + '/' + userId + '/gridcard/assign' })
        .then((response: AxiosResponse) => {
            console.log("[OK] gridCardDocuments");
            return response.data.map(parseDocument);
        })
        .catch(apiErrorCallback);
}

export function signGridCardDocument(userId: string, group: string, docCode: string, versCorrelative: number): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/users/' + group + '/' + userId + '/gridcard/documents/' + docCode + '/' + versCorrelative + '/sign'
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] signGridCardDocument");
            return true;
        })
        .catch(apiErrorCallback);
}


export function deleteUserCard(userId: string, group: string, serialNumber: string, comment?: string): Promise<boolean> {
    return helperAxios({
        method: 'DELETE',
        url: '/api/v2/users/' + group + '/' + userId + '/gridcard/' + serialNumber,
        data: { serialNumber, comment, state: STATE_CANCELED }
    })
        .then(() => {
            console.log("[OK] deleteUserCard");
            return true;
        })
        .catch(apiErrorCallback);
}

function parseCard(card: GridCardAPI): GridCard {
    return {
        serialNumber: card.serialNumber,
        challengeCount: card.challengeCount || Number(i18n.t('no-data')),
        state: CARD_STATES[card.state],
        createDate: card.createDate ? new Date(card.createDate) : i18n.t('no-data'),
        expirateDate: card.expirateDate ? new Date(card.expirateDate) : i18n.t('no-data'),
    }
}