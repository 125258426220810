import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { DeviceTypeParams, DEVICES_TYPE_DEVICE_ROUTE } from "../../../const/Routes";
import i18n from "../../../i18n";
import { getAssignedOffices, assignOffices } from "../../../services/DeviceType";
import { OfficesByDeviceType, Office } from "../../../interfaces/Shipment";
import { apiHandleErrorCode, ResponseError } from "../../../utils/ApiBaseConfig";
import { AssignOffice } from "../../../interfaces/DeviceType";
import { getOfficesAllList } from "../../../services/Office";
import { AssignGroup } from "./AssignGroup";

export interface DeviceTypeAssignProps extends RouteComponentProps<DeviceTypeParams>, AppContextProps { }
export interface DeviceTypeAssignState {
  officesByDeviceType: OfficesByDeviceType;
  allOffices: AssignOffice[];
  wasModificated: boolean;
}

export class DeviceTypeAssignMain extends React.Component<DeviceTypeAssignProps, DeviceTypeAssignState> {
  constructor(props: DeviceTypeAssignProps) {
    super(props);
    this.back = this.back.bind(this);
    this.update = this.update.bind(this);

    this.fetchAllOffices = this.fetchAllOffices.bind(this);
    this.removeAssigns = this.removeAssigns.bind(this);
    this.addAssigns = this.addAssigns.bind(this);
    this.state = {
      officesByDeviceType: { code: "", name: "", offices: [] },
      allOffices: [],
      wasModificated: false
    };
  }

  componentDidMount() {
    this.fetchAllOffices();
    if (this.props.match.params.deviceTypeId) this.fetchDeviceTypeAssign(this.props.match.params.deviceTypeId);
  }

  fetchDeviceTypeAssign(deviceTypeId: string) {
    this.props.showLoading(true);
    getAssignedOffices(deviceTypeId)
      .then((officesByDeviceType: OfficesByDeviceType) => {
        this.setState({ officesByDeviceType });
        this.props.showLoading(false);
      })
      .catch((response: ResponseError) => {
        apiHandleErrorCode(response.status, this.props.history);
        this.props.setMessage(response.message);
        this.props.showLoading(false);
      });
  }

  fetchAllOffices() {
    this.props.showLoading(true);
    getOfficesAllList()
      .then((allOffices: AssignOffice[]) => {
        this.setState({ allOffices });
        this.props.showLoading(false);
      })
      .catch((response: ResponseError) => {
        apiHandleErrorCode(response.status, this.props.history);
        this.props.setMessage(response.message);
        this.props.showLoading(false);
      });
  }

  back() {
    this.props.history.push(DEVICES_TYPE_DEVICE_ROUTE);
  }

  update() {
    this.props.showLoading(true);
    assignOffices(this.state.officesByDeviceType)
      .then(() => {
        this.props.showLoading(false);
        this.props.history.goBack();
      })
      .catch((response: ResponseError) => {
        apiHandleErrorCode(response.status, this.props.history);
        this.props.setMessage(response.message);
        this.props.showLoading(false);
        this.props.history.goBack();
      });
  }

  addAssigns(selected: AssignOffice[]) {
    this.setState({
      officesByDeviceType: {
        code: this.state.officesByDeviceType.code,
        name: this.state.officesByDeviceType.name,
        offices: [...this.state.officesByDeviceType.offices, ...selected]
      }, wasModificated: true
    })
  }

  removeAssigns(selected: AssignOffice[]) {
    this.setState({
      officesByDeviceType: {
        code: this.state.officesByDeviceType.code,
        name: this.state.officesByDeviceType.name,
        offices: this.state.officesByDeviceType.offices.filter((office: Office) => {
          return selected.every((assigendOffice: AssignOffice) => {
            return assigendOffice.code != office.code
          });
        })
      }, wasModificated: true
    });
  }

  render() {

    const form: JSX.Element =
      <AssignGroup
        assignedOffices={this.state.officesByDeviceType.offices}
        allOffices={this.state.allOffices}
        addAssigns={this.addAssigns}
        removeAssigns={this.removeAssigns} />

    return (
      <div>
        <div className="card">
          <div className="card-body form-horizontal">
            <div className="form-group">
              <label className="col-sm-2 control-label">
                {i18n.t("code")}:
              </label>
              <div className="col-sm-2">
                <input type="text" readOnly={this.state.officesByDeviceType !== undefined} name="deviceTypeCode" className="form-control" id="deviceTypeCode" maxLength={5}
                  defaultValue={this.state.officesByDeviceType ? this.state.officesByDeviceType.code : ""} />
              </div>
              <label className="col-sm-2 control-label">
                {i18n.t("name")}:
              </label>
              <div className="col-sm-2">
                <input type="text" readOnly={this.state.officesByDeviceType !== undefined} name="deviceTypeName" className="form-control" id="deviceTypeName" maxLength={50}
                  defaultValue={this.state.officesByDeviceType ? this.state.officesByDeviceType.name : ""} />
              </div>
            </div>
          </div>
        </div>

        <div className="card margin-card-top">
          <div className="card-header">
            <div className="card-title">
              <div className="title">{i18n.t('branch-offices')}</div>
            </div>
          </div>
          <div className="card-body">
            <div className="form-horizontal">
              {form}
              <br />
              <div className="form-group">
                <div className="col-sm-offset-2 col-sm-10">
                  <button type="button" onClick={this.back} className="btn btn-primary">{i18n.t('back')}</button>
                  <button type="button" onClick={this.update} className="btn btn-info" disabled={!this.state.wasModificated}>
                    {i18n.t('update')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export const DeviceTypeAssign = withAppContext(DeviceTypeAssignMain);
