import * as React from "react";
import i18n from "../../i18n";
import { withAppContext, AppContextProps } from "../../context/AppContext";
import { RouteComponentProps } from "react-router";
import { getRolesList } from "../../services/Role";
import { Role } from "../../interfaces/Role";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { SearchEvent, GeneralEvent } from "../../interfaces/Event";
import { DEVICE_TYPES } from "../../const/Device";
import { formatUserId, serviceUserId } from "../../utils/Formatter";
import { EVENT_ACTIONS, EVENT_COMMENTS } from "../../const/Event";
import { GroupAdmin, GroupClient } from "../../utils/Config";

interface EventFilterProps extends RouteComponentProps, AppContextProps {
    api: Function;
    filter: Function;
    step: number;
    clientId: String | null;
}

interface EventFilterState {
    collapsed: boolean;
    search: SearchEvent;
    otherComment: boolean;

}

export class EventFilterMain extends React.Component<EventFilterProps, EventFilterState>{
    constructor(props: EventFilterProps) {
        super(props);
        this.doFilter = this.doFilter.bind(this);
        this.handleClientId = this.handleClientId.bind(this);
        this.handleDeviceType = this.handleDeviceType.bind(this);
        this.handleEventAction = this.handleEventAction.bind(this);
        this.handleFrom = this.handleFrom.bind(this);
        this.handleTo = this.handleTo.bind(this);
        this.handleRoleSelected = this.handleRoleSelected.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.handleUserId = this.handleUserId.bind(this);
        this.handleFolio = this.handleFolio.bind(this);
        this.handleMaxResults = this.handleMaxResults.bind(this);
        this.showCommentsList = this.showCommentsList.bind(this);

        this.state = {
            collapsed: false,
            search: {
                clientGroup: 'clientes',
                userGroup: 'identity',
            },
            otherComment: false,
        }
    }

    componentWillReceiveProps(props: EventFilterProps) {
        if (props.step !== this.props.step && this.props.step === 1) {
            this.setState(state => ({
                ...state,
                search: {
                    ...state.search,
                    challengeType: undefined,
                    folio: undefined,
                    comment: undefined,
                    clientId: undefined,
                }
            }));
        }

        //setear la fecha de  busqueda por defecto
        var curr = new Date();
        var date = curr.toISOString().substr(0, 10);
        curr.setDate(curr.getDate()+1);
        var tomorrow = curr.toISOString().substr(0, 10);

        this.setState(state => ({
            ...state,
            search:{
                ...state.search,
                startDate: date + "T00:00",
                endDate: tomorrow + "T00:00",
            }
        }));
    }

    doFilter() {
        const userId = this.state.search.userId ? serviceUserId(this.state.search.userId, GroupAdmin) : '';
        const clientId = this.state.search.clientId ? serviceUserId(this.state.search.clientId, GroupClient) : '';
        const startDate = this.state.search.startDate ? new Date(this.state.search.startDate).getTime() : undefined;
        const endDate = this.state.search.endDate ? new Date(this.state.search.endDate).getTime() : undefined;
        this.props.filter({ ...this.state.search, userId, clientId, startDate, endDate });
    }

    handleUserId(e: any) {
        e.preventDefault();
        const userId = formatUserId(e.target.value);
        this.setState(state => ({ ...state, search: { ...state.search, userId } }));
    }

    handleClientId(e: any) {
        e.preventDefault();
        const clientId = formatUserId(e.target.value);
        this.setState(state => ({ ...state, search: { ...state.search, clientId } }));
    }

    handleDeviceType(e: any) {
        e.preventDefault();
        const challengeType = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, challengeType } }));
    }

    handleEventAction(e: any) {
        e.preventDefault();
        const eventAction = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, eventAction } }));
    }

    handleFrom(e: any) {
        e.preventDefault();
        const startDate = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, startDate } }));
    }

    handleTo(e: any) {
        e.preventDefault();
        const endDate = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, endDate } }));
    }

    handleComment(e: any) {
        e.preventDefault();
        const input = e.target.value;
        const otherComment = this.state.otherComment || input === 'reason-other';
        const comment = input === 'reason-other' ? '' : input;
        this.setState(state => ({ ...state, otherComment, search: { ...state.search, comment } }));
    }

    handleRoleSelected(e: any) {
        e.preventDefault();
        const codeRole = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, codeRole } }));
    }

    handleFolio(e: any) {
        e.preventDefault();
        const folio = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, folio } }));
    }

    handleMaxResults(e: any) {
        e.preventDefault();
        const maxResults: number = e.target.value;
        this.setState(state => ({ ...state, search: { ...state.search, maxResults } }));
    }


    showCommentsList() {
        this.setState(state => ({ ...state, otherComment: false, search: { ...state.search, comment: '' } }));
    }

    render() {
        const actions: JSX.Element[] = Object.keys(EVENT_ACTIONS).map((action: string) => {
            return <option key={action} value={action}>{i18n.t(EVENT_ACTIONS[action])}</option>;
        });
        const devices: JSX.Element[] = Object.keys(DEVICE_TYPES).map((type: string) => {
            return <option key={type} value={type}>{i18n.t(DEVICE_TYPES[type])}</option>;
        });
        const comments: JSX.Element[] = EVENT_COMMENTS.map((comment: string) => {
            return <option key={comment} value={comment}>{i18n.t(comment)}</option>;
        });
        const collapseButton: JSX.Element = (
            <button type="button" onClick={() => this.setState(state => ({ ...state, collapsed: !state.collapsed }))} className="btn">
                <i className={this.state.collapsed ? "fa fa-angle-down" : "fa fa-angle-up"}></i>
            </button>
        );

        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('filters')} {collapseButton}</div>
                    </div>
                </div>
                {!this.state.collapsed && <div className="card-body">
                    <div className="form-group col-sm-3">
                        <label>{i18n.t('user-id')}:</label>
                        <input type="text" name="userId" className="form-control" id="userId" value={this.state.search.userId} onChange={this.handleUserId} />
                    </div>
                    <div className="form-group col-sm-3">
                        <label>{i18n.t('from')}:</label>
                        <input type="datetime-local" name="from" className="form-control" id="from" value={this.state.search.startDate} onChange={this.handleFrom} />
                    </div>
                    <div className="form-group col-sm-3">
                        <label>{i18n.t('to')}:</label>
                        <input type="datetime-local" name="to" className="form-control" id="to" value={this.state.search.endDate} onChange={this.handleTo} />
                    </div>
                    <div className="form-group col-sm-3">
                        <label>{i18n.t('max-entries')}:</label>
                        <input type="number" min="0" name="maxResults" className="form-control" id="maxResults" value={this.state.search.maxResults} onChange={this.handleMaxResults} />
                    </div>
                    <div className="form-group col-sm-12">
                        <button type="button" onClick={this.doFilter} className="btn btn-info">{i18n.t('filter')}</button>
                    </div>
                </div>}
            </div>
        )
    }
}

export const EventFilter = withAppContext(EventFilterMain);