import React from 'react';
import { MessageModel } from './../utils/Message';
import { IdentityAuthUser } from './../interfaces/Identity';
import { withRouter } from 'react-router';

export interface AppContextProps {
  showLoading: Function,
  setMessage: Function,
  hideMessage: Function,
  message: MessageModel,
  showMessage: boolean,
  authUser: IdentityAuthUser | null;
  authenticated: Function;
  havePermission: Function;
  useIntellitrust: boolean;
}

const AppContext = React.createContext<AppContextProps>({
  showLoading: (loading: boolean) => { },
  setMessage: (message: MessageModel) => { },
  hideMessage: () => { },
  message: {},
  showMessage: false,
  authUser: null,
  authenticated: () => { },
  havePermission: (...permissions: string[]) => { },
  useIntellitrust: false,
});
export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

export function withAppContext(Component: any) {
  return withRouter((props: any) => (
    <AppContextConsumer>
      {appContext => {
        return <Component {...props} {...appContext} />
      }}
    </AppContextConsumer>
  ))
}