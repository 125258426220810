import { DocumentType } from "../interfaces/Document";

export const DOCUMENT_CONTRACT = 'contract';
export const DOCUMENT_VOUCHER = 'voucher';
export const DOCUMENT_SIGNED = 'signed';
export const DOCUMENT_NOT_SIGNED = 'signed-not';
export const DOCUMENT_CURUS_SIGN = 'curus-identity-sign';

export const DOCUMENT_TYPES: { [key: string]: DocumentType } = {
    'CT': DOCUMENT_CONTRACT,
    'VC': DOCUMENT_VOUCHER,
};

export const DOCUMENT_PARAM_FOLIO = 'FOLIO';
export const DOCUMENT_PARAM_SERIAL_NUMER = 'SERIAL_NUMBER';
