import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import { ROLE_CREATE_PERM, ROLE_MODIFY_PERM, ROLE_LIST_PERM, ROLE_SHOW_PERM } from "../../const/Permission";
import { ROLE_CREATE_ROUTE, ROLE_MODIFY_ROUTE, ROLES_ROUTE, ROLE_EVENTS_ROUTE, ROLE_SHOW_ROUTE, LOGIN_ROUTE, FORBIDDEN_ROUTE, RouteRoleParams } from "../../const/Routes";
import { FormRole } from "../../components/role/Form";
import { RoleList } from "../../components/role/List";
import { AppContextProps, withAppContext } from "../../context/AppContext";

interface RoleContainerProps extends RouteComponentProps<RouteRoleParams>, AppContextProps { }
interface RoleContainerState { }

export class RoleContainerMain extends React.Component<RoleContainerProps, RoleContainerState>{
    constructor(props: RoleContainerProps) {
        super(props);
    }

    render() {
        return (
            <Switch>
                {this.props.havePermission(ROLE_MODIFY_PERM) && <Route path={ROLE_MODIFY_ROUTE} exact component={FormRole} />}
                {this.props.havePermission(ROLE_CREATE_PERM) && <Route path={ROLE_CREATE_ROUTE} exact component={FormRole} />}
                {this.props.havePermission(ROLE_SHOW_PERM) && <Route path={ROLE_SHOW_ROUTE} exact component={FormRole} />}
                {this.props.havePermission(ROLE_LIST_PERM) && <Route path={ROLES_ROUTE} exact component={RoleList} />}
                <Redirect to={FORBIDDEN_ROUTE} />
            </Switch>
        )
    }
}

export const RoleContainer = withAppContext(RoleContainerMain);