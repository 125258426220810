import React from 'react';
import { withAppContext } from './AppContext';
import { withRouter } from 'react-router';
import { BranchOffice } from '../interfaces/Office';

export interface ReportContextProps {
    branchOffices: BranchOffice[];
}

const ReportContext = React.createContext<ReportContextProps>({
    branchOffices: []
});

export const ReportContextProvider = ReportContext.Provider;
export const ReportContextConsumer = ReportContext.Consumer;

export function withAppReportContext(Component: any) {
    return withAppContext((props: any) => (
        <ReportContextConsumer>
            {ReportContext => {
                return <Component {...props} {...ReportContext} />
            }}
        </ReportContextConsumer>
    ))
}

export function withReportContext(Component: any) {
    return withRouter((props: any) => (
        <ReportContextConsumer>
            {ReportContext => {
                return <Component {...props} {...ReportContext} />
            }}
        </ReportContextConsumer>
    ))
}
