import { IdentityUser, IdentityAccess, IdentityLockOut, IdentityUserWithOffice } from "../interfaces/Identity";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { GroupAdmin, GroupClient, GroupCreate } from "../utils/Config";
import { TYPE_USER, USER_API, CLIENT_API } from "../const/Routes";
import i18n from "../i18n";
import { capitalize, cleanProperty, formatUserId } from "../utils/Formatter";
import { DEVICE_TYPES } from "../const/Device";
import { Enterprise } from "../interfaces/Enterprise";
import { ContactInfoAPI, ContactInfo } from "../interfaces/User";
import { FingerprintAPI, Fingerprint } from "../interfaces/Biometrics";
import { CreditCardAPI, CreditCard } from "../interfaces/CreditCard";
import { CREDIT_CARD_TYPES } from "../const/CreditCard";
import { parseFingerprint } from "./Biometrics";

export function getUserCreate(userId: string, group: string, userType: string): Promise<IdentityUserWithOffice> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({ method: 'GET', url: base + '/create/' + group + '/' + userId + '/' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getUserCreate");
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function getUser(userId: string, group: string, userType: string): Promise<IdentityUserWithOffice> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({ method: 'GET', url: base + '/' + group + '/' + userId + '/' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getUser");
            return parseUser(response.data);
        })
        .catch(apiErrorCallback);
}

export function getUserContactInfo(userId: string, group: string, userType: string): Promise<ContactInfo> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({ method: 'GET', url: base + '/' + group + '/' + userId + '/info' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getUserContactInfo");
            return parseContactInfo(response.data);
        })
        .catch(apiErrorCallback);
}

export function updateUser(userName: string, userId: string, userType: string, codeRole: string, userActive: boolean): Promise<boolean> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    const group = userType === TYPE_USER ? GroupAdmin : GroupClient
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId,
        data: {
            userId,
            userName,
            userActive,
            role: { codeRole }
        }
    })
        .then(() => {
            console.log("[OK] updateUser");
            return true;
        })
        .catch(apiErrorCallback);
}

export function updateUserWithOffice(userName: string, userId: string, userType: string, codeRole: string, userActive: boolean, officeCode: string, externalName: string, email: string): Promise<boolean> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    const group = userType === TYPE_USER ? GroupAdmin : GroupClient
    return helperAxios({
        method: 'POST', url: base + '/' + group + '/' + userId,
        data: {
            userId,
            userName,
            userActive,
            role: { codeRole },
            branchOffice: { code: officeCode },
            externalUserName: externalName,
            emailUser: email
        }
    })
        .then(() => {
            console.log("[OK] updateUser");
            return true;
        })
        .catch(apiErrorCallback);
}

export function createUser(userName: string, userId: string, userType: string, codeRole: string): Promise<boolean> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    const group = userType === TYPE_USER ? GroupAdmin : GroupClient
    return helperAxios({
        method: 'POST', url: base + '/create/' + group + '/' + userId + '/',
        data: {
            userId,
            userName,
            role: { codeRole }
        }
    })
        .then(() => {
            console.log("[OK] createUser");
            return true;
        })
        .catch(apiErrorCallback);
}

export function updatePassword(userId: string, currentPwd: string, newPwd: string): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: USER_API + '/changepassword/' + GroupAdmin + '/' + userId,
        data: {
            currentPassword: currentPwd,
            newPassword: newPwd
        }
    })
        .then(() => {
            console.log("[OK] updatePassword");
            return true;
        })
        .catch(apiErrorCallback);
}

export function createUserWithOffice(userName: string, userId: string, userType: string, codeRole: string, officeCode: string, externalName: string, email: string): Promise<boolean> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    const group = userType === TYPE_USER ? GroupAdmin : GroupClient
    return helperAxios({
        method: 'POST', url: base + '/create/' + group + '/' + userId + '/',
        data: {
            userId,
            userName,
            role: { codeRole },
            branchOffice: { code: officeCode },
            externalUserName: externalName,
            emailUser: email
        }
    })
        .then(() => {
            console.log("[OK] createUser");
            return true;
        })
        .catch(apiErrorCallback);
}

export function unlockUser(userId: string, group: string, userType: string): Promise<boolean> {
    userId = userId.replace('k', 'K');
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({ method: 'POST', url: base + '/' + group + '/' + userId + '/unlock' })
        .then(() => {
            console.log("[OK] unlockUser");
            return true;
        })
        .catch(apiErrorCallback);
}

function parseUser(user: IdentityUserWithOffice): IdentityUserWithOffice {
    return {
        userId: cleanProperty(user.userId),
        userName: capitalize(user.userName),
        userActive: user.userActive,
        group: cleanProperty(user.group),
        email: cleanProperty(user.email),
        role: user.role,
        branchOffice: user.branchOffice,
        enterprise: user.enterprise ? parseEnterprise(user.enterprise) : undefined,
        maxTokensAllowed: user.maxTokensAllowed || 0,
        tokenAmount: user.tokenAmount || 0,
        maxActivatedTokensAllowed: user.maxActivatedTokensAllowed || 0,
        activatedTokenAmount: user.activatedTokenAmount || 0,
        lockoutInfo: parseLockOut(user.lockoutInfo),
        lastAuth: parseAccess(user.lastAuth),
        lastFailedAuth: parseAccess(user.lastFailedAuth),
        externalUserName: cleanProperty(user.externalUserName),
        emailUser: cleanProperty(user.emailUser)
    }
}

function parseContactInfo(contactInfo: ContactInfoAPI): ContactInfo {
    return {
        email: contactInfo.email,
        phone: contactInfo.phone,
        fingerprints: contactInfo.fingerprints.map(parseFingerprint),
        creditCards: contactInfo.creditCards.map(parseCreditCard),
    }
}

function parseCreditCard(creditCard: CreditCardAPI): CreditCard {
    return {
        pan: creditCard.pan,
        userName: capitalize(creditCard.userName),
        cardHolder: creditCard.cardHolder,
        cardType: CREDIT_CARD_TYPES[creditCard.cardType],
    }
}

function parseAccess(access: IdentityAccess | null): IdentityAccess | null {
    return access ? {
        date: new Date(access.date),
        type: DEVICE_TYPES[cleanProperty(access.type)]
    } : null;
}

function parseLockOut(lockout: IdentityLockOut | null): IdentityLockOut | null {
    return lockout ? {
        failureCount: lockout.failureCount,
        lockoutDate: new Date(lockout.lockoutDate) || i18n.t('no-data'),
        authenticatorLockouts: lockout.authenticatorLockouts || i18n.t('no-data')
    } : null;
}

function parseEnterprise(enterprise: any): Enterprise {
    return {
        id: formatUserId(cleanProperty(enterprise.enterpriseId)),
        name: cleanProperty(enterprise.enterpriseName),
    }
}

export function findAll(group: string, userType: string): Promise<IdentityUserWithOffice[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({ method: 'GET', url: base + '/' + group + '/findall' })
        .then((response: AxiosResponse) => {
            console.log("[OK] findAll");
            return response.data.map(parseUser);
        })
        .catch(apiErrorCallback);
}

export function getAllNamesAndCodes(group: string, userType: string): Promise<string[]> {
    const base = userType === TYPE_USER ? USER_API : CLIENT_API
    return helperAxios({ method: 'GET', url: base + '/' + group + '/namecode' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getAllNamesAndCodes");
            return response.data;
        })
        .catch(apiErrorCallback);
}
