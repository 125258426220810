import * as React from "react";
import Popup from "reactjs-popup";
import imageLoading from "../../images/Spinner-Loading.gif";
import "./style.css";
import i18n from "../../i18n";

interface LoadingProps{}
interface LoadingState{}

export class Loading extends React.Component<LoadingProps, LoadingState>{
    render() {
        return(
            <Popup open={true} closeOnDocumentClick={false} overlayStyle={{ zIndex: 9999999999999 }}>
                <div className="card">      
                    <br/>
                    <img className="img-center" src={imageLoading} />
                    <div style={{textAlign: 'center', fontSize: '20px' }}>{i18n.t('loading')}</div>    
                    <br/> 
                </div>
            </Popup>
        )
    }
}