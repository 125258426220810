import * as React from 'react'
import { IDENTITY_ADMIN_GROUP, IDENTITY_CLIENT_GROUP } from "../const/Group";
import i18n from "../i18n";
import { IdentityLink } from '../interfaces/Identity';
import { DOCUMENT_SIGNED, DOCUMENT_NOT_SIGNED } from '../const/Document';
import { DocumentSign } from '../interfaces/Document';
import { SHIPMENT_STATE_RECEIVED, SHIPMENT_STATE_CANCELED, SHIPMENT_STATE_SENT, SHIPMENT_STATE_ASSIGNED, SHIPMENT_STATE_GENERATED } from '../const/Shipment';
import { ShipmentState } from '../interfaces/Shipment';
import { DeviceState } from '../interfaces/Device';
import { DEVICE_STATE_ACTIVE, DEVICE_STATE_UNAVAILABLE, DEVICE_STATE_UNASSIGNED } from '../const/Device';
import { MovementAdd } from '../components/devices/movement/Add';
import { NoticeStatus } from '../interfaces/Notice';
import { NOTICE_STATUS_PENDING, NOTICE_STATUS_SENT, NOTICE_STATUS_DISCARDED, NOTICE_STATUS_ERROR, NOTICE_STATUS_SENDING } from '../const/Notice';

const SERVICE_ID_LENGTH = 11;

function strip(string: string, char: String): string {
    while (string[0] === char) string = string.slice(1);
    while (string[string.length - 1] === char) string = string.slice(0, -1);
    return string;
}

Date.prototype.toString = function (): string {
    const day = this.getDate();
    const month = this.getMonth() + 1;
    const year = this.getFullYear();
    const hours = this.getHours();
    const mins = this.getMinutes();
    return i18n.t('date-format').replace('yyyy', year)
        .replace('mm', (month < 10 ? '0' : '') + month)
        .replace('dd', (day < 10 ? '0' : '') + day)
        .replace('hh', (hours < 10 ? '0' : '') + hours)
        .replace('MM', (mins < 10 ? '0' : '') + mins)
        .replace('yyyy', year);
}

export function getTimestamp() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours()
    const mins = date.getMinutes()
    const sec = date.getSeconds()
    return (day < 10 ? '0' : '') + day + '_' + (month < 10 ? '0' : '') + month + '_' + year + '_' + (hours < 10 ? '0' : '') + hours + '_' + (mins < 10 ? '0' : '') + mins + '_' + (sec < 10 ? '0' : '') + sec;
}

export function serviceUserId(userId: string, group: string): string {
    switch (group) {
        case IDENTITY_ADMIN_GROUP:
        case IDENTITY_CLIENT_GROUP:
            return cleanIdentity(userId)
        default:
            return userId;
    }
}

function cleanRUT(userId: string): string {
    while (userId[0] === '0') userId = userId.slice(1);
    userId = strip(userId, ' ');
    userId = userId.replace(/[^0-9kK]+/g, "");
    return userId.toUpperCase();
}

function cleanIdentity(userId: string): string {
    if (userId.includes('A') || userId.includes('a')) return userId;
    const cleaned = cleanRUT(userId);
    return '0'.repeat(SERVICE_ID_LENGTH - cleaned.length) + cleaned;
}

export function formatUserId(userId: string, group?: string): string {
    switch (group) {
        case IDENTITY_ADMIN_GROUP:
        case IDENTITY_CLIENT_GROUP:
            return formatRUT(userId);
        default:
            return formatRUT(userId);
    }
}

function formatRUT(userId: string): string {
    if (userId.includes('_')) return strip(userId, ' ');

    userId = cleanRUT(userId);

    let formatUserId: string = "";
    if (userId.length < 2) {
        return userId;
    }
    formatUserId = "-" + userId.substring(userId.length - 1);
    let i: number;
    let cont: number = 0;
    for (i = userId.length - 2; i >= 0; i--) {
        formatUserId = userId.substring(i, i + 1) + formatUserId;
        cont++;
        if (cont == 3 && i != 0) {
            formatUserId = "." + formatUserId;
            cont = 0;
        }
    }
    return formatUserId.toUpperCase();
}

export function cleanProperty(text: string | null): string {
    if (text === null || text === undefined)
        return i18n.t('no-data');
    text = text.trim();
    return text;
}

export function capitalize(string: string): string {
    if (string.length === 0) return string;
    const parts = string.replace(/\s+/g, ' ').split(' ');
    const parsed = parts.map(part => part[0].toUpperCase() + part.slice(1).toLowerCase());
    return parsed.join(' ');
}

export function checkRut(rut: string) {
    const valor: string[] = rut.replace(/\./g, '').split('-');
    const cuerpo = valor[0];
    const dv = valor[1].toUpperCase();

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if (cuerpo.length < 5) { console.log("RUT Incompleto"); return false; }

    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;

    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i++) {
        // Obtener su Producto con el Múltiplo Correspondiente
        let index = multiplo * parseInt(cuerpo[cuerpo.length - i]);
        // Sumar al Contador General
        suma = suma + index;
        // Consolidar Múltiplo dentro del rango [2,7]
        if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    const dvEsperado = 11 - (suma % 11);
    // Casos Especiales (0 y K)
    const auxDv = dv === '0' ? 11 : dv === 'K' ? 10 : parseInt(dv);
    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado !== auxDv) { console.log("RUT Inválido"); return false; }
    // Si todo sale bien, eliminar errores (decretar que es válido)
    console.log("RUT Válido"); return true;
}

export function activeFormatter(cell: any): JSX.Element {
    if (cell) return <i className="fa fa-check"></i>;
    return <i className="fa fa-remove"></i>;
}

export function signedFormatter(signs: DocumentSign[]): JSX.Element {
    const status = signs !== null && signs.length > 0 ? DOCUMENT_SIGNED : DOCUMENT_NOT_SIGNED;
    return <span className={"label label-" + (status === DOCUMENT_SIGNED ? "success" : "danger")}>
        {i18n.t(status)}
    </span>;
}

export function linkFormatter(cell: IdentityLink): JSX.Element {
    return <a href={cell.path} className="btn btn-xs btn-primary">{cell.text}</a>;
}

export function checkDate(date: number): Date | string {
    return date ? new Date(date) : i18n.t('no-data')
}

export function translate(cell: any): string {
    return i18n.t(cell) || i18n.t('no-data');
}

export function idForReport(cell: any, row: any, enumObject: any, index: any) {
    return (<div>{index + 1}</div>)
}

export function officeFormatterForReport(cell: any, row: any): JSX.Element {
    return <span>{row.officeCode + ' - ' + row.officeName}</span>;
}

export function formatUserIdForList(cell: any, row: any): string {
    if (/\d/.test(row.userId)) {
        return formatRUT(row.userId)
    }
    return row.userId;
}

export function formatBranchOfficeForList(cell: any, row: any): string {
    let branch = row.branchOffice.code + ' - ' + row.branchOffice.name;
    return branch;
}

export function formatRoleForList(cell: any, row: any): string {
    return row.role.roleName
}

export function formatNameForList(cell: any, row: any): string {
    return row.name
}

export function formatStatusForList(cell: any, row: any): JSX.Element {
    if (row.userActive) {
        return <span className="label label-success">{i18n.t('active')}</span>;
    }
    return <span className="label label-danger">{i18n.t('inactive')}</span>
}

export function activeFormatterOpenedAtForReport(cell: any, row: any): JSX.Element {
    if (cell) {
        var date = new Date(row.openedAt);
        return (
            <div>
                <i className="fa fa-check"></i><br />
                <span>{hourAndMinuts(date)} ({row.openedBy})</span>
            </div>
        );
    }
    return <i className="fa fa-remove"></i>;
}

export function activeFormatterClosedAtForReport(cell: any, row: any): JSX.Element {
    if (cell) {
        var date = new Date(row.closedAt);
        return (
            <div>
                <i className="fa fa-check"></i><br />
                <span>{hourAndMinuts(date)} ({row.closenedBy})</span>
            </div>
        );
    }
    return <i className="fa fa-remove"></i>;
}

function hourAndMinuts(date: any) {
    var hours = date.getHours();
    var mins = date.getMinutes();

    return (hours > 10 ? hours : '0' + hours) + ' : ' + (mins > 10 ? mins : '0' + mins);
}

export function shipmentStateFormatter(shipmentState: ShipmentState): JSX.Element {
    return <span className={"label label-" + (shipmentState === SHIPMENT_STATE_RECEIVED ? "success" :
        shipmentState === SHIPMENT_STATE_CANCELED ? "danger" :
            shipmentState === SHIPMENT_STATE_SENT ? "warning" :
                shipmentState === SHIPMENT_STATE_ASSIGNED ? "primary" :
                    shipmentState === SHIPMENT_STATE_GENERATED ? "default" :
                        "info")}>
        {i18n.t(shipmentState)}
    </span>;
}

export function deviceStateFormatter(deviceState: DeviceState): JSX.Element {
    return <span className={"label label-" + (deviceState === DEVICE_STATE_ACTIVE ? "success" :
        [DEVICE_STATE_UNAVAILABLE, DEVICE_STATE_UNAVAILABLE].includes(deviceState) ? "danger" :
            deviceState === DEVICE_STATE_UNASSIGNED ? "warning" : "info")}>
        {i18n.t(deviceState)}
    </span>;
}

export function decodeBase64(base64: string): string {
    base64 = base64.replace(/\s/g, '');
    return atob(base64);
};

export function trimSerialNumberRange(devices: string[]): string {
    let base: string = devices.shift()!;
    let last: string = String(base);
    let serialNumber = Number(base);
    let range = "";

    devices.map((serie: string) => {
        const asLong = Number(serie);
        if (asLong > serialNumber + 1) {
            if (base === last)
                range = range + base + ', ';
            else
                range = range + base + '-' + last + ', ';
            base = serie;
        }
        last = serie;
        serialNumber = asLong;
    });

    if (base === last)
        range = range + base + ', ';
    else
        range = range + base + '-' + last + ', ';

    return range.substring(0, range.length - 2);
}

export function formatSerialNumberRange(serialNumbers: string[]): string {
    serialNumbers = serialNumbers.sort((prev: string, next: string) => Number(prev) < Number(next) ? -1 : 1);
    let base: string = serialNumbers.shift()!;
    let last: string = base;
    let serialNumber = Number(base);
    let range = "";

    serialNumbers.forEach((serie: string) => {
        const asLong = Number(serie);
        if (asLong > (serialNumber + 1)) {
            range = range + base + '-' + last + ',';
            base = serie;
        }
        last = serie;
        serialNumber = asLong;
        console.log(serie + ' ' + range);
    });
    range = range + base + '-' + last + ',';
    return range.substring(0, range.length - 1);
}

export function formatNoticeNameForList(cell: any, row: any): string {
    return row.noticeType.name
}

export function noticeStatusFormatter(noticeStatus: NoticeStatus): JSX.Element {
    return <span className={"label label-" + (noticeStatus === NOTICE_STATUS_SENT ? "success" :
        noticeStatus === NOTICE_STATUS_DISCARDED ? "danger" :
            noticeStatus === NOTICE_STATUS_ERROR ? "warning" :
                noticeStatus === NOTICE_STATUS_PENDING ? "primary" :
                    noticeStatus === NOTICE_STATUS_SENDING ? "default" :
                        "info")}>
        {i18n.t("notice-" + noticeStatus)}
    </span>;
}