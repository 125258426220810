import * as React from "react";
import i18n from "../../../i18n";
import { COLUMM_STICKY_STYLE } from "../../../const/Identity";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { StockDeviceSummary, StockDeviceMonthSummary, StockDeviceData, StockMonthSummary } from "../../../interfaces/Stock";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { getStock, getStockMonth } from "../../../services/Stock";
import { withAppDeviceContext, DeviceContextProps } from "../../../context/DeviceContext";
import { STOCK_DEVICES_SUMMARY_PERM, STOCK_DEVICES_SUMMARY_MONTH_PERM } from "../../../const/Permission";
import { daysInMonth, daysInPreviousMonth } from "../../../utils/Validator";
import { stringify } from "querystring";

const CODE_WIDTH: string = "250";
const CODE_STICKY_STYLE: any = { ...COLUMM_STICKY_STYLE, left: 0 };
const OFFICE_STICKY_STYLE: any = { ...COLUMM_STICKY_STYLE, left: Number(CODE_WIDTH) };

export interface StockSummaryProps extends RouteComponentProps, AppContextProps, DeviceContextProps { }

export interface StockSummaryState {
    step: number;
    stock: StockDeviceSummary[];
    stockMonth: StockMonthSummary[];
    stockPreviousMonth: StockMonthSummary[];
    selected: StockDeviceSummary | null;
    tableOptions: any;
    showStockFlow: boolean;
}

export class StockSummaryMain extends React.Component<StockSummaryProps, StockSummaryState> {
    constructor(props: StockSummaryProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.handleShowStockFlow = this.handleShowStockFlow.bind(this);
        this.stockDataFormat = this.stockDataFormat.bind(this);

        this.state = {
            step: 0,
            stock: [],
            stockMonth: [],
            stockPreviousMonth: [],
            selected: null,
            showStockFlow: false,
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            }
        }
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    componentDidMount() {

        if (this.props.havePermission(STOCK_DEVICES_SUMMARY_PERM)) {
            if (this.props.authUser && this.props.authUser.branchOffice) {
                this.props.showLoading(true);
                getStock(this.props.authUser.branchOffice.code)
                    .then((stock: StockDeviceSummary[]) => {
                        this.setState({ stock }, () => this.props.showLoading(false));
                    })
                    .catch((response: ResponseError) => {
                        apiHandleErrorCode(response.status, this.props.history);
                        this.props.setMessage(response.message);
                        this.props.showLoading(false);
                    });
            }
        }
        if (this.props.havePermission(STOCK_DEVICES_SUMMARY_MONTH_PERM)) {
            if (this.props.authUser && this.props.authUser.branchOffice) {
                this.props.showLoading(true);
                getStockMonth(this.props.authUser.branchOffice.code)
                    .then((stock2Month: StockMonthSummary[][]) => {
                        this.setState({ stockMonth: stock2Month[0], stockPreviousMonth: stock2Month[1] }, () => this.props.showLoading(false));
                        //, stockPreviousMonth: stock2Month[1]
                        console.log(stock2Month);
                        console.log(stock2Month[0]);
                        console.log(stock2Month[1]);
                    })
                    .catch((response: ResponseError) => {
                        apiHandleErrorCode(response.status, this.props.history);
                        this.props.setMessage(response.message);
                        this.props.showLoading(false);
                    });
            }
        }
    }

    handleShowStockFlow(e: any) {
        const showStockFlow: boolean = e.target.checked;
        this.setState({ showStockFlow });
    }

    onSelect(selected: StockDeviceSummary) {
        this.setState({ selected });
    }

    stockDataFormat(data: StockDeviceData) {
        return (<div className="stock-data">
            <span>{data.currentStock}</span>
            {this.state.showStockFlow && <div className="stock-data-flow">
                <span className="stock-data-incomes">+ {data.incomes}</span>
                <span className="stock-data-outcomes">- {data.outcomes}</span>
            </div>}
        </div>)
    }

    render() {
        const now: Date = new Date();
        const monthKey: string = 'month-' + now.getMonth();
        const days: number = daysInMonth();
        const daysMonth: JSX.Element[] = [];
        for (let i = 1; i <= days; i++) {
            const key: string = 'day-' + i;
            daysMonth.push(<TableHeaderColumn key={key} dataField={key} dataAlign="center" width={this.state.showStockFlow ? "150" : "50"}
                thStyle={i === now.getDate() ? { backgroundColor: '#c9e7f7' } : {}}
                tdStyle={i === now.getDate() ? { backgroundColor: '#def3ff' } : {}}
                dataFormat={this.stockDataFormat}>{i}</TableHeaderColumn>);
        }

        const daysPrevious: number = daysInPreviousMonth();
        const monthPreviousKey: string = 'month-' + (now.getMonth()-1);
        const daysPreviousMonth: JSX.Element[] = [];
        for (let i = 1; i <= daysPrevious; i++) {
            const key: string = 'day-' + i;
            daysPreviousMonth.push(<TableHeaderColumn key={key} dataField={key} dataAlign="center" width={this.state.showStockFlow ? "150" : "50"}
                dataFormat={this.stockDataFormat}>{i}</TableHeaderColumn>);
        }

        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(STOCK_DEVICES_SUMMARY_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('today')}</a></li>}
                        {this.props.havePermission(STOCK_DEVICES_SUMMARY_MONTH_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t(monthKey)}</a></li>}
                    </ul>
                </div>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                {this.state.step === 0 && <div className="title">{i18n.t('stock-summary')} ({i18n.t('today')})</div>}
                                {this.state.step === 1 && <div className="title">{i18n.t('stock-summary')} ({i18n.t(monthKey)})</div>}
                            </div>
                        </div>
                        {this.state.step === 0 && <div className="card-body">
                            <BootstrapTable
                                data={this.state.stock}
                                options={this.state.tableOptions}
                                bordered={true}
                                pagination hover>
                                <TableHeaderColumn dataField='id' isKey hidden>{i18n.t('branch-office')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='deviceType' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="250">{i18n.t('device-type')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='branchOffice' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} dataAlign="center" width="250">{i18n.t('branch-office')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='yesterdayStock' dataSort={true} dataAlign="center" width="130">{i18n.t('stock-yesterday')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='minStock' dataSort={true} dataAlign="center" width="130">{i18n.t('stock-min')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='currentStock' dataSort={true} dataAlign="center" width="130">{i18n.t('stock-current')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='incomes' dataSort={true} dataAlign="center" width="130">{i18n.t('incomes')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='lastIncome' dataSort={true} dataAlign="center" width="150">{i18n.t('date-last-income')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='outcomes' dataSort={true} dataAlign="center" width="130">{i18n.t('outcomes')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='updateDate' dataSort={true} dataAlign="center" width="150">{i18n.t('date-update')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>}
                        {this.state.step === 1 && <div className="card-body">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" name="showStockFlow" id="showStockFlow" onChange={this.handleShowStockFlow} checked={this.state.showStockFlow} />
                                <label className="form-check-label" htmlFor="showStockFlow" >{i18n.t('stock-summary-detail')}</label>
                            </div>
                            <BootstrapTable
                                data={this.state.stockMonth}
                                options={this.state.tableOptions}
                                bordered={true}
                                pagination hover>
                                <TableHeaderColumn dataField='id' isKey hidden={true}>{i18n.t('branch-office')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='deviceType' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="250" tdStyle={CODE_STICKY_STYLE} thStyle={CODE_STICKY_STYLE}>{i18n.t('device-type')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='branchOffice' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="250" tdStyle={OFFICE_STICKY_STYLE} thStyle={OFFICE_STICKY_STYLE}>{i18n.t('branch-office')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='incomes' dataSort={true} dataAlign="center" width="130">{i18n.t('incomes')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='outcomes' dataSort={true} dataAlign="center" width="130">{i18n.t('outcomes')}</TableHeaderColumn>
                                {daysMonth}
                            </BootstrapTable>
                        </div>}
                        {this.state.step === 1 && <div>
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{i18n.t('stock-summary')} ({i18n.t(monthPreviousKey)})</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <BootstrapTable
                                    data={this.state.stockPreviousMonth}
                                    options={this.state.tableOptions}
                                    bordered={true}
                                    pagination hover>
                                    <TableHeaderColumn dataField='id' isKey hidden={true}>{i18n.t('branch-office')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceType' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="250" tdStyle={CODE_STICKY_STYLE} thStyle={CODE_STICKY_STYLE}>{i18n.t('device-type')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='branchOffice' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="250" tdStyle={OFFICE_STICKY_STYLE} thStyle={OFFICE_STICKY_STYLE}>{i18n.t('branch-office')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='incomes' dataSort={true} dataAlign="center" width="130">{i18n.t('incomes')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='outcomes' dataSort={true} dataAlign="center" width="130">{i18n.t('outcomes')}</TableHeaderColumn>
                                    {daysPreviousMonth}
                                </BootstrapTable>
                            </div></div>}
                    </div>
                </div>
            </div>
        )
    }
}

export const StockSummaryList = withAppDeviceContext(StockSummaryMain);
