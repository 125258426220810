import * as React from "react";
import flagUSA from "../../images/flag_usa.png";
import flagChile from "../../images/flag_chile.png";
import { RouteComponentProps } from "react-router-dom";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { getAuthenticate, getAuthenticateToken } from "../../services/Session";
import { Message, MessageModel } from "../../utils/Message";
import { MAIN_ROUTE } from "../../const/Routes";
import { ResponseError } from "../../utils/ApiBaseConfig";
import i18n from "../../i18n";
import "./style.css";
import { MESSAGE_WARNING, MESSAGE_ERROR } from "../../const/Message";
import { getWebSocket } from "../../utils/StompBaseConfig";
import { serviceUserId } from "../../utils/Formatter";
import { GroupAdmin } from "../../utils/Config";

const ENTER_KEY_CODE = 13;

interface LoginProps extends RouteComponentProps, AppContextProps {
    getAuthUser: Function;
    getParamFromURL: Function;
}
interface LoginState {
    username: string;
    password: string;
    showMessage: boolean;
    message: MessageModel;
}

export class LoginMain extends React.Component<LoginProps, LoginState>{
    constructor(props: LoginProps) {
        super(props);
        this.loginFunction = this.loginFunction.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.loginTokenFunction = this.loginTokenFunction.bind(this);

        this.state = {
            username: "",
            password: "",
            showMessage: false,
            message: {},
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("login-page");
    }

    componentDidMount() {
        getWebSocket();
        if (this.props.location.search.includes("token")) {
            this.loginTokenFunction();
        }

        if (this.props.location.state && this.props.location.state.redirect) {
            this.setState({ message: { message: i18n.t('login-required'), type: MESSAGE_WARNING }, showMessage: true });
        }
        document.body.classList.add("login-page");
    }

    loginTokenFunction() {
        let pathData: string = this.props.location.search.replace("?", "");
        this.props.showLoading(true);
        getAuthenticateToken(pathData)
            .then((response: string) => {
                this.props.getAuthUser();
                this.props.showLoading(false);
                this.props.history.replace(this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : MAIN_ROUTE);
            }).catch((error: ResponseError) => {
                this.setState({ message: error.message, showMessage: true }, () => this.props.showLoading(false));
            });
    }

    loginFunction() {
        this.props.showLoading(true);
        getAuthenticate(serviceUserId(this.state.username, GroupAdmin), this.state.password)
            .then((response: string) => {
                this.props.getAuthUser();
                this.props.showLoading(false);
                this.props.history.replace(this.props.location.state && this.props.location.state.redirect && this.props.location.state.userId === this.state.username ? this.props.location.state.redirect : MAIN_ROUTE);
            }).catch((error: ResponseError) => {
                var errorMessage = error.message.message;
                if (error.code == 9101 || error.code == 9000) {
                    errorMessage = i18n.t('login-failed')
                }
                this.setState({ message: { message: '' + errorMessage, type: MESSAGE_ERROR }, showMessage: true }, () => this.props.showLoading(false));
            });
    }

    handleUsernameChange(e: any): void {
        const username: string = e.target.value;
        this.setState({ username });
    }

    handlePasswordChange(e: any): void {
        const password: string = e.target.value;
        this.setState({ password });
    }

    handleLogin(e: any) {
        const charCode = (typeof e.which === "number") ? e.which : e.keyCode;
        if (charCode === ENTER_KEY_CODE) this.loginFunction();
    }

    hideMessage() {
        this.setState({ showMessage: false });
    }

    render() {
        const webLogo = require("../../images/" + process.env.REACT_APP_ICON_LOGIN);
        return (
            <div className="container">
                <div className="login-box">
                    <div>
                        <div className="login-form row">
                            <div className="login-header text-center">
                                <img src={webLogo} className="login-logo" />
                                <h4 className="login-title" >Stock Card</h4>
                            </div>
                            <div className="login-body">
                                <Message show={this.state.showMessage} hide={this.hideMessage} message={this.state.message} closable={false} />
                                <div className="control">
                                    <input type="text" id="usrid" name="userid" className="form-control" placeholder={i18n.t('login-user')} onChange={this.handleUsernameChange} onKeyUp={this.handleLogin} value={this.state.username} />
                                </div>
                                <div className="control">
                                    <input type="password" id="psw" name="psw" className="form-control" placeholder={i18n.t('login-password')} onChange={this.handlePasswordChange} onKeyUp={this.handleLogin} />
                                </div>
                                <div className="login-button text-center">
                                    <button onClick={this.loginFunction} className="btn btn-primary" disabled={this.state.username.length === 0 || this.state.password.length === 0}>{i18n.t('login-do')}</button>
                                </div>
                                <br />
                            </div>
                            <div className="login-footer">
                                <span className="text-left">
                                    <a href="?lang=en">
                                        <img onClick={() => i18n.changeLanguage('en')} height={20} width={20} src={flagUSA} />
                                    </a> |
                                        <a href="?lang=es">
                                        <img onClick={() => i18n.changeLanguage('es')} height={20} width={20} src={flagChile} />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export const Login = withAppContext(LoginMain);