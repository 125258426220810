import SockJS from "sockjs-client";
import { Stomp, CompatClient } from "@stomp/stompjs";
import { getToken } from "./ApiBaseConfig";

let stompClient: CompatClient | null = null;

function connectWebSocket(resolve: Function, reject: Function) {
    const socket = new SockJS(window.API_URL + '/api/v2/curus-identity-messaging');
    stompClient = Stomp.over(socket);
    stompClient.connect({ token: getToken() }, resolve, reject);
}

export function getWebSocket(): Promise<CompatClient | null> {
    return new Promise((resolve, reject) => {
        if (stompClient != null) {
            resolve(stompClient);
            return;
        }

        connectWebSocket(() => {
            resolve(stompClient);
        }, (error: any) => {
            resolve(stompClient = null);
        });
    });
}

export function disconnectWebSocket() {
    if (stompClient !== null) {
        stompClient.disconnect();
    }
    console.log("Disconnected");
}