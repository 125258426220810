export const USER_GROUP_PARAM = ":userGroup"
export const USER_ID_PARAM = ":userId"
export const USER_TYPE_PARAM = ":userType"
export const DOCUMENT_ID_PARAM = ":docId"
export const ROLE_ID_PARAM = ":roleId"
export const OFFICE_ID_PARAM = ":officeId"
export const DEVICETYPE_ID_PARAM = ":deviceTypeId"
export const SERIAL_NUMBER_PARAM = ":serialNumber"
export const TYPE_CLIENT = "client"
export const TYPE_USER = "user"
export const CREATE_PARAM = "create"
export const DEVICES_SHIPPING_ID_PARAM = ":shipmentId"
export const EQUIVALENCE_ID_PARAM = ":equivalenceId"
export const NOTICE_CONF_ID_PARAM = ":noticeConfId"

// RUTAS APLICACIÓN
// Iniciales
export const MAIN_ROUTE = "/"
export const LOGIN_ROUTE = "/login"
export const FORBIDDEN_ROUTE = "/forbidden"

// Clientes
export const FIND_CLIENT_ROUTE = "/" + TYPE_CLIENT
export const CLIENT_CREATE_ROUTE = FIND_CLIENT_ROUTE + "/" + USER_ID_PARAM + "/" + CREATE_PARAM
export const CLIENT_MAIN_ROUTE = FIND_CLIENT_ROUTE + "/" + USER_GROUP_PARAM + "/" + USER_ID_PARAM
export const CLIENT_MODIFY_ROUTE = CLIENT_MAIN_ROUTE + "/modify"
export const CLIENT_EVENTS_ROUTE = CLIENT_MAIN_ROUTE + "/events"
export const CLIENT_PASSWORD_ROUTE = CLIENT_MAIN_ROUTE + "/password"
export const CLIENT_GRIDS_ROUTE = CLIENT_MAIN_ROUTE + "/grids"
export const CLIENT_GRID_ASSIGN_ROUTE = CLIENT_GRIDS_ROUTE + "/assign"
export const CLIENT_DOCUMENTS_ROUTE = CLIENT_MAIN_ROUTE + "/documents"
export const CLIENT_DOCUMENT_SIGN_ROUTE = CLIENT_DOCUMENTS_ROUTE + "/" + DOCUMENT_ID_PARAM + "/sign"
export const CLIENT_SOFTTOKEN_ROUTE = CLIENT_MAIN_ROUTE + "/softokens"
export const CLIENT_BIOMETRIC_ROUTE = CLIENT_MAIN_ROUTE + "/biometrics"

// Usuarios
export const FIND_USER_ROUTE = "/" + TYPE_USER
export const USER_CREATE_ROUTE = FIND_USER_ROUTE + "/" + USER_ID_PARAM + "/" + CREATE_PARAM
export const USER_MAIN_ROUTE = FIND_USER_ROUTE + "/" + USER_GROUP_PARAM + "/" + USER_ID_PARAM
export const USER_MODIFY_ROUTE = USER_MAIN_ROUTE + "/modify"
export const USER_EVENTS_ROUTE = USER_MAIN_ROUTE + "/events"
export const USER_SOFTTOKEN_ROUTE = USER_MAIN_ROUTE + "/softokens"

// Personas (Genérico de cliente y usuario)
export const FIND_PERSON_ROUTE = "/" + USER_TYPE_PARAM
export const PERSON_CREATE_ROUTE = FIND_PERSON_ROUTE + "/" + USER_ID_PARAM + "/" + CREATE_PARAM
export const PERSON_MAIN_ROUTE = FIND_PERSON_ROUTE + "/" + USER_GROUP_PARAM + "/" + USER_ID_PARAM
export const PERSON_MODIFY_ROUTE = PERSON_MAIN_ROUTE + "/modify"
export const PERSON_EVENTS_ROUTE = PERSON_MAIN_ROUTE + "/events"
export const PERSON_PASSWORD_ROUTE = PERSON_MAIN_ROUTE + "/password"
export const PERSON_GRIDS_ROUTE = PERSON_MAIN_ROUTE + "/grids"
export const PERSON_GRID_ASSIGN_ROUTE = PERSON_GRIDS_ROUTE + "/assign"
export const PERSON_DOCUMENTS_ROUTE = PERSON_MAIN_ROUTE + "/documents"
export const PERSON_DOCUMENT_SIGN_ROUTE = PERSON_DOCUMENTS_ROUTE + "/" + DOCUMENT_ID_PARAM + "/sign"
export const PERSON_SOFTTOKEN_ROUTE = PERSON_MAIN_ROUTE + "/softokens"
export const PERSON_BIOMETRIC_ROUTE = PERSON_MAIN_ROUTE + "/biometrics"
export const SOFTTOKEN_ACTIVATION_ROUTE = PERSON_SOFTTOKEN_ROUTE + "/activation"
export const PERSON_SELF_PROFILE_ROUTE = "/myprofile"

// Roles
export const ROLES_ROUTE = "/roles"
export const ROLE_EVENTS_ROUTE = ROLES_ROUTE + "/events"
export const ROLE_CREATE_ROUTE = ROLES_ROUTE + "/" + CREATE_PARAM
export const ROLE_SHOW_ROUTE = ROLES_ROUTE + "/" + ROLE_ID_PARAM
export const ROLE_MODIFY_ROUTE = ROLE_SHOW_ROUTE + "/modify"

// Offices
export const OFFICES_ROUTE = "/offices"
export const OFFICE_CREATE_ROUTE = OFFICES_ROUTE + "/create"
export const OFFICE_SHOW_ROUTE = OFFICES_ROUTE + "/" + OFFICE_ID_PARAM
export const OFFICE_MODIFY_ROUTE = OFFICE_SHOW_ROUTE + "/modify"
// Documentos
export const DOCUMENTS_ROUTE = "/documents"
export const DOCUMENT_CREATE_ROUTE = DOCUMENTS_ROUTE + "/create"
export const DOCUMENT_MODIFY_ROUTE = DOCUMENTS_ROUTE + "/" + DOCUMENT_ID_PARAM + "/modify"

// Eventos
export const AUDIT_ROUTE = "/audit"

// Dispositivos
export const DEVICES_ROUTE = "/devices"
export const DEVICES_STOCK_ROUTE = DEVICES_ROUTE + "/stock"
export const DEVICES_STOCK_LOAD_ROUTE = DEVICES_STOCK_ROUTE + "/load"
export const DEVICES_EVENTS_ROUTE = DEVICES_ROUTE + "/events"
export const DEVICES_FIND_DEVICE_ROUTE = DEVICES_ROUTE + "/find"
export const DEVICES_TYPE_DEVICE_ROUTE = DEVICES_ROUTE + "/type"
export const DEVICES_TYPE_DEVICE_CREATE_ROUTE = DEVICES_TYPE_DEVICE_ROUTE + "/create"
export const DEVICES_TYPE_ASSIGN_ROUTE = DEVICES_TYPE_DEVICE_ROUTE + "/" + DEVICETYPE_ID_PARAM + "/assign"
export const DEVICES_TYPE_EDIT_ROUTE = DEVICES_TYPE_DEVICE_ROUTE + "/" + DEVICETYPE_ID_PARAM + "/edit"
export const DEVICES_FIND_DEVICE_LIST_ROUTE = DEVICES_FIND_DEVICE_ROUTE + "/devices"
export const DEVICES_FIND_DEVICE_MOVE_ROUTE = DEVICES_FIND_DEVICE_LIST_ROUTE + "/" + DEVICES_SHIPPING_ID_PARAM + "/move"
export const DEVICES_FIND_DEVICE_DELETE_ROUTE = DEVICES_FIND_DEVICE_LIST_ROUTE + "/" + DEVICES_SHIPPING_ID_PARAM + "/delete"
export const DEVICES_BALANCE_ROUTE = DEVICES_ROUTE + "/balance"
export const DEVICES_BALANCE_MOVEMENT_ROUTE = DEVICES_BALANCE_ROUTE + "/movement"
export const DEVICES_BALANCE_STOCKTAKING_ROUTE = DEVICES_BALANCE_ROUTE + "/stocktaking"


export const DEVICES_SHIPPING_ROUTE = DEVICES_ROUTE + "/shipments"
export const DEVICES_SHIPPING_ASSIGN_ROUTE = DEVICES_SHIPPING_ROUTE + "/assign"
export const DEVICES_SHIPPING_GENERATE_ROUTE = DEVICES_SHIPPING_ROUTE + "/create"
export const DEVICES_SHIPPING_INFO_ROUTE = DEVICES_SHIPPING_ROUTE + "/" + DEVICES_SHIPPING_ID_PARAM
export const DEVICES_SHIPPING_RECEIVE_ROUTE = DEVICES_SHIPPING_INFO_ROUTE + "/receive"

// Sistema
export const SYSTEM_ROUTE = "/system"

// Avisos
export const NOTICE_ROUTE = "/notice"
export const NOTICE_EDIT_ROUTE = NOTICE_ROUTE + "/edit/" + NOTICE_CONF_ID_PARAM

//Equivalencia
export const EQUIVALENCE_ROUTE = SYSTEM_ROUTE + "/equivalence"
export const EQUIVALENCE_MODIFY_ROUTE = EQUIVALENCE_ROUTE + "/" + EQUIVALENCE_ID_PARAM

//Control y reportes
export const CONTROL_REPORTS_ROUTE = "/reports"
export const STATE_OFFICE_REPORT_ROUTE = CONTROL_REPORTS_ROUTE + "/stateoffices"

// Interfaces de parámetros
export interface RouteEquivalenceParams {
    equivalenceId: string;
}

export interface RouteUserParams {
    userId: string;
    userGroup: string;
    userType: string;
    docId: string;
}
export interface RouteRoleParams {
    roleId: string;
}
export interface RouteDocumentParams {
    docId: string;
}
export interface RouteOfficeParams {
    officeId: string;
}
export interface DeviceTypeParams {
    deviceTypeId: string;
}
export interface NoticeConfParams {
    noticeConfId: string;
}
export interface RouteShipmentParams {
    shipmentId: string;
}
export interface RouteAllParams {
    userId?: string;
    userGroup?: string;
    userType?: string;
    roleId?: string;
    officeId?: string;
    docId?: string;
    deviceTypeId?: string;
}

// RUTAS API
export const API_PREFIX = '/api/v2';
export const CLIENT_API = API_PREFIX + '/users';
export const USER_API = API_PREFIX + '/admin/users';
export const GRID_DOCS_TO_SIGN_API = CLIENT_API + '/' + USER_GROUP_PARAM + '/' + USER_ID_PARAM + '/gridcard/assign';
export const GRID_VOUCHER_API = CLIENT_API + '/' + USER_GROUP_PARAM + '/' + USER_ID_PARAM + '/gridcard/' + SERIAL_NUMBER_PARAM;

// RUTAS API CARD WIZARD
export const CW_API_PREFIX = '/api';