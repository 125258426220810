import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { RouteUserParams, DOCUMENT_CREATE_ROUTE, DOCUMENT_MODIFY_ROUTE, DOCUMENT_ID_PARAM } from "../../const/Routes";
import { AppContextProps } from "../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { USER_DOCUMENT_SIGN_PERM, DOCUMENT_UPLOAD_PERM } from "../../const/Permission";
import { documentTemplates, getDocumentSource } from "../../services/Document";
import { DocumentTemplate, DocumentTemplateVersion, DocumentSource } from "../../interfaces/Document";
import { translate, activeFormatter } from "../../utils/Formatter";
import { DocumentContextProps, withAppDocumentContext } from "../../context/DocumentContext";

export interface DocumentsProps extends RouteComponentProps<RouteUserParams>, AppContextProps, DocumentContextProps { }
export interface DocumentsState {
    templates: DocumentTemplate[];
    selected: DocumentTemplate | null;
    selectedVersion: DocumentTemplateVersion | null;
    confirmSign: boolean;
    tableOptions: any;
 }


export class DocumentsMain extends React.Component<DocumentsProps, DocumentsState> {
    constructor(props: any) {
        super(props);
        this.changeChecked = this.changeChecked.bind(this);
        this.changeVersionChecked = this.changeVersionChecked.bind(this);
        this.getDocumentTemplates = this.getDocumentTemplates.bind(this);
        this.download = this.download.bind(this);
        this.create = this.create.bind(this);
        this.modify = this.modify.bind(this);

        this.state = {
            templates: [],
            selected: null,
            selectedVersion: null,
            confirmSign: false,
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }],
                sizePerPage: 5,
                paginationSize: 3,
                pageStartIndex: 1,
            }
        };
    }

    componentWillMount() {
        this.getDocumentTemplates();
    }

    getDocumentTemplates() {
        this.props.showLoading(true);
        documentTemplates()
            .then((templates: DocumentTemplate[]) => {
                this.setState({ templates });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    changeChecked(selected: DocumentTemplate) {
        this.setState({ selected, selectedVersion: null });
    }

    changeVersionChecked(selectedVersion: DocumentTemplateVersion) {
        this.setState({ selectedVersion });
    }

    download() {
        this.props.showLoading(true);
        getDocumentSource(this.state.selectedVersion!.docCode!, this.state.selectedVersion!.versCorrelative!)
            .then((source: DocumentSource) => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    const userId = this.props.authUser!.group + '/' + this.props.authUser!.username;
                    const doc: DocumentTemplate = this.state.selected!;
                    const fileName: string = doc.docName + '(' + doc.versName + ').pdf';
                    const url = window.API_URL + '/api/v2/admin/documents/legacy?userId=' + userId + '&fileName=' + fileName;
                    window.open(encodeURI(url));
                } else {
                    const url = window.URL.createObjectURL(source.blob);
                    window.open(url);
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    create() {
        this.props.setDocument(this.state.selected, null);
        this.props.history.push(DOCUMENT_CREATE_ROUTE);
    }

    modify() {
        this.props.setDocument(this.state.selected, this.state.selectedVersion);
        this.props.history.push(DOCUMENT_MODIFY_ROUTE.replace(DOCUMENT_ID_PARAM, String(this.state.selectedVersion!.versCorrelative!)));
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('documents')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <br></br>
                    <form id="transaction" method="POST">
                        <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{i18n.t('document-select')}</div>
                            </div>
                            <BootstrapTable data={this.state.templates} 
                                options={this.state.tableOptions} 
                                selectRow={{ mode: 'radio', onSelect: this.changeChecked }}>
                                <TableHeaderColumn isKey={true} dataField='docName' dataSort={true} width={'300'}>{i18n.t('name')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='docType' dataAlign={"center"} dataSort={true} dataFormat={translate}>{i18n.t('type')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='deviceType' dataAlign={"center"} dataSort={true} dataFormat={translate}>{i18n.t('device')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='versName' dataAlign={"center"} dataSort={true}>{i18n.t('version')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='creationDate' dataAlign={"center"} dataSort={true}>{i18n.t('date-update')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        {this.state.selected && <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{i18n.t('document-version-select')}</div>
                            </div> 
                            <BootstrapTable data={this.state.selected.versions}
                                bordered={false}
                                options={this.state.tableOptions} 
                                selectRow={{ mode: 'radio', onSelect: this.changeVersionChecked }}>
                                <TableHeaderColumn dataField='versName' dataAlign={"center"} dataSort={true}>{i18n.t('name')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='creationDate' dataAlign={"center"} dataSort={true} isKey={true}>{i18n.t('date-creation')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='active' dataAlign={"center"} dataSort={true} dataFormat={activeFormatter}>{i18n.t('active')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>}
                        {this.props.havePermission(DOCUMENT_UPLOAD_PERM) && <button type="button" id="print" className="btn btn-primary" disabled={this.state.selectedVersion === null} onClick={this.modify}>{i18n.t('modify')}</button>}
                        {this.props.havePermission(USER_DOCUMENT_SIGN_PERM) && <button type="button" id="sign" className="btn btn-primary" disabled={this.state.selectedVersion === null} onClick={this.download}>{i18n.t('download')}</button>}
                        {this.props.havePermission(DOCUMENT_UPLOAD_PERM) && <button type="button" id="create" className="btn btn-info" disabled={this.state.selected === null} onClick={this.create}>{i18n.t('document-version-create')}</button>}
                        <br></br>
                    </form>
                </div>
            </div>
        )
    }

}

export const Documents = withAppDocumentContext(DocumentsMain);
