import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { DocumentVersionAPI, Document, DocumentSignedAPI, DocumentSign, DocumentTemplate, DocumentAPI, DocumentTemplateVersion, DocumentSource, DocumentSourceAPI } from "../interfaces/Document";
import { checkDate, cleanProperty, decodeBase64 } from "../utils/Formatter";
import { DOCUMENT_TYPES } from "../const/Document";
import i18n from "../i18n";
import { DEVICE_TYPES } from "../const/Device";

export function documentTemplates(): Promise<DocumentTemplate[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/admin/documents' })
        .then((response: AxiosResponse) => {
            console.log("[OK] documentTemplates");
            return response.data.map(parseTemplate);
        })
        .catch(apiErrorCallback);
}

export function getDocumentSource(docCode: string, versCorrelative: number): Promise<DocumentSource> {
    return helperAxios({
        method: 'GET',
        url: '/api/v2/admin/documents/' + docCode + '/' + versCorrelative,
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] getDocumentSource");
            return parseDocumentSource(response.data);
        })
        .catch(apiErrorCallback);
}

export function userDocuments(userId: string, group: string): Promise<Document[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/users/' + group + '/' + userId + '/documents' })
        .then((response: AxiosResponse) => {
            console.log("[OK] userDocuments");
            return response.data.map(parseDocumentSigned);
        })
        .catch(apiErrorCallback);
}

export function signDocument(userId: string, group: string, docCode: string, versCorrelative: number): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/users/' + group + '/' + userId + '/documents/' + docCode + '/' + versCorrelative + '/sign'
    })
        .then(() => {
            console.log("[OK] signDocument");
            return true;
        })
        .catch(apiErrorCallback);
}

export function downloadDocument(userId: string, group: string, docCode: string, versCorrelative: number): Promise<DocumentSource> {
    return helperAxios({
        method: 'GET',
        url: '/api/v2/users/' + group + '/' + userId + '/documents/' + docCode + '/' + versCorrelative + '/print'
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] downloadDocument");
            return parseDocumentSource(response.data);
        })
        .catch(apiErrorCallback);
}

export function downloadDocumentWithCode(userId: string, group: string, docCode: string, versCorrelative: number, signCode?: string): Promise<DocumentSource> {
    return helperAxios({
        method: 'GET',
        url: '/api/v2/users/' + group + '/' + userId + '/documents/' + docCode + '/' + versCorrelative + '/print/' + signCode
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] downloadDocument");
            return parseDocumentSource(response.data);
        })
        .catch(apiErrorCallback);
}

export function uploadDocument(docCode: string, document: DocumentTemplateVersion): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/admin/documents/' + docCode,
        data: { key: { docCode, versCorrelative: document.versCorrelative }, ...document }
    })
        .then(() => {
            console.log("[OK] uploadDocument");
            return true;
        })
        .catch(apiErrorCallback);
}

function parseDocumentSigned(version: DocumentVersionAPI): Document {
    return {
        docCode: version.key.document.docCode,
        docName: cleanProperty(version.key.document.docName),
        docType: DOCUMENT_TYPES[cleanProperty(version.key.document.docType)],
        versCorrelative: version.key.versCorrelative!,
        versName: version.versName,
        creationDate: checkDate(version.creationDate),
        signs: version.signs!.map(parseSign),
    };
}

export function parseDocument(version: DocumentVersionAPI): Document {
    return {
        docCode: version.key.document.docCode,
        docName: cleanProperty(version.key.document.docName),
        docType: DOCUMENT_TYPES[cleanProperty(version.key.document.docType)],
        versCorrelative: version.key.versCorrelative!,
        versName: version.versName,
        creationDate: checkDate(version.creationDate),
        signCode: version.signCode,
    };
}

export function parseDocumentSource(document: DocumentSourceAPI): DocumentSource {
    let byteArray = null;
    if (document.source !== null) {
        const byteCharacters = decodeBase64(document.source);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        byteArray = new Uint8Array(byteNumbers);
    }
    return {
        params: document.params,
        blob: byteArray ? new Blob([byteArray], { type: 'application/pdf' }) : null,
        base64: document.source,
    };
}

function parseSign(sign: DocumentSignedAPI): DocumentSign {
    return {
        signCode: cleanProperty(sign.signCode),
        signDate: sign.signDate ? new Date(sign.signDate) : i18n.t('no-data'),
    }
}

function parseTemplate(template: DocumentAPI): DocumentTemplate {
    const versions: DocumentTemplateVersion[] = template.versions ? template.versions.map(parseTemplateVersion) : [];
    const active: DocumentTemplateVersion | undefined = versions.find(version => version.active);
    return {
        docCode: template.docCode,
        docName: cleanProperty(template.docName),
        docType: DOCUMENT_TYPES[cleanProperty(template.docType)],
        deviceType: DEVICE_TYPES[cleanProperty(template.deviceType)],
        versName: active ? active.versName : i18n.t('no-data'),
        creationDate: active ? active.creationDate : i18n.t('no-data'),
        versions
    }
}

function parseTemplateVersion(version: DocumentVersionAPI): DocumentTemplateVersion {
    return {
        docCode: version.key.document.docCode,
        versCorrelative: version.key.versCorrelative!,
        versName: version.versName,
        creationDate: checkDate(version.creationDate),
        active: version.active,
        source: '',
    }
}
