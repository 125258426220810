import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DeviceType } from "../../../interfaces/DeviceType";
import { activeFormatter } from "../../../utils/Formatter";
import { getDeviceTypeList } from "../../../services/DeviceType";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { ToggleLink } from "../../../utils/ToggleLink";
import { DEVICES_TYPE_ASSIGN_ROUTE, DEVICETYPE_ID_PARAM, DEVICES_TYPE_DEVICE_CREATE_ROUTE, DEVICES_TYPE_EDIT_ROUTE } from "../../../const/Routes";
import { BASE_PERM, DEVICE_TYPE_DO_ASIGN_PERM, DEVICE_TYPE_UPDATE_PERM, DEVICE_TYPE_CREATE_PERM } from "../../../const/Permission";


export interface DeviceTypeProps extends RouteComponentProps, AppContextProps { }
export interface DeviceTypeState {
    data: DeviceType[];
    selected: DeviceType | null;
    code: string | null;
}

export class DeviceTypeMain extends React.Component<DeviceTypeProps, DeviceTypeState>{
    reachTheLastPage: boolean = false;
    constructor(props: DeviceTypeProps) {
        super(props);
        this.getDeviceTypesFromAPI = this.getDeviceTypesFromAPI.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.state = {
            data: [],
            code: null,
            selected: null
        }
    }

    componentDidMount() {
        this.getDeviceTypesFromAPI();
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList()
            .then((deviceTypeArray: DeviceType[]) => {
                this.setState({ data: deviceTypeArray }, this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    onSelect(selected: DeviceType) {
        this.setState({ selected, code: selected.codeDeviceType });
    }

    render() {
        const options = {
            noDataText: i18n.t('table-empty'),
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '20', value: 20
            }, {
                text: '50', value: 50
            }],
            sizePerPage: 10,
            paginationSize: 3,
            pageStartIndex: 1
        };

        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                </div>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('device-type-adm')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group col-sm-8">
                                {this.props.havePermission(DEVICE_TYPE_CREATE_PERM) && <ToggleLink to={DEVICES_TYPE_DEVICE_CREATE_ROUTE}
                                    className="btn btn-success">{i18n.t('create')}</ToggleLink>
                                }
                                {this.props.havePermission(DEVICE_TYPE_UPDATE_PERM) && <ToggleLink to={DEVICES_TYPE_EDIT_ROUTE.replace(DEVICETYPE_ID_PARAM, this.state.code ? this.state.code : '')}
                                    className="btn btn-info" disabled={this.state.selected === null}>{i18n.t('modify')}</ToggleLink>
                                }
                                {this.props.havePermission(DEVICE_TYPE_DO_ASIGN_PERM) && <ToggleLink to={DEVICES_TYPE_ASSIGN_ROUTE.replace(DEVICETYPE_ID_PARAM, this.state.code ? this.state.code : '')}
                                    className="btn btn-warning" disabled={this.state.selected === null}>{i18n.t('assign')}</ToggleLink>
                                }
                                <BootstrapTable
                                    data={this.state.data}
                                    options={options}
                                    selectRow={{ mode: 'radio', onSelect: this.onSelect }}
                                    bordered hover pagination={this.state.data.length > 0} >
                                    <TableHeaderColumn dataField='codeDeviceType' isKey tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" width="130">{i18n.t('code')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='nameDeviceType' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" width="250">{i18n.t('name')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='available' dataAlign={"center"} dataSort={true} dataFormat={activeFormatter} editable={{ type: 'checkbox' }}>{i18n.t('active')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const DeviceTypeList = withAppContext(DeviceTypeMain);
