import { CARD_STATES } from "./GridCard";
import { PASSWORD_STATES } from "./Password";
import { EventAction } from "../interfaces/Event";
import { SOFTTOKEN_STATES } from "./SoftToken";


export const BRANCH_OFFICE_CODE_COLUMN = 'branchOfficeCode';
export const BRANCH_OFFICE_NAME_COLUMN = 'branchOfficeName';
export const CHALLENGE_SN_COLUMN = 'challengeSerialNumber';
export const CHALLENGE_STATE_COLUMN = 'challengeState';
export const CHALLENGE_TYPE_COLUMN = 'deviceType';
export const CHANNEL_CODE_COLUMN = 'channelCode';
export const CHANNEL_NAME_COLUMN = 'channelName';
export const CLIENT_ID_COLUMN = 'userClientId';
export const CLIENT_GROUP_COLUMN = 'userClientGroup';
export const CODE_ROLE_COLUMN = 'codeRole';
export const COMMENT_COLUMN = 'comment';
export const DOC_ID_COLUMN = 'clientDocId';
export const DOC_SIGNED_CORRELATIVE_COLUMN = 'docSignedCorrelative';
export const DOC_TYPE_COLUMN = 'clientDocType';
export const EVENT_ACTION_CODE_COLUMN = 'eventAction';
export const EVENT_ACTION_DATE_COLUMN = 'eventActionDate';
export const EVENT_CORRELATIVE_COLUMN = 'eventCorrelative';
export const EVENT_DETAILS_COLUMN = 'eventDetails';
export const IP_ADDRESS_COLUMN = 'ipAddress';
export const USER_CORRELATIVE_COLUMN = 'userCorrelative';
export const USER_GROUP_COLUMN = 'userGroup';
export const USER_ID_COLUMN = 'userId';

export const ALL_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    CLIENT_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    CHALLENGE_SN_COLUMN,
    CHALLENGE_TYPE_COLUMN,
    CHALLENGE_STATE_COLUMN,
    COMMENT_COLUMN,
    // USER_CORRELATIVE_COLUMN,
    // USER_GROUP_COLUMN,
    // CLIENT_GROUP_COLUMN,
    DOC_ID_COLUMN,
    // DOC_SIGNED_CORRELATIVE_COLUMN,
    DOC_TYPE_COLUMN,
    BRANCH_OFFICE_CODE_COLUMN,
    BRANCH_OFFICE_NAME_COLUMN,
    CHANNEL_CODE_COLUMN,
    CHANNEL_NAME_COLUMN,
    // CODE_ROLE_COLUMN,
    IP_ADDRESS_COLUMN,
];

export const SUMMARY_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    //CLIENT_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    //CHALLENGE_SN_COLUMN,
    //CHALLENGE_TYPE_COLUMN,
    //CHALLENGE_STATE_COLUMN,
    //COMMENT_COLUMN,
    // USER_CORRELATIVE_COLUMN,
    // USER_GROUP_COLUMN,
    // CLIENT_GROUP_COLUMN,
    //DOC_ID_COLUMN,
    // DOC_SIGNED_CORRELATIVE_COLUMN,
    //DOC_TYPE_COLUMN,
    BRANCH_OFFICE_CODE_COLUMN,
    BRANCH_OFFICE_NAME_COLUMN,
    //CHANNEL_CODE_COLUMN,
    //CHANNEL_NAME_COLUMN,
    // CODE_ROLE_COLUMN,
    IP_ADDRESS_COLUMN,
];

export const SYSTEM_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    CHANNEL_CODE_COLUMN,
    CHANNEL_NAME_COLUMN,
    IP_ADDRESS_COLUMN,
];


export const SOFTTOKEN_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    CHALLENGE_SN_COLUMN,
    CHALLENGE_STATE_COLUMN,
    COMMENT_COLUMN,
    BRANCH_OFFICE_CODE_COLUMN,
    BRANCH_OFFICE_NAME_COLUMN,
    CHANNEL_CODE_COLUMN,
    CHANNEL_NAME_COLUMN,
    IP_ADDRESS_COLUMN,
];

export const CARD_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    CHALLENGE_SN_COLUMN,
    CHALLENGE_STATE_COLUMN,
    COMMENT_COLUMN,
    DOC_ID_COLUMN,
    DOC_TYPE_COLUMN,
    BRANCH_OFFICE_CODE_COLUMN,
    BRANCH_OFFICE_NAME_COLUMN,
    CHANNEL_CODE_COLUMN,
    CHANNEL_NAME_COLUMN,
    IP_ADDRESS_COLUMN,
];

export const PASSWORD_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    CHALLENGE_SN_COLUMN,
    CHALLENGE_STATE_COLUMN,
    BRANCH_OFFICE_CODE_COLUMN,
    BRANCH_OFFICE_NAME_COLUMN,
    CHANNEL_CODE_COLUMN,
    CHANNEL_NAME_COLUMN,
    IP_ADDRESS_COLUMN,
];

export const USER_EVENT_TABLE_COLUMNS: string[] = [
    EVENT_CORRELATIVE_COLUMN,
    USER_ID_COLUMN,
    EVENT_ACTION_DATE_COLUMN,
    EVENT_ACTION_CODE_COLUMN,
    EVENT_DETAILS_COLUMN,
    CHALLENGE_SN_COLUMN,
    CHALLENGE_TYPE_COLUMN,
    CHALLENGE_STATE_COLUMN,
    COMMENT_COLUMN,
    DOC_ID_COLUMN,
    DOC_TYPE_COLUMN,
    BRANCH_OFFICE_CODE_COLUMN,
    BRANCH_OFFICE_NAME_COLUMN,
    CHANNEL_CODE_COLUMN,
    CHANNEL_NAME_COLUMN,
    IP_ADDRESS_COLUMN,
];

export const EVENTS_TABLE_COLUMN_WIDTH: { [key: string]: string } = {
    [EVENT_CORRELATIVE_COLUMN]: '115',
    [EVENT_ACTION_DATE_COLUMN]: '150',
    [EVENT_ACTION_CODE_COLUMN]: '150',
    [EVENT_DETAILS_COLUMN]: '320',
    [CHALLENGE_SN_COLUMN]: '110',
    [CHALLENGE_TYPE_COLUMN]: '150',
    [CHALLENGE_STATE_COLUMN]: '150',
    [USER_CORRELATIVE_COLUMN]: '115',
    [USER_ID_COLUMN]: '150',
    [USER_GROUP_COLUMN]: '150',
    [CLIENT_ID_COLUMN]: '150',
    [CLIENT_GROUP_COLUMN]: '150',
    [BRANCH_OFFICE_CODE_COLUMN]: '80',
    [BRANCH_OFFICE_NAME_COLUMN]: '220',
    [CHANNEL_CODE_COLUMN]: '150',
    [CHANNEL_NAME_COLUMN]: '150',
    [CODE_ROLE_COLUMN]: '90',
    [COMMENT_COLUMN]: '200',
    [DOC_ID_COLUMN]: '150',
    [DOC_SIGNED_CORRELATIVE_COLUMN]: '150',
    [DOC_TYPE_COLUMN]: '150',
    [IP_ADDRESS_COLUMN]: '130',
}

export const EVENTS_TABLE_COLUMN_ALIGN: { [key: string]: "left" | "right" | "start" | "center" | "end" | undefined } = {
    [EVENT_CORRELATIVE_COLUMN]: 'center',
    [EVENT_ACTION_DATE_COLUMN]: 'center',
    [EVENT_ACTION_CODE_COLUMN]: 'center',
    [EVENT_DETAILS_COLUMN]: 'left',
    [CHALLENGE_SN_COLUMN]: 'center',
    [CHALLENGE_TYPE_COLUMN]: 'center',
    [CHALLENGE_STATE_COLUMN]: 'center',
    [USER_CORRELATIVE_COLUMN]: 'center',
    [USER_ID_COLUMN]: 'center',
    [USER_GROUP_COLUMN]: 'left',
    [CLIENT_ID_COLUMN]: 'center',
    [CLIENT_GROUP_COLUMN]: 'left',
    [BRANCH_OFFICE_CODE_COLUMN]: 'center',
    [BRANCH_OFFICE_NAME_COLUMN]: 'left',
    [CHANNEL_CODE_COLUMN]: 'center',
    [CHANNEL_NAME_COLUMN]: 'left',
    [CODE_ROLE_COLUMN]: 'center',
    [COMMENT_COLUMN]: 'left',
    [DOC_ID_COLUMN]: 'center',
    [DOC_SIGNED_CORRELATIVE_COLUMN]: 'center',
    [DOC_TYPE_COLUMN]: 'center',
    [IP_ADDRESS_COLUMN]: 'center',
}

export const EVENTS_TABLE_HEADERS: { [key: string]: string } = {
    [EVENT_CORRELATIVE_COLUMN]: 'id-list',
    [EVENT_ACTION_DATE_COLUMN]: 'date',
    [EVENT_ACTION_CODE_COLUMN]: 'action',
    [EVENT_DETAILS_COLUMN]: 'details',
    [CHALLENGE_SN_COLUMN]: 'serial-number-abbr',
    [CHALLENGE_TYPE_COLUMN]: 'type',
    [CHALLENGE_STATE_COLUMN]: 'status',
    [USER_CORRELATIVE_COLUMN]: 'id-list',
    [USER_ID_COLUMN]: 'user',
    [USER_GROUP_COLUMN]: 'group',
    [CLIENT_ID_COLUMN]: 'client',
    [CLIENT_GROUP_COLUMN]: 'group',
    [BRANCH_OFFICE_CODE_COLUMN]: 'id-list',
    [BRANCH_OFFICE_NAME_COLUMN]: 'name',
    [CHANNEL_CODE_COLUMN]: 'id-list',
    [CHANNEL_NAME_COLUMN]: 'name',
    [CODE_ROLE_COLUMN]: 'code',
    [COMMENT_COLUMN]: 'comment',
    [DOC_ID_COLUMN]: 'Folio',
    [DOC_SIGNED_CORRELATIVE_COLUMN]: 'id-list',
    [DOC_TYPE_COLUMN]: 'type',
    [IP_ADDRESS_COLUMN]: 'ip-address',
}

export const EVENT_STATES: { [key: string]: string } = {
    ...SOFTTOKEN_STATES,
    ...CARD_STATES,
    ...PASSWORD_STATES,
}

export const EVENT_ACTION_CREATE = 'create';
export const EVENT_ACTION_DELETE = 'delete';
export const EVENT_ACTION_UPDATE = 'update';
export const EVENT_ACTION_LOCK = 'lock';
export const EVENT_ACTION_UNLOCK = 'unlock';
export const EVENT_ACTION_ENABLE = 'enable';
export const EVENT_ACTION_DISABLE = 'disable';
export const EVENT_ACTION_ACTIVATE = 'activate';
export const EVENT_ACTION_DEACTIVATE = 'deactivate';
export const EVENT_ACTION_QRGEN = 'qr-generation';
export const EVENT_ACTION_LOG = 'log';
export const EVENT_ACTION_ASSIGN = 'assign';
export const EVENT_ACTION_UPLOAD = 'upload';
export const EVENT_ACTION_UNASSIGN = 'unassign';
export const EVENT_ACTION_SIGN = 'sign';
export const EVENT_ACTION_REISSUE = 'reissue';
export const EVENT_ACTION_RUN = 'run';

export const EVENT_ACTIONS: { [key: string]: EventAction } = {
    "CREATE": EVENT_ACTION_CREATE,
    "DELETE": EVENT_ACTION_DELETE,
    "UPDATE": EVENT_ACTION_UPDATE,
    "LOCK": EVENT_ACTION_LOCK,
    "UNLOCK": EVENT_ACTION_UNLOCK,
    "ENABLE": EVENT_ACTION_ENABLE,
    "DISABLE": EVENT_ACTION_DISABLE,
    "ACTIVATE": EVENT_ACTION_ACTIVATE,
    "QRGEN": EVENT_ACTION_QRGEN,
    "ASSIGN": EVENT_ACTION_ASSIGN,
    "UNASSIGN": EVENT_ACTION_UNASSIGN,
    "SIGN": EVENT_ACTION_SIGN,
    "REISSUE": EVENT_ACTION_REISSUE,
    "RUN": EVENT_ACTION_RUN,
    "UPLOAD": EVENT_ACTION_UPLOAD,
}

export const EVENT_COMMENTS: string[] = [
    "reason-created-not-assigned",
    "reason-doesnt-work",
    "reason-device-change",
    "reason-lost",
    "reason-previously-locked",
    "reason-stolen",
    "reason-other",
]
