import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { API_PREFIX } from "../const/Routes";
import { Equivalence, EquivalenceAPI } from "../interfaces/Equivalence";

export function getEquivalencesList(): Promise<Equivalence[]> {
    return helperAxios(API_PREFIX + '/system/parameters/equivalence/all')
        .then((response: AxiosResponse) => {
            console.log('[OK] getEquivalencesList');
            return response.data.map(parseEquivalence);
        })
        .catch(apiErrorCallback);
}

export function parseEquivalence(equivalenceAPI: EquivalenceAPI): Equivalence {
    return {
        id: equivalenceAPI.id,
        equivalenceType: equivalenceAPI.equivalenceType,
        stockcardValue: equivalenceAPI.stockCardValue,
        cardwizardValue: equivalenceAPI.cardWizardValue
    }
}

export function parseEquivalenceAPI(equivalence: Equivalence): EquivalenceAPI {
    return {
        id: equivalence.id,
        equivalenceType: equivalence.equivalenceType,
        stockCardValue: equivalence.stockcardValue,
        cardWizardValue: equivalence.cardwizardValue
    }
}

export function getEquivalence(id: number): Promise<Equivalence> {
    return helperAxios(API_PREFIX + '/system/parameters/equivalence/' + id.toString())
    .then((response: AxiosResponse) => {
        console.log('[OK] getEquivalence');
        return parseEquivalence(response.data);
    })
    .catch(apiErrorCallback);
}

export function updateEquivalence(equivalence: Equivalence): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/system/parameters/equivalence/update/' + equivalence.id.toString(),
        data: parseEquivalenceAPI(equivalence)
    })
        .then(() => {
            console.log('[OK] updateEquivalence');
            return true;
        })
        .catch(apiErrorCallback);
}

export function getEquivalenceTypes(): Promise<string[]> {
    return helperAxios(API_PREFIX + '/system/parameters/equivalence/types')
        .then((response: AxiosResponse) => {
            console.log('[OK] getEquivalenceTypes');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function createEquivalence(equivalence: Equivalence): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/system/parameters/equivalence/create/new',
        data: parseEquivalenceAPI(equivalence)
    })
        .then(() => {
            console.log('[OK] createEquivalence');
            return true;
        })
        .catch(apiErrorCallback);
}

export function deleteEquivalence(id: number): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/system/parameters/equivalence/delete/' + id.toString(),
    })
        .then(() => {
            console.log('[OK] createEquivalence');
            return true;
        })
        .catch(apiErrorCallback);
}