import React from 'react';
import { IdentityUser, IdentityUserWithOffice } from './../interfaces/Identity';
import { withAppContext } from './AppContext';
import { withRouter } from 'react-router';

export interface UserContextProps {
  user: IdentityUserWithOffice | null;
  setUser: Function;
  refreshUser: Function;
}

const UserContext = React.createContext<UserContextProps>({
  user: null,
  setUser: () => { },
  refreshUser: () => { }
});

export const UserContextProvider = UserContext.Provider;
export const UserContextConsumer = UserContext.Consumer;

export function withAppUserContext(Component: any) {
  return withAppContext((props: any) => (
    <UserContextConsumer>
      {userContext => {
        return <Component {...props} {...userContext} />
      }}
    </UserContextConsumer>
  ))
}

export function withUserContext(Component: any) {
  return withRouter((props: any) => (
    <UserContextConsumer>
      {userContext => {
        return <Component {...props} {...userContext} />
      }}
    </UserContextConsumer>
  ))
}
