import { NoticeStatus } from "../interfaces/Notice";

export const NOTICE_STATUS_PENDING = "pending";
export const NOTICE_STATUS_SENDING = "sending";
export const NOTICE_STATUS_ERROR = "error";
export const NOTICE_STATUS_SENT = "sent";
export const NOTICE_STATUS_DISCARDED = "discarded";

export const API_NOTICE_STATUS_PENDING = "PDG";
export const API_NOTICE_STATUS_SENDING = "SDG";
export const API_NOTICE_STATUS_ERROR = "ERR";
export const API_NOTICE_STATUS_SENT = "SNT";
export const API_NOTICE_STATUS_DISCARDED = "DSD";

export const NOTICE_STATES: { [key: string]: NoticeStatus } = {
    [API_NOTICE_STATUS_PENDING]: NOTICE_STATUS_PENDING,
    [API_NOTICE_STATUS_SENDING]: NOTICE_STATUS_SENDING,
    [API_NOTICE_STATUS_ERROR]: NOTICE_STATUS_ERROR,
    [API_NOTICE_STATUS_SENT]: NOTICE_STATUS_SENT,
    [API_NOTICE_STATUS_DISCARDED]: NOTICE_STATUS_DISCARDED
}

export const API_NOTICE_STATES: { [key: string]: string } = {
    [NOTICE_STATUS_PENDING]: API_NOTICE_STATUS_PENDING,
    [NOTICE_STATUS_SENDING]: API_NOTICE_STATUS_SENDING,
    [NOTICE_STATUS_ERROR]: API_NOTICE_STATUS_ERROR,
    [NOTICE_STATUS_SENT]: API_NOTICE_STATUS_SENT,
    [NOTICE_STATUS_DISCARDED]: API_NOTICE_STATUS_DISCARDED
}