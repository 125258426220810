import axios from 'axios';
import i18n from "../i18n";
import { History } from 'history';
import { LOGIN_ROUTE, FORBIDDEN_ROUTE } from '../const/Routes';
import { MESSAGE_ERROR, MESSAGE_WARNING, MESSAGE_SUCCESS, MessageTypes } from '../const/Message';
import { MessageModel } from './Message';
import { SystemParameter } from '../interfaces/System';

declare global {
    interface Window { API_URL: string; CW_API_URL: string; }
}

export interface ResponseError {
    message: MessageModel;
    status: number;
    code: number;
}

/**
 * Token para realizar llamados
 */
export function setToken(token: string): void {
    localStorage.setItem('token', token);
    helperAxios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();
}
export function getToken(): string | null {
    return localStorage.getItem('token');
}
export function cleanToken() {
    localStorage.removeItem('token');
}

export const helperAxios = axios.create({
    baseURL: window.API_URL,
    timeout: 30000,
});

helperAxios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();
helperAxios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
helperAxios.interceptors.request.use(requestConfig => {
    return requestConfig;
}, error => {
    return Promise.reject(error);
});

helperAxios.interceptors.response.use(response => {
    if (response.headers['token-refresh'] !== undefined) {
        setToken(response.headers["token-refresh"]);
    }
    return response;
}, error => {
    if (error.response.headers['token-refresh'] !== undefined) {
        setToken(error.response.headers["token-refresh"]);
    }
    if (error.response && error.response.status === 401) {
        localStorage.clear();
    }
    return Promise.reject(error);
});

/**
 * Definición de conexión con Card Wizard
 */
export function setJWT(jwt: string): void {
    localStorage.setItem('JWT', jwt);
    helperAxiosCW.defaults.headers.common['JWT'] = getJWT();
}
export function getJWT(): string | null {
    return localStorage.getItem('JWT');
}
export function cleanJWT() {
    localStorage.removeItem('JWT');
}

export const helperAxiosCW = axios.create({
    baseURL: window.CW_API_URL,
    timeout: 30000,
});

helperAxiosCW.defaults.headers.common['JWT'] = getJWT();
helperAxiosCW.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
helperAxiosCW.interceptors.request.use(requestConfig => {
    return requestConfig;
}, error => {
    return Promise.reject(error);
});

helperAxiosCW.interceptors.response.use(response => {
    if (response.headers['jwt'] !== undefined) {
        setJWT(response.headers["jwt"]);
    }
    return response;
}, error => {
    if (error.response.headers['jwt'] !== undefined) {
        setJWT(error.response.headers["jwt"]);
    }
    if (error.response && error.response.status === 401) {
        localStorage.clear();
    }
    return Promise.reject(error);
});
/**
 * Fin definición
 */


export const TIMEOUT_RESPONSE_ERROR: ResponseError = {
    message: {
        title: i18n.t('connection-error'),
        message: i18n.t('connection-error'),
        type: MESSAGE_ERROR,
    },
    status: 500,
    code: 500,
}

const MESSAGE_TYPES: { [key: string]: MessageTypes } = {
    "WARN": MESSAGE_WARNING,
    "ERROR": MESSAGE_ERROR,
    "SUCCESS": MESSAGE_SUCCESS,
}

export function apiErrorCallback(error: any) {

    if (error.response) {
        const message: MessageModel = {
            message: error.response.data.detailUserMessage + ' [' + error.response.data.code + ']',
            type: error.response.data.type ? MESSAGE_TYPES[error.response.data.type] : MESSAGE_ERROR,
        };
        const errorResponse: ResponseError = { message, status: error.response.status, code: error.response.data.code }

        return Promise.reject(errorResponse);
    } else {
        return Promise.reject(TIMEOUT_RESPONSE_ERROR);
    }
}

export function apiHandleErrorCode(code: number, history: History): void {
    if (code === 401)
        history.replace(LOGIN_ROUTE, { redirect: history.location.pathname });
    else if (code === 403)
        history.push(FORBIDDEN_ROUTE);
}

export function handleSystemBussy(status: SystemParameter, redirect: Function): void {
    if (status.value === 'true') {
        redirect({
            message: i18n.t('system-bussy', {
                process: status.description,
                date: status.updateDate.toString()
            }),
            type: MESSAGE_WARNING,
            time: 6000,
        });
    }
}

export const CARD_STOCK_ID = 2003;
export const GROUP_ID = 1;
export const WIZARD_USER = 'wizard';
export const WIZARD_PASS = 'Kimn123456';
