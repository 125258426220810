import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ShipmentGenerated } from "../../../interfaces/Shipment";
import { RouteUserParams, DEVICES_ROUTE } from "../../../const/Routes";
import { withAppContext, AppContextProps } from "../../../context/AppContext";
import { ShipmentsGenerated } from "./assign/List";
import { ShipmentsAssigned } from "./assign/Summary";
import { DeviceContextProps, withAppDeviceContext } from "../../../context/DeviceContext";

interface ShipmentAssignProps extends RouteComponentProps<RouteUserParams>, AppContextProps, DeviceContextProps { }
interface ShipmentAssignState {
    step: number;
    shipments: ShipmentGenerated[];
}

export class ShipmentAssignMain extends React.Component<ShipmentAssignProps, ShipmentAssignState> {
    loading: boolean = false;

    constructor(props: ShipmentAssignProps) {
        super(props);
        this.next = this.next.bind(this);
        this.state = {
            step: 1,
            shipments: [],
        }
    }

    componentWillMount() {
        if (this.props.branchOffices.length === 0) {
            this.props.showLoading(this.loading = true);
        }
    }

    componentWillUpdate(props: ShipmentAssignProps) {
        if (props.branchOffices.length !== 0 && this.loading) {
            this.props.showLoading(this.loading = false);
        }
    }

    next(shipments: ShipmentGenerated[]) {
        if (this.state.step === 2) {
            this.props.history.replace(DEVICES_ROUTE);
            return;
        }

        this.setState(state => ({ ...state, step: state.step + 1, shipments }));
    }

    render() {
        if (this.props.branchOffices.length === 0) return <div></div>;
        return (
            <div>
                {this.state.step === 1 && <ShipmentsGenerated next={this.next} />}
                {this.state.step === 2 && <ShipmentsAssigned next={this.next} assigned={this.state.shipments} />}
            </div>
        )
    }
}

export const ShipmentAssign = withAppDeviceContext(ShipmentAssignMain);