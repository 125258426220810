import * as React from "react";
import flagUSA from "../../images/flag_usa.png";
import flagChile from "../../images/flag_chile.png";
import { RouteComponentProps } from "react-router-dom";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { getAuthenticatorsIntelliTrust, getAuthenticateIntelliTrust, getAuthenticateToken, completeAuthenticateIntelliTrust } from "../../services/Session";
import { Message, MessageModel } from "../../utils/Message";
import { MAIN_ROUTE } from "../../const/Routes";
import { ResponseError } from "../../utils/ApiBaseConfig";
import i18n from "../../i18n";
import "./style.css";
import { MESSAGE_WARNING, MESSAGE_ERROR } from "../../const/Message";
import { getWebSocket } from "../../utils/StompBaseConfig";
import { serviceUserId } from "../../utils/Formatter";
import { GroupAdmin } from "../../utils/Config";
import { IntelliTrustChallengeRequest, IntelliTrustAuthenticators } from "../../interfaces/IntelliTrust";

const ENTER_KEY_CODE = 13;

interface LoginIntelliTrustProps extends RouteComponentProps, AppContextProps {
    getAuthUser: Function;
    getParamFromURL: Function;
}
interface LoginIntelliTrustState {
    username: string;
    showMessage: boolean;
    message: MessageModel;
    challengeTypes: string[];
    challengeType: string;
    otpDeliveryTypes: string[];
    otpDeliveryType: string;
    challengeResponse: string;
    challengeToken: string;
    showOtpType: boolean;
    showPhase: number;
}

export class LoginIntelliTrustMain extends React.Component<LoginIntelliTrustProps, LoginIntelliTrustState>{
    constructor(props: LoginIntelliTrustProps) {
        super(props);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleSelectChallengeType = this.handleSelectChallengeType.bind(this);
        this.handleSelectOtpType = this.handleSelectOtpType.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.loginTokenFunction = this.loginTokenFunction.bind(this);
        this.getAvailableChallenges = this.getAvailableChallenges.bind(this);
        this.sendAuthenticateChallenge = this.sendAuthenticateChallenge.bind(this);
        this.completeAuthenticationChallenge = this.completeAuthenticationChallenge.bind(this);
        this.handleResponseChange = this.handleResponseChange.bind(this);
        this.reset = this.reset.bind(this);

        this.state = {
            username: "",
            showMessage: false,
            message: {},
            challengeTypes: [],
            challengeType: "",
            otpDeliveryTypes: [],
            otpDeliveryType: "",
            challengeResponse: "",
            challengeToken: "",
            showOtpType: false,
            showPhase: 0,
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("login-page");
    }

    componentDidMount() {
        getWebSocket();
        if (this.props.location.search.includes("token")) {
            this.loginTokenFunction();
        }

        if (this.props.location.state && this.props.location.state.redirect) {
            this.setState({ message: { message: i18n.t('login-required'), type: MESSAGE_WARNING }, showMessage: true });
        }
        document.body.classList.add("login-page");
    }

    loginTokenFunction() {
        let pathData: string = this.props.location.search.replace("?", "");
        this.props.showLoading(true);
        getAuthenticateToken(pathData)
            .then((response: string) => {
                this.props.getAuthUser();
                this.props.showLoading(false);
                this.props.history.replace(this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : MAIN_ROUTE);
            }).catch((error: ResponseError) => {
                this.setState({ message: error.message, showMessage: true }, () => this.props.showLoading(false));
            });
    }

    getAvailableChallenges() {
        this.props.showLoading(true);
        getAuthenticatorsIntelliTrust(this.state.username)
            .then((response: IntelliTrustAuthenticators) => {
                this.setState({ challengeTypes: response.authenticationTypes, otpDeliveryTypes: response.availableOTPDelivery, showPhase: 1 }, () => this.props.showLoading(false));
            })
            .catch((error: ResponseError) => {
                this.setState({ message: error.message, showMessage: true }, () => this.props.showLoading(false));
            });
    }

    sendAuthenticateChallenge() {
        this.props.showLoading(true);
        getAuthenticateIntelliTrust(this.state.username, this.state.challengeType, this.state.otpDeliveryType)
            .then((response: IntelliTrustChallengeRequest) => {
                this.setState({ showPhase: 2, challengeToken: response.token }, () => this.props.showLoading(false));
            })
            .catch((error: ResponseError) => {
                this.setState({ message: error.message, showMessage: true }, () => this.props.showLoading(false));
            });
    }

    completeAuthenticationChallenge() {
        this.props.showLoading(true);
        completeAuthenticateIntelliTrust(this.state.username, this.state.challengeType, this.state.otpDeliveryType, this.state.challengeToken, this.state.challengeResponse)
            .then((response: string) => {
                this.props.getAuthUser();
                this.props.showLoading(false);
                this.props.history.replace(this.props.location.state && this.props.location.state.redirect && this.props.location.state.userId === this.state.username ? this.props.location.state.redirect : MAIN_ROUTE);
            }).catch((error: ResponseError) => {
                var errorMessage = error.message.message;
                if (error.code == 9101 || error.code == 9000) {
                    errorMessage = i18n.t('login-failed')
                }
                this.setState({ showPhase: 0, challengeResponse: '', message: { message: '' + errorMessage, type: MESSAGE_ERROR }, showMessage: true }, () => this.props.showLoading(false));
            });
    }

    reset() {
        this.setState({ showPhase: 0, challengeResponse: '', username: '' });
    }

    handleSelectChallengeType(e: any): void {
        e.preventDefault();
        const challengeType: string = e.target.value == "NONE" ? "" : e.target.value;
        if (challengeType === "OTP") {
            this.setState({ challengeType: challengeType, showOtpType: true, showPhase: 1 });
        } else {
            this.setState({ challengeType: challengeType, showOtpType: false, showPhase: 1 });
        }
    }

    handleSelectOtpType(e: any): void {
        e.preventDefault();
        const otpType: string = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ otpDeliveryType: otpType, showPhase: 1 });
    }

    handleUsernameChange(e: any): void {
        const username: string = e.target.value;
        this.setState({ username, showPhase: 0 });
    }

    handleResponseChange(e: any): void {
        const response: string = e.target.value;
        this.setState({ challengeResponse: response });
    }

    hideMessage() {
        this.setState({ showMessage: false });
    }

    render() {
        const webLogo = require("../../images/" + process.env.REACT_APP_ICON_LOGIN);

        const CHALL: { [key: string]: string } = this.state.challengeTypes.reduce((map: { [key: string]: string }, type: string) => {
            map[type] = type;
            return map;
        }, {});

        const challenges: JSX.Element[] = Object.keys(CHALL).map((type: string) => {
            return <option key={type} value={type}>{i18n.t(CHALL[type])}</option>;
        })

        const OTPT: { [key: string]: string } = this.state.otpDeliveryTypes.reduce((map: { [key: string]: string }, type: string) => {
            map[type] = type;
            return map;
        }, {});

        const otps: JSX.Element[] = Object.keys(OTPT).map((type: string) => {
            return <option key={type} value={type}>{i18n.t(OTPT[type])}</option>;
        })

        return (
            <div className="container">
                <div className="login-box">
                    <div>
                        <div className="login-form row">
                            <div className="login-header text-center">
                                <img src={webLogo} className="login-logo" />
                                <h4 className="login-title" >Stock Card</h4>
                            </div>
                            <div className="login-body">
                                <Message show={this.state.showMessage} hide={this.hideMessage} message={this.state.message} closable={false} />
                                <div className="control">
                                    <input type="text" className="form-control" placeholder={i18n.t('login-user')} onChange={this.handleUsernameChange} value={this.state.username} disabled={this.state.showPhase == 2} />
                                </div>
                                {this.state.showPhase == 1 &&
                                    <div>
                                        <label>{i18n.t('external-challenge-type')}:</label>
                                        <select className="form-control" onChange={this.handleSelectChallengeType} value={this.state.challengeType}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {challenges}
                                        </select>
                                        <div style={{ display: (this.state.showOtpType ? 'block' : 'none') }}>
                                            <label>{i18n.t('external-challenge-delivery')}:</label>
                                            <select className="form-control" onChange={this.handleSelectOtpType} value={this.state.otpDeliveryType} >
                                                <option value="NONE">{i18n.t('option-select')}</option>
                                                {otps}
                                            </select>
                                        </div>
                                    </div>
                                }
                                {this.state.showPhase == 2 &&
                                    <div>
                                        <div className="control">
                                            <input type="text" className="form-control" placeholder={i18n.t('sent')} value={this.state.challengeType + "/" + this.state.otpDeliveryType} disabled />
                                        </div>
                                        <div className="control">
                                            <input type="text" className="form-control" placeholder={i18n.t('external-challenge-response')} onChange={this.handleResponseChange} value={this.state.challengeResponse} />
                                        </div>
                                    </div>
                                }
                                <div className="login-button text-center">
                                    {this.state.showPhase != 0 &&
                                        <button onClick={this.reset} className="btn btn-default" >{i18n.t('back')}</button>}
                                    {this.state.showPhase == 0 &&
                                        <button onClick={this.getAvailableChallenges} className="btn btn-primary" disabled={this.state.username.length === 0}>{i18n.t('login-do')}</button>}
                                    {this.state.showPhase == 1 &&
                                        <button onClick={this.sendAuthenticateChallenge} className="btn btn-primary" disabled={this.state.challengeType === "" || (this.state.challengeType === "OTP" && this.state.otpDeliveryType === "")}>{i18n.t('send')}</button>}
                                    {this.state.showPhase == 2 &&
                                        <button onClick={this.completeAuthenticationChallenge} className="btn btn-primary" disabled={this.state.username.length === 0}>{i18n.t('login-do')}</button>}
                                </div>
                                <br />
                            </div>
                            <div className="login-footer">
                                <span className="text-left">
                                    <a href="?lang=en">
                                        <img onClick={() => i18n.changeLanguage('en')} height={20} width={20} src={flagUSA} />
                                    </a> |
                                        <a href="?lang=es">
                                        <img onClick={() => i18n.changeLanguage('es')} height={20} width={20} src={flagChile} />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export const LoginIntelliTrust = withAppContext(LoginIntelliTrustMain);