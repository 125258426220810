import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BASE_PERM } from "../../../const/Permission";
import { MovementAdd } from "./Add";
import { MovementList } from "./List";

export interface MovementDeviceProps extends RouteComponentProps, AppContextProps{ }
export interface MovementDeviceState {
    step: number;
 }

export class MovementDeviceMain extends React.Component<MovementDeviceProps, MovementDeviceState>{
    reachTheLastPage: boolean = false;
    constructor(props: MovementDeviceProps) {
        super(props);
        this.setStep = this.setStep.bind(this);
        this.state = {
            step: 0,
        };
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(BASE_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('make-movements')}</a></li>}
                        {this.props.havePermission(BASE_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('see-movements')}</a></li>}
                    </ul>
                </div>
                {this.state.step === 0 && this.props.havePermission(BASE_PERM) &&
                <MovementAdd></MovementAdd>
                }
                {this.state.step === 1 && this.props.havePermission(BASE_PERM) &&
                <MovementList></MovementList>
                }
            </div>
        )
    }
}

export const MovementDevice = withAppContext(MovementDeviceMain);
