import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import { OFFICE_MODIFY_PERM, OFFICE_SHOW_PERM, OFFICE_LIST_PERM, OFFICE_CREATE_PERM } from "../../const/Permission";
import { FORBIDDEN_ROUTE, OFFICE_SHOW_ROUTE, OFFICE_MODIFY_ROUTE, OFFICES_ROUTE, RouteOfficeParams, OFFICE_CREATE_ROUTE } from "../../const/Routes";
import { FormOffice } from "../../components/office/Form";
import { OfficeList } from "../../components/office/List";
import { AppContextProps, withAppContext } from "../../context/AppContext";

interface OfficeContainerProps extends RouteComponentProps<RouteOfficeParams>, AppContextProps { }
interface OfficeContainerState { }

export class OfficeContainerMain extends React.Component<OfficeContainerProps, OfficeContainerState>{
    constructor(props: OfficeContainerProps) {
        super(props);
    }

    render() {
        return (
            <Switch>
                {this.props.havePermission(OFFICE_CREATE_PERM) && <Route path={OFFICE_CREATE_ROUTE} exact component={FormOffice} />}
                {this.props.havePermission(OFFICE_MODIFY_PERM) && <Route path={OFFICE_MODIFY_ROUTE} exact component={FormOffice} />}
                {this.props.havePermission(OFFICE_SHOW_PERM) && <Route path={OFFICE_SHOW_ROUTE} exact component={FormOffice} />}
                {this.props.havePermission(OFFICE_LIST_PERM) && <Route path={OFFICES_ROUTE} exact component={OfficeList} />}
                <Redirect to={FORBIDDEN_ROUTE} />
            </Switch>
        )
    }
}

export const OfficeContainer = withAppContext(OfficeContainerMain);