export function validateRange(range: string, initial: number, final: number): boolean {
    if (range === null || range.length === 0) return true;
    if (!/^([0-9]|\-|\,)+$/.test(range)) return false;
    const parts: string[] = range.split(',');
    for (let i = 0; i < parts.length; i++) {
        const part: string = parts[i];
        if (part.includes('-')) {
            const bounds: string[] = part.split('-');
            const min: number = +bounds[0];
            const max: number = +bounds[1];
            if (bounds.length > 2 || isNaN(min) || isNaN(max) || min > max || min < initial || max > final) return false;
        } else {
            const asNumber: number = +part;
            if (isNaN(asNumber) || asNumber < initial || asNumber > final) return false;
        }
    }
    return true;
}

export function daysInMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

export function daysInPreviousMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 0).getDate();
}