import React from 'react';
import { withAppContext } from './AppContext';
import { withRouter } from 'react-router';
import { DEVICE_TYPES } from '../const/Device';
import { BranchOffice } from '../interfaces/Office';

export interface DeviceContextProps {
    deviceType: string;
    setDeviceType: Function;
    branchOffices: BranchOffice[];
}

const DeviceContext = React.createContext<DeviceContextProps>({
  deviceType: DEVICE_TYPES[process.env.REACT_APP_DEFAULT_DEVICE_TYPE!],
  branchOffices: [],
  setDeviceType: (deviceType: string) => {},
});

export const DeviceContextProvider = DeviceContext.Provider;
export const DeviceContextConsumer = DeviceContext.Consumer;

export function withAppDeviceContext(Component: any) {
  return withAppContext((props: any) => (
    <DeviceContextConsumer>
      {DeviceContext => {
        return <Component {...props} {...DeviceContext}/>
      }}
    </DeviceContextConsumer>
  ))
}

export function withDeviceContext(Component: any) {
  return withRouter((props: any) => (
    <DeviceContextConsumer>
      {DeviceContext => {
        return <Component {...props} {...DeviceContext}/>
      }}
    </DeviceContextConsumer>
  ))
}
