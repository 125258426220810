import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { getOfficesList, syncOfficeList } from "../../services/Office";
import { OFFICE_MODIFY_PERM, OFFICE_SHOW_PERM, OFFICE_CREATE_PERM } from "../../const/Permission";
import i18n from "../../i18n";
import { OFFICE_MODIFY_ROUTE, OFFICE_ID_PARAM, OFFICE_SHOW_ROUTE, OFFICES_ROUTE, OFFICE_CREATE_ROUTE } from "../../const/Routes";
import { ToggleLink } from "../../utils/ToggleLink";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { BranchOffice } from "../../interfaces/Office";
import { activeFormatter } from "../../utils/Formatter";
import { MESSAGE_SUCCESS } from "../../const/Message";

interface OfficeListProps extends RouteComponentProps, AppContextProps { }
interface OfficeListState {
    showModify: boolean;
    showHistory: boolean;
    showCreateNew: boolean;
    officeList: BranchOffice[];
    selected: BranchOffice | null;
    code: string | null;
    deleteDialog: boolean;
}

export class OfficeListMain extends React.Component<OfficeListProps, OfficeListState>{
    bootstrapTableRef: any;
    constructor(props: OfficeListProps) {
        super(props);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.syncAction = this.syncAction.bind(this);
        this.state = {
            showCreateNew: false,
            showHistory: false,
            showModify: false,
            officeList: [],
            selected: null,
            code: null,
            deleteDialog: false,
        }
    }

    componentDidMount() {
        this.props.showLoading(true);
        getOfficesList()
            .then((officeList: BranchOffice[]) => {
                this.setState({ officeList }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    toggleDelete() {
        this.setState(state => ({ ...state, deleteDialog: !state.deleteDialog }));
    }

    syncAction() {
        this.props.showLoading(true);

        syncOfficeList()
            .then(() => {
                this.props.showLoading(false);
                this.props.history.push(OFFICES_ROUTE,
                    this.props.setMessage({ message: i18n.t('branch-office-synced'), type: MESSAGE_SUCCESS }));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }


    onSelect(selected: BranchOffice) {
        this.setState({ selected, code: selected.code });
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('branch-offices')}</div>
                    </div>
                </div>
                <div className="card-body">
                    {this.props.havePermission(OFFICE_SHOW_PERM) && <ToggleLink to={OFFICE_SHOW_ROUTE.replace(OFFICE_ID_PARAM, this.state.code ? this.state.code : '')} className="btn btn-primary" disabled={this.state.selected === null}>{i18n.t('show')}</ToggleLink>}
                    {this.props.havePermission(OFFICE_MODIFY_PERM) && <ToggleLink to={OFFICE_MODIFY_ROUTE.replace(OFFICE_ID_PARAM, this.state.code ? this.state.code : '')} className="btn btn-primary" disabled={this.state.selected === null}>{i18n.t('modify')}</ToggleLink>}
                    {this.props.havePermission(OFFICE_CREATE_PERM) && <ToggleLink to={OFFICE_CREATE_ROUTE} className="btn btn-info">{i18n.t('create')}</ToggleLink>}

                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">{i18n.t('branch-office-select')}</div>
                        <BootstrapTable
                            data={this.state.officeList}
                            selectRow={{ mode: 'radio', onSelect: this.onSelect }}
                            bordered={false}
                            pagination>
                            <TableHeaderColumn dataField='code' isKey={true} dataSort={true} dataAlign="center" width="120">{i18n.t('code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' dataSort={true} >{i18n.t('name')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='updateDate' filter={{ type: 'DateFilter' }}>{i18n.t('date-creation')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='enabled' dataSort={true} dataFormat={activeFormatter} dataAlign="center">{i18n.t('enabled')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    }
}

export const OfficeList = withAppContext(OfficeListMain);