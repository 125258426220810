

import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { AppContextConsumer } from '../context/AppContext';

const ProtectedRoute = ({ component: Component, ...rest }: any) => (
  <AppContextConsumer>
    {appContext => <Route {...rest} render={(props) => (
      appContext.authenticated()
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />}
  </AppContextConsumer>
);

export default ProtectedRoute;