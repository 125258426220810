import i18n from "../i18n";

export const PERMISSION_TYPES: { [key: string]: string } = {
    "USERS": i18n.t('clients'),
    "USERS_SOFTTOKENS": i18n.t('soft-tokens'),
    "USERS_GRID": i18n.t('grid-cards'),
    "USERS_PASSWORD": i18n.t('password-manage'),
    "USERS_DOCUMENTS": i18n.t('documents-users'),
    "USERS_BIOMETRICS": i18n.t('biometrics'),
    "ADMIN_ROLE": i18n.t('roles'),
    "ADMIN_USERS": i18n.t('users-admin'),
    "SYSTEM_EVENTS": i18n.t('events-system'),
    "DOCUMENTS_ADMIN": i18n.t('documents'),
    "DEVICE_TYPE": i18n.t('device-type-adm'),
    "SYSTEM_INFO": i18n.t('system'),
    "OFFICES_ADMIN": i18n.t('branch-offices'),
    "NOTICES": i18n.t('notices'),
    "STOCK_RESUME": i18n.t('stock-summary'),
    "STOCK_LOAD": i18n.t('stock-load'),
    "STOCK_ASSIGN": i18n.t('shipment-assign'),
    "STOCK_SHIPMENT": i18n.t('shipments'),
    "STOCK_EVENTS": i18n.t('shipment-events'),
    "STOCK_DEVICE": i18n.t('devices-manage'),
    "BALANCE": i18n.t('balance'),
    "STOCK_MOVEMENT": i18n.t('movement'),
    "REPORT_CONTROL": i18n.t('control-reports'),
}