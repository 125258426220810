import { Role, RolePermissionGroup, RoleAPI } from "../interfaces/Role";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { API_PREFIX } from "../const/Routes";

export function getRole(roleId: string): Promise<Role> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/roles/' + roleId
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getRole');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function getRolesList(): Promise<Role[]> {
    return helperAxios(API_PREFIX + '/roles/')
        .then((response: AxiosResponse) => {
            console.log('[OK] getRolesList');
            return response.data.map(parseRole);
        })
        .catch(apiErrorCallback);
}

export function getPermissionGroups(): Promise<RolePermissionGroup[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/permisions/by-groups' })
        .then((response: AxiosResponse) => {
            console.log('[OK] getPermissionGroups');
            return response.data;
        })
        .catch(apiErrorCallback);
}


export function updateRole(codeRole: string, roleName: string, roleActive: boolean, permisions: string[], externalRoleName: string, needTwoFactorAuth: boolean): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/roles/' + codeRole,
        data: { codeRole, roleName, roleActive, permisions, externalRoleName, needTwoFactorAuth }
    })
        .then(() => {
            console.log('[OK] updateRole');
            return true;
        })
        .catch(apiErrorCallback);
}

export function createNewRole(codeRole: string, roleName: string, permisions: string[], externalRoleName: string, needTwoFactorAuth: boolean): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/roles/',
        data: { codeRole, roleName, permisions, externalRoleName, needTwoFactorAuth }
    })
        .then(() => {
            console.log('[OK] createNewRole');
            return true;
        })
        .catch(apiErrorCallback);
}

export function deleteRole(roleId: string): Promise<boolean> {
    return helperAxios({
        method: 'DELETE', url: API_PREFIX + '/roles/' + roleId
    })
        .then(() => {
            console.log('[OK] deleteRole');
            return true;
        })
        .catch(apiErrorCallback);
}

export function parseRole(roleAPI: RoleAPI): Role {
    const creationDate: Date = new Date(roleAPI.creationDate);
    const vigencyDate: Date = new Date(roleAPI.vigencyDate);
    return {
        codeRole: roleAPI.codeRole,
        roleName: roleAPI.roleName,
        permisions: roleAPI.permisions,
        creationDate,
        roleCodeStr: roleAPI.roleCodeStr,
        vigencyDate,
        roleActive: roleAPI.roleActive,
        externalRoleName: roleAPI.externalRoleName,
        needTwoFactorAuth: roleAPI.needTwoFactorAuth,
    }
}
