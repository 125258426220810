import i18n from "../i18n";
import { SideMenuItem, NavBarItem, MainMenuItem } from "../interfaces/Menu";
import {
	CLIENT_MAIN_ROUTE, CLIENT_SOFTTOKEN_ROUTE, SOFTTOKEN_ACTIVATION_ROUTE, CLIENT_PASSWORD_ROUTE, CLIENT_GRIDS_ROUTE, CLIENT_GRID_ASSIGN_ROUTE, CLIENT_EVENTS_ROUTE,
	FIND_CLIENT_ROUTE, USER_MAIN_ROUTE, USER_MODIFY_ROUTE, FIND_USER_ROUTE, USER_CREATE_ROUTE, ROLE_SHOW_ROUTE, ROLE_MODIFY_ROUTE, ROLES_ROUTE, OFFICE_SHOW_ROUTE,
	OFFICE_MODIFY_ROUTE, OFFICES_ROUTE, ROLE_CREATE_ROUTE, DEVICES_ROUTE, DEVICES_STOCK_ROUTE, DEVICES_SHIPPING_ROUTE, DEVICES_SHIPPING_ASSIGN_ROUTE, DEVICES_FIND_DEVICE_ROUTE,
	DEVICES_EVENTS_ROUTE, AUDIT_ROUTE, DOCUMENTS_ROUTE, SYSTEM_ROUTE, CLIENT_DOCUMENTS_ROUTE, CLIENT_DOCUMENT_SIGN_ROUTE, DEVICES_STOCK_LOAD_ROUTE, CLIENT_BIOMETRIC_ROUTE,
	DEVICES_BALANCE_MOVEMENT_ROUTE, DEVICES_BALANCE_STOCKTAKING_ROUTE, DEVICES_TYPE_DEVICE_ROUTE, CONTROL_REPORTS_ROUTE, STATE_OFFICE_REPORT_ROUTE, DEVICES_TYPE_DEVICE_CREATE_ROUTE, DEVICES_TYPE_ASSIGN_ROUTE, DEVICES_TYPE_EDIT_ROUTE, NOTICE_ROUTE
} from "./Routes";
import { CLIENT_SHOW_PERM, SOFTTOKEN_LIST_PERM, SOFTTOKEN_EVENTS_PERM, CLIENT_PASSWORD_PERM, CLIENT_PASSWORD_SERVICE_PERM, CLIENT_PASSWORD_EVENTS_PERM, GRID_LIST_PERM, GRID_EVENTS_PERM, CLIENT_EVENTS_PERM, USER_UPDATE_PERM, ROLE_LIST_PERM, USER_CREATE_PERM, ROLE_SHOW_PERM, ROLE_MODIFY_PERM, OFFICE_SHOW_PERM, OFFICE_MODIFY_PERM, OFFICE_LIST_PERM, ROLE_CREATE_PERM, BASE_PERM, EVENTS_GENERAL_PERM, EVENTS_SYSTEM_PERM, LICENSE_LIST_PERM, DOCUMENT_LIST_PERM, SYSTEM_PARAMETERS_LIST_PERM, USER_DOCUMENT_PERM, SHIPMENTS_SUMMARY_PERM, STOCK_DEVICES_LOAD_PERM, STOCK_DEVICES_SUMMARY_PERM, STOCK_DEVICES_SEARCH_PERM, SHIPMENTS_EVENTS_PERM, STOCK_DEVICES_LOADED_PERM, BIOMETRIC_FINGERPRINTS_PERM, BIOMETRIC_FACE_ENROLLMENT_PERM, DEVICES_MANAGE_PERM, SHIPMENT_ASSIGN_PERM, SHIPMENT_EVENTS_PERM, REPORTS_AND_CONTROL_MENU_PERM, STATE_OFFICE_REPORT_PERM, REPORTS_AND_CONTROL_PERM, USER_SECTION_ACCESS, USER_SHOW_CREATE_PERM, ROLE_SECTION_ACCESS, OFFICE_SECTION_ACCESS, DEVICE_TYPE_SECTION_ACCESS, DEVICE_TYPE_CREATE_PERM, DEVICE_TYPE_ASIGN_PERM, DEVICE_TYPE_UPDATE_PERM, EVENTS_SECTION_ACCESS, SYSTEM_PARAMETERS_SECTION_ACCESS, STOCK_DEVICE_STATE_SECTION_ACCESS, STOCK_DEVICE_BALANCE_SECTION_ACCESS, STOCK_DEVICE_MOVEMENT_SECTION_ACCESS, STOCK_DEVICE_EVENT_SECTION_ACCESS, STOCK_DEVICE_ASSIGN_SECTION_ACCESS, STOCK_DEVICE_SHIPMENT_SECTION_ACCESS, STOCK_DEVICE_LOAD_SECTION_ACCESS, STOCK_DEVICE_STOCK_SECTION_ACCESS, STOCK_DEVICE_SECTION_ACCESS, NOTICE_SECTION_ACCESS } from "./Permission";

export const CLIENT_MENU: SideMenuItem[] = [
	{ id: "ClientInformation", text: i18n.t('information'), icon: "fa-cogs", path: CLIENT_MAIN_ROUTE, select: [], permissions: [CLIENT_SHOW_PERM] },
	{ id: "SoftTokenList", text: i18n.t('soft-token-manager'), icon: "fa-mobile", path: CLIENT_SOFTTOKEN_ROUTE, select: [SOFTTOKEN_ACTIVATION_ROUTE], permissions: [SOFTTOKEN_LIST_PERM, SOFTTOKEN_EVENTS_PERM] },
	{ id: "ClientPassword", text: i18n.t('password'), icon: "fa-key", path: CLIENT_PASSWORD_ROUTE, select: [], permissions: [CLIENT_PASSWORD_PERM, CLIENT_PASSWORD_SERVICE_PERM, CLIENT_PASSWORD_EVENTS_PERM] },
	{ id: "ClientCards", text: i18n.t('grid-card'), icon: "fa-th", path: CLIENT_GRIDS_ROUTE, select: [CLIENT_GRID_ASSIGN_ROUTE], permissions: [GRID_LIST_PERM, GRID_EVENTS_PERM] },
	{ id: "ClientDocuments", text: i18n.t('documents'), icon: "fa-file-text", path: CLIENT_DOCUMENTS_ROUTE, select: [CLIENT_DOCUMENT_SIGN_ROUTE], permissions: [USER_DOCUMENT_PERM] },
	{ id: "ClientBiometrics", text: i18n.t('biometrics'), icon: "fa-hand-o-up", path: CLIENT_BIOMETRIC_ROUTE, select: [], permissions: [BIOMETRIC_FINGERPRINTS_PERM, BIOMETRIC_FACE_ENROLLMENT_PERM] },
	{ id: "ClientBinnacle", text: i18n.t('events'), icon: "fa-history", path: CLIENT_EVENTS_ROUTE, select: [], permissions: [CLIENT_EVENTS_PERM] },
	{ id: "FindClient", text: i18n.t('back'), icon: "fa-chevron-left", path: FIND_CLIENT_ROUTE, select: [], permissions: [CLIENT_SHOW_PERM] },
]

export const USER_MENU: SideMenuItem[] = [
	{ id: "AdminUserInformation", text: i18n.t('information'), icon: "fa-cogs", path: USER_MAIN_ROUTE, select: [USER_MODIFY_ROUTE], permissions: [USER_SHOW_CREATE_PERM] },
	{ id: "FindUser", text: i18n.t('back'), icon: "fa-chevron-left", path: FIND_USER_ROUTE, select: [], permissions: [USER_SECTION_ACCESS] },
]

export const USER_FORM_MENU: SideMenuItem[] = [
	{ id: "UserForm", text: i18n.t('modify'), icon: "fa-edit", path: USER_MODIFY_ROUTE, select: [], permissions: [USER_UPDATE_PERM] },
	{ id: "UserProfile", text: i18n.t('back'), icon: "fa-chevron-left", path: USER_MAIN_ROUTE, select: [], permissions: [ROLE_LIST_PERM] },
]

export const USER_FORM_CREATE_MENU: SideMenuItem[] = [
	{ id: "UserForm", text: i18n.t('create'), icon: "fa-edit", path: USER_CREATE_ROUTE, select: [], permissions: [USER_CREATE_PERM] },
	{ id: "RoleList", text: i18n.t('back'), icon: "fa-chevron-left", path: FIND_USER_ROUTE, select: [], permissions: [USER_SECTION_ACCESS] },
]

export const ROLE_FORM_MENU: SideMenuItem[] = [
	{ id: "RoleFormShow", text: i18n.t('show'), icon: "fa-eye", path: ROLE_SHOW_ROUTE, select: [], permissions: [ROLE_SHOW_PERM] },
	{ id: "RoleFormModify", text: i18n.t('modify'), icon: "fa-edit", path: ROLE_MODIFY_ROUTE, select: [], permissions: [ROLE_MODIFY_PERM] },
	{ id: "RoleList", text: i18n.t('back'), icon: "fa-chevron-left", path: ROLES_ROUTE, select: [], permissions: [ROLE_SECTION_ACCESS] },
]

export const ROLE_FORM_CREATE_MENU: SideMenuItem[] = [
	{ id: "RoleFormCreate", text: i18n.t('create'), icon: "fa-edit", path: ROLE_CREATE_ROUTE, select: [], permissions: [ROLE_CREATE_PERM] },
	{ id: "RoleList", text: i18n.t('back'), icon: "fa-chevron-left", path: ROLES_ROUTE, select: [], permissions: [ROLE_SECTION_ACCESS] },
]

export const OFFICE_FORM_MENU: SideMenuItem[] = [
	{ id: "OfficeFormShow", text: i18n.t('show'), icon: "fa-eye", path: OFFICE_SHOW_ROUTE, select: [], permissions: [OFFICE_SHOW_PERM] },
	{ id: "OfficeFormModify", text: i18n.t('modify'), icon: "fa-edit", path: OFFICE_MODIFY_ROUTE, select: [], permissions: [OFFICE_MODIFY_PERM] },
	{ id: "OfficeList", text: i18n.t('back'), icon: "fa-chevron-left", path: OFFICES_ROUTE, select: [], permissions: [OFFICE_SECTION_ACCESS] },
]

export const DEVICE_TYPE_CREATE_MENU: SideMenuItem[] = [
	{ id: "CreateDeviceType", text: i18n.t('create'), icon: "fa-edit", path: DEVICES_TYPE_DEVICE_CREATE_ROUTE, select: [DEVICES_TYPE_DEVICE_CREATE_ROUTE], permissions: [DEVICE_TYPE_CREATE_PERM] },
	{ id: "DevicesType", text: i18n.t('back'), icon: "fa-chevron-left", path: DEVICES_TYPE_DEVICE_ROUTE, select: [], permissions: [DEVICE_TYPE_SECTION_ACCESS] },
]

export const DEVICE_TYPE_UPDATE_MENU: SideMenuItem[] = [
	{ id: "ModifyDeviceType", text: i18n.t('modify'), icon: "fa-edit", path: DEVICES_TYPE_EDIT_ROUTE, select: [DEVICES_TYPE_EDIT_ROUTE], permissions: [DEVICE_TYPE_UPDATE_PERM] },
	{ id: "DevicesType", text: i18n.t('back'), icon: "fa-chevron-left", path: DEVICES_TYPE_DEVICE_ROUTE, select: [], permissions: [DEVICE_TYPE_SECTION_ACCESS] },
]

export const DEVICE_TYPE_ASSIGN_MENU: SideMenuItem[] = [
	{ id: "AssignDeviceType", text: i18n.t('assign'), icon: "fa-eye", path: DEVICES_TYPE_ASSIGN_ROUTE, select: [DEVICES_TYPE_ASSIGN_ROUTE], permissions: [DEVICE_TYPE_ASIGN_PERM] },
	{ id: "DevicesType", text: i18n.t('back'), icon: "fa-chevron-left", path: DEVICES_TYPE_DEVICE_ROUTE, select: [], permissions: [DEVICE_TYPE_SECTION_ACCESS] },
]

export const DEFAULT_MENU: SideMenuItem[] = [
	{ id: "FindUserMain", text: i18n.t('users'), icon: "fa-users", path: FIND_USER_ROUTE, select: [], permissions: [USER_SECTION_ACCESS] },
	{ id: "RolesMain", text: i18n.t('roles'), icon: "fa-shield", path: ROLES_ROUTE, select: [], permissions: [ROLE_SECTION_ACCESS] },
	{ id: "OfficeListMain", text: i18n.t('branch-offices'), icon: "fa-building", path: OFFICES_ROUTE, select: [], permissions: [OFFICE_SECTION_ACCESS] },
	{ id: "DevicesType", text: i18n.t('device-type'), icon: "fa-credit-card", path: DEVICES_TYPE_DEVICE_ROUTE, select: [], permissions: [DEVICE_TYPE_SECTION_ACCESS] },
	{ id: "SystemMain", text: i18n.t('system'), icon: "fa-cogs", path: SYSTEM_ROUTE, select: [], permissions: [SYSTEM_PARAMETERS_SECTION_ACCESS] },
	{ id: "AuditsMain", text: i18n.t('events'), icon: "fa-search-plus", path: AUDIT_ROUTE, select: [], permissions: [EVENTS_SECTION_ACCESS] },
	/*{ id: "ReportsControlMainMenu", text: i18n.t('control-reports'), icon: "fa-bar-chart", path: CONTROL_REPORTS_ROUTE, select: [], permissions: [REPORTS_AND_CONTROL_PERM] },*/
	{ id: "DevicesAdminMain", text: i18n.t('devices'), icon: "fa-credit-card", path: DEVICES_ROUTE, select: [], permissions: [STOCK_DEVICE_SECTION_ACCESS] },
	{ id: "NoticesMain", text: i18n.t('notices'), icon: "fa-envelope-o", path: NOTICE_ROUTE, select: [], permissions: [NOTICE_SECTION_ACCESS] },
]

export const NAVBAR_ITEMS: NavBarItem[] = [
	{ classNameSpan: "badge", classNameI: "fa fa-exclamation-circle icon", text: "new registration", number: 1 },
	{ classNameSpan: "badge success", classNameI: "fa fa-check icon", text: "new orders", number: 1 },
	{ classNameSpan: "badge danger", classNameI: "fa fa-comments icon", text: "customers messages", number: 2 }
]

export const MAIN_MENU_ITEMS: MainMenuItem[] = [
	{ id: "FindUserMainMenu", text: i18n.t('user-find'), icon: "fa-users", title: i18n.t('users'), color: "red", path: FIND_USER_ROUTE, permissions: [USER_SECTION_ACCESS] },
	{ id: "RolesMainMenu", text: i18n.t('roles-manage'), icon: "fa-shield", title: i18n.t('roles'), color: "green", path: ROLES_ROUTE, permissions: [ROLE_SECTION_ACCESS] },
	{ id: "OfficeListMain", text: i18n.t('branch-offices-manage'), icon: "fa-building", title: i18n.t('branch-offices'), color: "yellow", path: OFFICES_ROUTE, permissions: [OFFICE_SECTION_ACCESS] },
	{ id: "DevicesType", text: i18n.t('device-type-adm'), icon: "fa-credit-card", path: DEVICES_TYPE_DEVICE_ROUTE, title: i18n.t('device-type'), color: "blue", permissions: [DEVICE_TYPE_SECTION_ACCESS] },
	{ id: "SystemMainMenu", text: i18n.t('system-parameters-manage'), icon: "fa-cogs", title: i18n.t('system'), color: "green", path: SYSTEM_ROUTE, permissions: [SYSTEM_PARAMETERS_SECTION_ACCESS] },
	{ id: "AuditsMainMenu", text: i18n.t('events-platform'), icon: "fa-search-plus", title: i18n.t('events'), color: "yellow", path: AUDIT_ROUTE, permissions: [EVENTS_SECTION_ACCESS] },
	/*{ id: "ReportsControlMainMenu", text: i18n.t('control-reports-sub'), icon: "fa-bar-chart", title: i18n.t('control-reports'), color: "green", path: CONTROL_REPORTS_ROUTE, permissions: [REPORTS_AND_CONTROL_PERM] }*/
	{ id: "DevicesAdminMainMenu", text: i18n.t('devices-manage'), icon: "fa-credit-card", title: i18n.t('devices'), color: "blue", path: DEVICES_ROUTE, permissions: [STOCK_DEVICE_SECTION_ACCESS] },
	{ id: "NoticesMainMenu", text: i18n.t('notices-sub'), icon: "fa-envelope-o", title: i18n.t('notices'), color: "green", path: NOTICE_ROUTE, permissions: [NOTICE_SECTION_ACCESS] },
]

export const DEVICE_MANAGE_SIDE_MENU: SideMenuItem[] = [
	{ id: "DevicesManage", text: i18n.t('menu'), icon: "fa-bars", path: DEVICES_ROUTE, select: [], permissions: [STOCK_DEVICE_SECTION_ACCESS] },
	{ id: "DevicesStock", text: i18n.t('stock-summary'), icon: "fa-stack-overflow", path: DEVICES_STOCK_ROUTE, select: [], permissions: [STOCK_DEVICE_STOCK_SECTION_ACCESS] },
	{ id: "DevicesLoad", text: i18n.t('stock-load'), icon: "fa-plus", path: DEVICES_STOCK_LOAD_ROUTE, select: [], permissions: [STOCK_DEVICE_LOAD_SECTION_ACCESS] },
	{ id: "DevicesMakeShipments", text: i18n.t('shipment-assign'), icon: "fa-sort-amount-asc", path: DEVICES_SHIPPING_ASSIGN_ROUTE, select: [], permissions: [STOCK_DEVICE_ASSIGN_SECTION_ACCESS] },
	{ id: "DevicesShipping", text: i18n.t('shipments'), icon: "fa-truck", path: DEVICES_SHIPPING_ROUTE, select: [], permissions: [STOCK_DEVICE_SHIPMENT_SECTION_ACCESS] },
	/*{ id: "DevicesConsult", text: i18n.t('devices-consult'), icon: "fa-search", path: DEVICES_FIND_DEVICE_ROUTE, select: [], permissions:  [STOCK_DEVICES_SEARCH_PERM] },*/
	/*{ id: "DevicesType", text: i18n.t('device-type'), icon: "fa-credit-card", path: DEVICES_TYPE_DEVICE_ROUTE, select: [], permissions: [DEVICES_MANAGE_PERM] },*/
	{ id: "DevicesBinnacle", text: i18n.t('events'), icon: "fa-history", path: DEVICES_EVENTS_ROUTE, select: [], permissions: [STOCK_DEVICE_EVENT_SECTION_ACCESS] },
	{ id: "DevicesMovements", text: i18n.t('devices-movements'), icon: "fa-exchange", path: DEVICES_BALANCE_MOVEMENT_ROUTE, select: [], permissions: [STOCK_DEVICE_MOVEMENT_SECTION_ACCESS] },
	{ id: "DevicesBalance", text: i18n.t('devices-balance'), icon: "fa-balance-scale", path: DEVICES_BALANCE_STOCKTAKING_ROUTE, select: [], permissions: [STOCK_DEVICE_BALANCE_SECTION_ACCESS] },
	{ id: "StateOfficeReport", text: i18n.t('report-status-office'), icon: "fa-map", path: STATE_OFFICE_REPORT_ROUTE, select: [], permissions: [STOCK_DEVICE_STATE_SECTION_ACCESS] },
	{ id: "MainMenu", text: i18n.t('main-menu'), icon: "fa-chevron-left", path: "", select: [], permissions: [BASE_PERM] }
]

export const REPORTS_AND_CONTROL_MENU: SideMenuItem[] = [
	{ id: "StateOfficeReport", text: i18n.t('report-status-office'), icon: "fa-map", path: STATE_OFFICE_REPORT_ROUTE, select: [], permissions: [STOCK_DEVICE_STATE_SECTION_ACCESS] },
	{ id: "MainMenu", text: i18n.t('main-menu'), icon: "fa-chevron-left", path: "", select: [], permissions: [BASE_PERM] }
]

export const DEVICE_MANAGE_MENU_ITEMS: MainMenuItem[] = [
	{ id: "DevicesStockMain", text: i18n.t('stock-devices'), icon: "fa-stack-overflow", path: DEVICES_STOCK_ROUTE, title: i18n.t('stock'), color: "blue", permissions: [STOCK_DEVICE_STOCK_SECTION_ACCESS] },
	{ id: "DevicesLoadMain", text: i18n.t('stock-load-devices'), icon: "fa-plus", path: DEVICES_STOCK_LOAD_ROUTE, title: i18n.t('stock-load'), color: "red", permissions: [STOCK_DEVICE_LOAD_SECTION_ACCESS] },
	{ id: "DevicesMakeShipmentsMain", text: i18n.t('shipments-assign'), icon: "fa-sort-amount-asc", path: DEVICES_SHIPPING_ASSIGN_ROUTE, title: i18n.t('shipment-assign'), color: "yellow", permissions: [STOCK_DEVICE_ASSIGN_SECTION_ACCESS] },
	{ id: "DevicesShippingMain", text: i18n.t('shipments-generated'), icon: "fa-truck", path: DEVICES_SHIPPING_ROUTE, title: i18n.t('shipments'), color: "green", permissions: [STOCK_DEVICE_SHIPMENT_SECTION_ACCESS] },
	/*{ id: "DevicesConsultMain", text: i18n.t('devices-consult'), icon: "fa-search", path: DEVICES_FIND_DEVICE_ROUTE, title: i18n.t('search'), color: "blue",  permissions:  [STOCK_DEVICES_SEARCH_PERM] },*/
	/*{ id: "DevicesType", text: i18n.t('device-type-adm'), icon: "fa-credit-card", path: DEVICES_TYPE_DEVICE_ROUTE, title: i18n.t('device-type'), color: "blue", permissions: [DEVICE_TYPE_SECTION_ACCESS] },*/
	{ id: "DevicesBinnacleMain", text: i18n.t('shipment-events'), icon: "fa-history", path: DEVICES_EVENTS_ROUTE, title: i18n.t('events'), color: "red", permissions: [SHIPMENTS_EVENTS_PERM] },
	{ id: "DevicesMovements", text: i18n.t('devices-movements-desc'), icon: "fa-exchange", path: DEVICES_BALANCE_MOVEMENT_ROUTE, title: i18n.t('devices-movements'), color: "green", permissions: [STOCK_DEVICE_MOVEMENT_SECTION_ACCESS] },
	{ id: "DevicesBalance", text: i18n.t('devices-balance-desc'), icon: "fa-balance-scale", path: DEVICES_BALANCE_STOCKTAKING_ROUTE, title: i18n.t('devices-balance'), color: "yellow", permissions: [STOCK_DEVICE_BALANCE_SECTION_ACCESS] },
	{ id: "StateOfficeReport", text: i18n.t('report-status-office-sub'), icon: "fa-map", path: STATE_OFFICE_REPORT_ROUTE, title: i18n.t('report-status-office'), color: "yellow", permissions: [STOCK_DEVICE_STATE_SECTION_ACCESS] },
	{ id: "MainMenu", text: i18n.t('main-menu'), icon: "fa-chevron-left", path: "", title: i18n.t('back'), color: "dark", permissions: [BASE_PERM] }
]

export const REPORTS_AND_CONTROL_MENU_ITEMS: MainMenuItem[] = [
	{ id: "StateOfficeReport", text: i18n.t('report-status-office-sub'), icon: "fa-map", path: STATE_OFFICE_REPORT_ROUTE, title: i18n.t('report-status-office'), color: "yellow", permissions: [STOCK_DEVICE_STATE_SECTION_ACCESS] },
	{ id: "MainMenu", text: i18n.t('main-menu'), icon: "fa-chevron-left", path: "", title: i18n.t('back'), color: "dark", permissions: [BASE_PERM] }
]
