import { SearchBalance, Balance, BalanceAPI, BalanceToSend, BalanceAPICW, CreateBalanceToSend } from "../interfaces/Balance";
import { helperAxios, apiErrorCallback, helperAxiosCW, CARD_STOCK_ID, GROUP_ID, WIZARD_USER, WIZARD_PASS } from "../utils/ApiBaseConfig";
import { API_PREFIX, CW_API_PREFIX } from "../const/Routes";
import { AxiosResponse } from "axios";
import i18n from "../i18n";

export const BALANCES_AMOUNT_PER_REQUEST = 300;

export function getLastBalance(searchBalance: SearchBalance): Promise<Balance> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/last',
        data: {
            ...searchBalance
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getLastBalance');
            return parseBalance(response.data);
        })
        .catch(apiErrorCallback);
}

export function getExpectedBalance(searchBalance: SearchBalance): Promise<Balance> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/expected',
        data: {
            ...searchBalance
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getExpectedBalance');
            return parseBalance(response.data);
        })
        .catch(apiErrorCallback);
}

export function getExpectedBalanceCW(searchBalance: SearchBalance): Promise<Balance> {

    console.log('[INIT] getExpectedBalanceCW');
    //TODO: cambiar datos en duro, conexión dummy con card wizard
    return helperAxiosCW({
        method: 'POST', url: CW_API_PREFIX + '/OAuthTokens/', data: { 'userName': WIZARD_USER, 'password': WIZARD_PASS, 'iscwUser': true }
    }).then((response: AxiosResponse) => {
        console.log('[OK] LOG IN !');
        return helperAxiosCW({
            method: 'GET', url: CW_API_PREFIX + '/CardStocks/' + CARD_STOCK_ID + '?gid=' + GROUP_ID
        })
            .then((response: AxiosResponse) => {
                console.log('[OK] getExpectedBalanceCW');
                return parseBalanceCW(response.data);
            })
            .catch(apiErrorCallback);
    })
        .catch(apiErrorCallback);
}

export function parseBalance(balanceAPI: BalanceAPI): Balance {
    return {
        correlative: balanceAPI.correlative,
        date: balanceAPI.date ? new Date(balanceAPI.date) : i18n.t('no-data'),
        userCorrelative: balanceAPI.userCorrelative,
        userName: balanceAPI.userName,
        userSupervisorCorrelative: balanceAPI.userSupervisorCorrelative,
        userSupervisorName: (balanceAPI.userSupervisorName) ? balanceAPI.userSupervisorName : i18n.t('no-data'),
        regionCode: balanceAPI.regionCode,
        regionName: balanceAPI.regionName,
        branchOfficeCode: balanceAPI.branchOfficeCode,
        branchOfficeName: balanceAPI.branchOfficeName,
        deviceTypeCode: balanceAPI.deviceTypeCode,
        deviceTypeName: balanceAPI.deviceTypeName,
        expectedQuantityInVault: balanceAPI.expectedQuantityInVault,
        expectedQuantityInMachine: balanceAPI.expectedQuantityInMachine,
        expectedQuantityInOther: balanceAPI.expectedQuantityInOther,
        expectedTotalQuantityOutVault: balanceAPI.expectedTotalQuantityOutVault,
        expectedQuantityRejectedMachine: balanceAPI.expectedQuantityRejectedMachine,
        expectedQuantityRejectedQA: balanceAPI.expectedQuantityRejectedQA,
        realQuantityInVault: balanceAPI.realQuantityInVault,
        realQuantityInMachine: balanceAPI.realQuantityInMachine,
        realQuantityInOther: balanceAPI.realQuantityInOther,
        realTotalQuantityOutVault: balanceAPI.realTotalQuantityOutVault,
        realQuantityRejectedMachine: balanceAPI.realQuantityRejectedMachine,
        realQuantityRejectedQA: balanceAPI.realQuantityRejectedQA
    }
}

export function parseBalanceCW(balanceAPI: BalanceAPICW): Balance {

    //TODO: cambiar datos en duro, conexión dummy con card wizard
    return {
        correlative: balanceAPI.id,
        date: balanceAPI.dateTime ? new Date(balanceAPI.dateTime) : i18n.t('no-data'),
        userCorrelative: 0,
        userName: balanceAPI.userName ? balanceAPI.userName : i18n.t('no-data'),
        userSupervisorCorrelative: 0,
        userSupervisorName: (balanceAPI.overrideUserName) ? balanceAPI.overrideUserName : i18n.t('no-data'),
        regionCode: i18n.t('no-data'),
        regionName: i18n.t('no-data'),
        branchOfficeCode: balanceAPI.pcGroupID,
        branchOfficeName: balanceAPI.pcGroupName ? balanceAPI.pcGroupName : i18n.t('no-data'),
        deviceTypeCode: "" + CARD_STOCK_ID, // balanceAPI.id,
        deviceTypeName: balanceAPI.cardStockName,
        expectedQuantityInVault: balanceAPI.inVault + balanceAPI.inVaultChange,
        expectedQuantityInMachine: 0,
        expectedQuantityInOther: 0,
        expectedTotalQuantityOutVault: balanceAPI.outsideVault + balanceAPI.outsideVaultChange,
        expectedQuantityRejectedMachine: balanceAPI.inMachineRejects + balanceAPI.inMachineRejectsChange,
        expectedQuantityRejectedQA: balanceAPI.countQARejects + balanceAPI.qaRejectsChange,
        realQuantityInVault: 0,
        realQuantityInMachine: 0,
        realQuantityInOther: 0,
        realTotalQuantityOutVault: 0,
        realQuantityRejectedMachine: 0,
        realQuantityRejectedQA: 0
    }
}

export function sendStocktaking(model: CreateBalanceToSend): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/create',
        data: model
    })
        .then(() => {
            console.log('[OK] sendStocktaking');
            return true;
        })
        .catch(apiErrorCallback);
}

export function sendStocktakingCW(balance: BalanceToSend): Promise<boolean> {

    console.log('[INIT] sendStocktakingCW');
    //TODO: cambiar datos en duro, conexión dummy con card wizard
    return helperAxiosCW({
        method: 'POST', url: CW_API_PREFIX + '/OAuthTokens/', data: { 'userName': WIZARD_USER, 'password': WIZARD_PASS, 'iscwUser': true }
    }).then((response: AxiosResponse) => {
        console.log('[OK] LOG IN !');
        return helperAxiosCW({
            method: 'PUT', url: CW_API_PREFIX + '/CardStocks/Balance/',
            data: {
                "id": CARD_STOCK_ID,
                "cardStockName": "tarjeta blanca",
                "pcGroupID": GROUP_ID,
                "pcGroupName": null,
                "inventoryActivity": 0,
                "cardFormatSet": null,
                "warningEnabled": false,
                "lowlevel": 0,
                "emptyLevel": 0,
                "dateLastBalanced": "0001-01-01T00:00:00",
                "inVault": Number(balance.realQuantityInVault),
                "outsideVault": Number(balance.realTotalQuantityOutVault),
                "inVaultChange": 0,
                "outsideVaultChange": 0,
                "inMachineRejectsChange": 0,
                "qaRejectsChange": 0,
                "inMachineRejects": Number(balance.realQuantityRejectedMachine),
                "countQARejects": Number(balance.realQuantityRejectedQA),
                "sequenceNumber": 1,
                "dateTime": "0001-01-01T00:00:00",
                "userName": null,
                "status": 0,
                "deletingDateTime": "0001-01-01T00:00:00",
                "deletingUserName": null,
                "balanceOverrideComment": "[" + balance.userSupervisor.userId + "] : " + balance.description,
                "overrideUserName": balance.userSupervisor.userId,
                "outsideVaultInMachine": Number(balance.realQuantityInMachine),
                "outsideVaultOther": Number(balance.realQuantityInOther),
                "numberOfCards": 0,
                "comment": balance.description
            }
        })
            .then((response: AxiosResponse) => {
                console.log('[OK] sendStocktakingCW');
                return true;
            })
            .catch(apiErrorCallback);
    })
        .catch(apiErrorCallback);
}

export function findBalances(searchBalance: SearchBalance): Promise<Balance[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/find',
        data: {
            ...searchBalance,
            maxResults: searchBalance.maxResults || BALANCES_AMOUNT_PER_REQUEST
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findBalances');
            return response.data.map(parseBalance);
        })
        .catch(apiErrorCallback);
}