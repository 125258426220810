import React from 'react';
import { withAppContext } from './AppContext';
import { withRouter } from 'react-router';
import { DocumentTemplate, DocumentTemplateVersion } from '../interfaces/Document';

export interface DocumentContextProps {
    document: DocumentTemplate | null;
    version: DocumentTemplateVersion | null;
    setDocument: Function;
}

const DocumentContext = React.createContext<DocumentContextProps>({
  document: null,
  version: null,
  setDocument: (document: DocumentTemplate, version: DocumentTemplateVersion) => {},
});

export const DocumentContextProvider = DocumentContext.Provider;
export const DocumentContextConsumer = DocumentContext.Consumer;

export function withAppDocumentContext(Component: any) {
  return withAppContext((props: any) => (
    <DocumentContextConsumer>
      {documentContext => {
        return <Component {...props} {...documentContext}/>
      }}
    </DocumentContextConsumer>
  ))
}

export function withDocumentContext(Component: any) {
  return withRouter((props: any) => (
    <DocumentContextConsumer>
      {documentContext => {
        return <Component {...props} {...documentContext}/>
      }}
    </DocumentContextConsumer>
  ))
}
