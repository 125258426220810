import * as React from "react";
import { RouteComponentProps, Switch, Route, Redirect } from "react-router";
import { USER_CREATE_PERM, USER_UPDATE_PERM, BASE_PERM, USER_SHOW_CREATE_PERM, USER_SECTION_ACCESS } from "../../const/Permission";
import { RouteUserParams, FIND_PERSON_ROUTE, PERSON_MAIN_ROUTE, PERSON_MODIFY_ROUTE, PERSON_CREATE_ROUTE, FORBIDDEN_ROUTE, TYPE_USER, LOGIN_ROUTE, PERSON_SELF_PROFILE_ROUTE } from "../../const/Routes";
import { UserContextProvider, UserContextProps } from "../../context/UserContext";
import { IdentityUser, IdentityUserWithOffice } from "../../interfaces/Identity";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { FormUser } from "../../components/user/Form";
import { UserProfile } from "../../components/user/Profile";
import { FindUser } from "../../components/user/Find";
import { getUser } from "../../services/User";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { UserSelfProfile } from "../../components/user/profile/SelfProfile";

interface UserContainerProps extends RouteComponentProps<RouteUserParams>, AppContextProps { }
interface UserContainerState {
    userContext: UserContextProps;
}

class UserContainerMain extends React.Component<UserContainerProps, UserContainerState>{
    constructor(props: UserContainerProps) {
        super(props);
        this.setUser = this.setUser.bind(this);
        this.refreshUser = this.refreshUser.bind(this);

        this.state = {
            userContext: {
                user: null,
                setUser: this.setUser,
                refreshUser: this.refreshUser,
            }
        }
    }

    setUser(user: IdentityUserWithOffice, callback?: Function) {
        this.setState(state => ({ ...state, userContext: { ...state.userContext, user } }), () => { if (callback) callback() });
    }

    refreshUser() {
        this.props.showLoading(true);
        getUser(this.state.userContext.user!.userId, this.state.userContext.user!.group, TYPE_USER)
            .then((user: IdentityUserWithOffice) => {
                this.setState(state => ({ ...state, userContext: { ...state.userContext, user } }));
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {
        return (
            <UserContextProvider value={this.state.userContext}>
                <Switch>
                    {this.props.havePermission(USER_UPDATE_PERM) && <Route path={PERSON_MODIFY_ROUTE} exact component={FormUser} />}
                    {this.props.havePermission(USER_CREATE_PERM) && <Route path={PERSON_CREATE_ROUTE} exact component={FormUser} />}
                    {this.props.havePermission(USER_SHOW_CREATE_PERM) && <Route path={PERSON_MAIN_ROUTE} exact component={UserProfile} />}
                    {this.props.havePermission(USER_SECTION_ACCESS) && <Route path={FIND_PERSON_ROUTE} exact component={FindUser} />}
                    <Redirect to={FORBIDDEN_ROUTE} />
                </Switch>
            </UserContextProvider>
        )
    }
}

export const UserContainer = withAppContext(UserContainerMain);