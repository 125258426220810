import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { RouteOfficeParams, OFFICES_ROUTE, OFFICE_SHOW_ROUTE, OFFICE_CREATE_ROUTE, OFFICE_MODIFY_ROUTE } from "../../const/Routes";
import { getBranchOffice, updateOffice, createOffice } from "../../services/Office";
import { BranchOfficeAPI, BranchOffice } from "../../interfaces/Office";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../const/Message";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "../../utils/Formatter";
import { Region } from "../../interfaces/Movement";
import { getRegionList } from "../../services/Stock";
import { DEVICE_TYPE_CONF_PERM } from "../../const/Permission";


interface FormOfficeProps extends RouteComponentProps<RouteOfficeParams>, AppContextProps { }
interface FormmOfficeState {
    branchOffice: BranchOffice;
    tableOptions: any;
    regions: Region[];
}

export class FormOfficeMain extends React.Component<FormOfficeProps, FormmOfficeState>{
    constructor(props: FormOfficeProps) {
        super(props);
        this.back = this.back.bind(this);
        this.update = this.update.bind(this);
        this.create = this.create.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.handleInputCode = this.handleInputCode.bind(this);
        this.handleInputName = this.handleInputName.bind(this);
        this.handleInputAddress = this.handleInputAddress.bind(this);
        this.handleInputManagerUser = this.handleInputManagerUser.bind(this);
        this.handleInputNotificationMail = this.handleInputNotificationMail.bind(this);
        this.printDeviceType = this.printDeviceType.bind(this);
        this.state = {
            regions: [],
            branchOffice: {
                code: '',
                name: '',
                enabled: true,
                address: '',
                managerUserName: '',
                notificationMail: '',
                updateDate: new Date(),
                stockConfig: [],
                region: { codeRegion: '', regionName: '', regionActive: true }
            },
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            },
        }
    }

    componentDidMount() {
        if (this.props.match.path !== OFFICE_CREATE_ROUTE) this.fetchOffice();
        this.getRegionsFromAPI();
    }

    fetchOffice() {
        this.props.showLoading(true);
        getBranchOffice(this.props.match.params.officeId)
            .then((branchOffice: BranchOffice) => {
                this.setState({ branchOffice });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
                this.props.history.goBack();
            });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    handleRegion(e: any) {
        e.preventDefault();
        this.setState({
            branchOffice: {
                ...this.state.branchOffice,
                region: {
                    codeRegion: e.target.value,
                    regionName: e.target.options[e.target.selectedIndex].text,
                    regionActive: true
                }
            }
        });
    }

    back() {
        this.props.history.push(OFFICES_ROUTE);
    }

    handleInputCode(e: any): void {
        e.preventDefault();
        const code = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, code } }));
    }

    handleInputName(e: any): void {
        e.preventDefault();
        const name = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, name } }));
    }

    handleInputAddress(e: any): void {
        e.preventDefault();
        const address = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, address } }));
    }

    handleInputManagerUser(e: any): void {
        e.preventDefault();
        const managerUserName = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, managerUserName } }));
    }

    handleInputNotificationMail(e: any): void {
        e.preventDefault();
        const notificationMail = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, notificationMail } }));
    }

    update() {
        const office: BranchOfficeAPI = {
            ...this.state.branchOffice,
            stockConfig: this.state.branchOffice.stockConfig,
            updateDate: 0,
            region: this.state.branchOffice.region
        };

        this.props.showLoading(true);
        updateOffice(office)
            .then(() => {
                this.props.history.push(OFFICES_ROUTE);
                this.props.setMessage({ message: i18n.t('branch-office-updated'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    create() {
        const office: BranchOfficeAPI = { ...this.state.branchOffice, stockConfig: undefined, updateDate: 0, region: this.state.branchOffice.region };
        if (office.code.length === 0 || office.name.length === 0) {
            this.props.setMessage({ message: i18n.t('branch-office-form'), type: MESSAGE_WARNING });
            return;
        }
        if (this.state.branchOffice.region.codeRegion === "" || this.state.branchOffice.region.codeRegion === "NONE") {
            this.props.setMessage({ message: i18n.t('region-must-be-selected'), type: MESSAGE_WARNING });
            return;
        }

        this.props.showLoading(true);
        createOffice(office)
            .then(() => {
                this.props.history.push(OFFICES_ROUTE);
                this.props.setMessage({ message: i18n.t('branch-office-created'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    printDeviceType(row: any) {
        return row.name;
    }

    render() {
        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('branch-office')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('code')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" maxLength={4} disabled={this.props.match.path !== OFFICE_CREATE_ROUTE} name="code" className="form-control" id="code" onChange={this.handleInputCode} value={this.state.branchOffice.code} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('name')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={this.props.match.path !== OFFICE_CREATE_ROUTE} name="name" className="form-control" id="name" onChange={this.handleInputName} value={this.state.branchOffice.name} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('address')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={this.props.match.path !== OFFICE_CREATE_ROUTE} name="address" className="form-control" id="address" onChange={this.handleInputAddress} value={this.state.branchOffice.address} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('region')}:</label>
                                <div className="col-sm-4">
                                    <select disabled={this.props.match.path !== OFFICE_CREATE_ROUTE} className="form-control" onChange={this.handleRegion} value={this.state.branchOffice.region.codeRegion}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <label className="col-sm-2 control-label">{i18n.t('status')}: </label>
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        {this.state.branchOffice.enabled &&
                                            <span className="label label-success">{i18n.t('enabled')}</span>}
                                        {!this.state.branchOffice.enabled &&
                                            <span className="label label-danger">{i18n.t('disabled')}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <label className="col-sm-2 control-label">{i18n.t('manager')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={this.props.match.path === OFFICE_SHOW_ROUTE} onChange={this.handleInputManagerUser} name="managerUserName" className="form-control" id="managerUserName" value={this.state.branchOffice.managerUserName} />
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <label className="col-sm-2 control-label">{i18n.t('email')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={this.props.match.path === OFFICE_SHOW_ROUTE} onChange={this.handleInputNotificationMail} name="notificationMail" className="form-control" id="notificationMail" value={this.state.branchOffice.notificationMail} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.branchOffice.stockConfig && this.props.havePermission(DEVICE_TYPE_CONF_PERM) && <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('stock-config')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <BootstrapTable data={this.state.branchOffice.stockConfig}
                            bordered={true}
                            cellEdit={{
                                mode: 'click',
                                blurToSave: true,
                            }}
                            options={this.state.tableOptions}>
                            <TableHeaderColumn dataField='deviceType' dataFormat={this.printDeviceType} dataSort={true} isKey={true}>{i18n.t('device')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='minStock' dataAlign={"center"} dataSort={true}>{i18n.t('stock-min')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='available' dataAlign={"center"} dataSort={true} dataFormat={activeFormatter} editable={false}>{i18n.t('active')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>}
                <div className="card margin-card-top">
                    <div className="card-body">
                        <div className="col-sm-offset-0 col-sm-10">
                            <button type="button" onClick={this.back} className="btn btn-info">{i18n.t('back')}</button>
                            {this.props.match.path === OFFICE_CREATE_ROUTE &&
                                <button type="button" onClick={this.create} className="btn btn-info" >
                                    {i18n.t('create')}
                                </button>}
                            {this.props.match.path === OFFICE_MODIFY_ROUTE &&
                                <button type="button" onClick={this.update} className="btn btn-info" >
                                    {i18n.t('update')}
                                </button>}
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>
        )
    }
}

export const FormOffice = withAppContext(FormOfficeMain);