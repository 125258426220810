import { DeviceState } from "../interfaces/Device";
import { CURUS_DEVICE_GRID, CURUS_DEVICE_TOKEN, CURUS_DEVICE_ANY, CURUS_DEVICE_PASSWORD, CURUS_DEVICE_TOKENRO, CURUS_DEVICE_SYSTEM } from "./Identity";

export const DEVICE_GRID_CARD = 'grid-card';
export const DEVICE_SOFT_TOKEN = 'soft-token';
export const DEVICE_PASSWORD = 'password';
export const DEVICE_ANY = 'any';
export const DEVICE_SYSTEM = 'system';

export const DEVICE_TYPES: { [key: string]: string } = {
    [CURUS_DEVICE_GRID]: DEVICE_GRID_CARD,
    [CURUS_DEVICE_TOKEN]: DEVICE_SOFT_TOKEN,
    [CURUS_DEVICE_TOKENRO]: DEVICE_SOFT_TOKEN,
    [CURUS_DEVICE_PASSWORD]: DEVICE_PASSWORD,
    [CURUS_DEVICE_ANY]: DEVICE_ANY,
    [CURUS_DEVICE_SYSTEM]: DEVICE_SYSTEM,
};

export const CURUS_DEVICE_TYPES: { [key: string]: string } = {
    [DEVICE_GRID_CARD]: CURUS_DEVICE_GRID,
    [DEVICE_SOFT_TOKEN]: CURUS_DEVICE_TOKEN,
    [DEVICE_PASSWORD]: CURUS_DEVICE_PASSWORD,
    [DEVICE_ANY]: CURUS_DEVICE_ANY,
};

export const DEVICE_STATE_ACTIVE = 'active';
export const DEVICE_STATE_CREATED = 'created';
export const DEVICE_STATE_DELETED = 'deleted';
export const DEVICE_STATE_IN_ACTIVATION = 'in-activation';
export const DEVICE_STATE_LOCKED = 'locked';
export const DEVICE_STATE_PENDING = 'pending';
export const DEVICE_STATE_UNASSIGNED = 'canceled';
export const DEVICE_STATE_UNKNOWN = 'unknown';
export const DEVICE_STATE_UNAVAILABLE = 'unavailable';

export const DEVICE_STATES: { [key: string]: DeviceState } = {
    'ACTIVE': DEVICE_STATE_ACTIVE,
    'CREATED': DEVICE_STATE_CREATED,
    'DELETED': DEVICE_STATE_DELETED,
    'IN_ACTIVATION': DEVICE_STATE_IN_ACTIVATION,
    'LOCKED': DEVICE_STATE_LOCKED,
    'PENDING': DEVICE_STATE_PENDING,
    'UNASSIGNED': DEVICE_STATE_UNASSIGNED,
    'UNKNOWN': DEVICE_STATE_UNKNOWN,
    'UNAVAILABLE': DEVICE_STATE_UNAVAILABLE,
};
