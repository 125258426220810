

import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { IdentityApp } from "./containers/IdentityApp";
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './i18n';

ReactDOM.render(
    <BrowserRouter  >
        <IdentityApp />
    </BrowserRouter>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
