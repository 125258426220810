import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { getUser, findAll } from "../../services/User";
import { serviceUserId, formatUserId, checkRut, formatUserIdForList, formatBranchOfficeForList, formatRoleForList, formatStatusForList } from "../../utils/Formatter";
import { USER_GROUP_PARAM, USER_ID_PARAM, RouteUserParams, PERSON_CREATE_ROUTE, USER_TYPE_PARAM, PERSON_MAIN_ROUTE, TYPE_CLIENT, TYPE_USER } from "../../const/Routes";
import { GroupAdmin, GroupClient } from "../../utils/Config";
import { UserContextProps, withAppUserContext } from "../../context/UserContext";
import { IdentityUser, IdentityUserWithOffice } from "../../interfaces/Identity";
import i18n from "../../i18n";
import { MESSAGE_ERROR } from "../../const/Message";
import { USER_CREATE_PERM, CLIENT_CREATE_PERM, USER_SHOW_CREATE_PERM, USER_LIST_PERM, USER_SHOW_PERM } from "../../const/Permission";
import { CLIENT_NOT_EXIST_CODE } from "../../const/Identity";
import { IDENTITY_ADMIN_GROUP } from "../../const/Group";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

interface FindUserProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps { }
interface FindUserState {
    userId: string;
    create: boolean;
    users: IdentityUserWithOffice[];
    selected: IdentityUserWithOffice | null;
}

export class FindUserMain extends React.Component<FindUserProps, FindUserState>{
    constructor(props: FindUserProps) {
        super(props);
        this.findUser = this.findUser.bind(this);
        this.createUser = this.createUser.bind(this);
        this.onChangeId = this.onChangeId.bind(this);
        this.findAllUser = this.findAllUser.bind(this);
        this.onSelect = this.onSelect.bind(this);

        this.state = {
            userId: "",
            create: false,
            users: [],
            selected: null
        }
    }

    componentWillMount() {
        this.props.setUser(null);
        if (this.props.havePermission(USER_LIST_PERM)) {
            this.findAllUser();
        }
    }

    findUser(e: any) {
        e.preventDefault();
        let userID: string = "";
        if (this.state.selected != null) userID = formatUserId(this.state.selected.userId);
        if (userID === "") {
            this.props.setMessage({ message: i18n.t('id-enter-' + this.props.match.params.userType), type: MESSAGE_ERROR });
        } else {
            this.props.showLoading(true);
            const serviceId = serviceUserId(userID, this.props.match.params.userType === TYPE_CLIENT ? GroupClient : GroupAdmin);
            getUser(serviceId, this.props.match.params.userType === TYPE_CLIENT ? GroupClient : GroupAdmin, this.props.match.params.userType)
                .then((response: IdentityUser) => {
                    this.props.setUser(response, () => {
                        this.props.showLoading(false);
                        this.props.history.push(PERSON_MAIN_ROUTE
                            .replace(USER_TYPE_PARAM, this.props.match.params.userType)
                            .replace(USER_GROUP_PARAM, response.group)
                            .replace(USER_ID_PARAM, serviceId));
                    });
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    if (response.code === CLIENT_NOT_EXIST_CODE) this.setState({ create: true });
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    createUser() {
        if (this.state.userId === "") {
            this.props.setMessage({ message: i18n.t('id-enter-' + this.props.match.params.userType), type: MESSAGE_ERROR });
        } else if (checkRut(this.state.userId)) {
            const serviceId = serviceUserId(this.state.userId, this.props.match.params.userType === TYPE_CLIENT ? GroupClient : GroupAdmin);
            this.props.history.push(PERSON_CREATE_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType).replace(USER_ID_PARAM, serviceId));
        } else {
            this.props.setMessage({ message: i18n.t('id-invalid'), type: MESSAGE_ERROR });
        }
    }

    findAllUser() {
        this.props.showLoading(true);

        findAll(IDENTITY_ADMIN_GROUP, this.props.match.params.userType)
            .then((response: IdentityUserWithOffice[]) => {
                this.props.showLoading(false);
                this.setState({ users: response });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    onChangeId(e: any) {
        e.preventDefault();
        const userId: string = formatUserId(e.target.value);
        this.setState({ userId, create: false });
    }

    onSelect(selected: IdentityUserWithOffice) {
        let userID: string = formatUserId(selected.userId);
        if (userID !== "")
            this.setState({ selected });
        else
            this.setState({ selected: null });
    }

    render() {
        return (
            <div className="panel-body">
                {this.props.havePermission(USER_SHOW_CREATE_PERM) &&
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('create')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="form-horizontal" onSubmit={this.createUser}>
                                <div className="form-group">
                                    <label htmlFor="inputPassword3" className="col-sm-2 control-label">{i18n.t('id')}:</label>
                                    <div className="col-sm-4">
                                        <input type="text" onChange={this.onChangeId} value={this.state.userId} name="userid" className="form-control" id="userid" placeholder={i18n.t('id-enter-' + this.props.match.params.userType)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-offset-2 col-sm-10">
                                        {this.props.match.params.userType === TYPE_USER && this.props.havePermission(USER_CREATE_PERM) && <button type="button" onClick={this.createUser} id="deleteButton" className="btn btn-info" >{i18n.t('create')}</button>}
                                        {this.props.match.params.userType === TYPE_CLIENT && this.props.havePermission(CLIENT_CREATE_PERM) && this.state.create && <button type="button" onClick={this.createUser} id="deleteButton" className="btn btn-info" >{i18n.t('create')}</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                {this.props.havePermission(USER_LIST_PERM) &&
                    <div className="card margin-card-top">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('users')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {this.props.havePermission(USER_SHOW_PERM) && <button type="button" onClick={this.findUser} id="findButton" disabled={this.state.selected === null} className="btn btn-primary">{i18n.t('show')}</button>}

                            <BootstrapTable
                                data={this.state.users}
                                selectRow={{ mode: 'radio', onSelect: this.onSelect }}
                                bordered={true} >
                                <TableHeaderColumn dataField='userId' dataFormat={formatUserIdForList} isKey={true} dataSort={true} dataAlign="right" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }}>{i18n.t('id')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='userName' dataSort={true} dataAlign="left">{i18n.t('name')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='branchOffice' dataFormat={formatBranchOfficeForList} dataSort={true} dataAlign={'left'}>{i18n.t('branch-office')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='role' dataFormat={formatRoleForList} dataSort={true} dataAlign="left">{i18n.t('role')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='externalUserName' dataSort={true} dataAlign="left" hidden={!this.props.useIntellitrust}>{i18n.t('external-name')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='userActive' dataFormat={formatStatusForList} dataSort={true} dataAlign="center">{i18n.t('status-user')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export const FindUser = withAppUserContext(FindUserMain);