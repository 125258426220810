import { AxiosResponse } from "axios";
import { IdentityAuthUser, IdentityAuthUserAPI } from "../interfaces/Identity";
import { helperAxios, setToken, apiErrorCallback } from "../utils/ApiBaseConfig";
import { GroupAdmin } from "../utils/Config";
import { cleanProperty, capitalize } from "../utils/Formatter";
import { parseOfficeWithDeviceType } from "./Office";
import { parseRole } from "./Role";
import i18n from "../i18n";
import { LANGUAGE_EN, LANGUAGE_ES } from "../const/Identity";
import { disconnectWebSocket } from "../utils/StompBaseConfig";
import { API_PREFIX } from "../const/Routes";
import { IntelliTrustAuthenticators, IntelliTrustChallengeRequest } from "../interfaces/IntelliTrust";

export function getAuthenticate(username: string, password: string): Promise<string> {
    const lang: string = i18n.language.startsWith('es') ? LANGUAGE_ES : LANGUAGE_EN;
    return helperAxios({
        method: 'POST', url: '/api/v2/login?lang=' + lang,
        data: {
            username: username,
            password: password,
            group: GroupAdmin
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getAuthenticate');
            setToken(response.data.token);
            return response.data.token;
        })
        .catch(apiErrorCallback);
}

export function getAuthenticateToken(password: string): Promise<string> {
    return helperAxios({
        method: 'POST', url: '/api/v2/login-token',
        data: {
            password: password,
            group: GroupAdmin
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK]getAuthenticateToken');
            setToken(response.data.token);
            return response.data.token;
        })
        .catch(apiErrorCallback);
}

export function getUserSessionInfo(): Promise<IdentityAuthUser> {
    return helperAxios({ method: 'GET', url: '/api/v2/principal/' })
        .then((response: AxiosResponse) => {
            console.log('[OK] getUserSessionInfo');
            return parseUser(response.data);
        })
        .catch(apiErrorCallback);
}

export function getUserLogout(): Promise<boolean> {
    return helperAxios({ method: 'GET', url: '/api/v2/logout' })
        .then((response: AxiosResponse) => {
            disconnectWebSocket();
            return true;
        })
        .catch(apiErrorCallback);
}

function parseUser(userAPI: IdentityAuthUserAPI): IdentityAuthUser {
    return {
        enabled: userAPI.enabled,
        fullName: capitalize(cleanProperty(userAPI.fullName)),
        group: cleanProperty(userAPI.group),
        permissionIds: userAPI.permissionIds,
        username: cleanProperty(userAPI.username),
        role: parseRole(userAPI.role),
        branchOffice: userAPI.branchOffice ? parseOfficeWithDeviceType(userAPI.branchOffice) : undefined,
        alias: userAPI.alias,
    }
}

export function getAuthenticatorsIntelliTrust(username: string): Promise<IntelliTrustAuthenticators> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/authenticators',
        data: {
            username: username,
            group: GroupAdmin
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getAuthenticators');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function getAuthenticateIntelliTrust(username: string, challengeType: string, otpDeliveryType: string): Promise<IntelliTrustChallengeRequest> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/authenticate',
        data: {
            username: username,
            group: GroupAdmin,
            challengeType: challengeType,
            otpDeliveryType: otpDeliveryType
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getAuthenticateIntelliTrust');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function completeAuthenticateIntelliTrust(username: string, challengeType: string, otpDeliveryType: string, token: string, response: string): Promise<string> {

    return helperAxios({
        method: 'POST', url: API_PREFIX + '/authenticate/complete',
        data: {
            username: username,
            group: GroupAdmin,
            challengeType: challengeType,
            otpDeliveryType: otpDeliveryType,
            token: token,
            response: cleanProperty(response)
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] completeAuthenticateIntelliTrust');
            setToken(response.data.token);
            return response.data.token;
        })
        .catch(apiErrorCallback);
}