import { PasswordState } from "../interfaces/Password";

export const PASSWORD_STATE_ACTIVE = 'active';
export const PASSWORD_STATE_LOCKED = 'locked';

export const PASSWORD_STATES: { [key: string]: PasswordState } = {
    "OK": PASSWORD_STATE_ACTIVE,
    "LOCKED": PASSWORD_STATE_LOCKED,
}
export const PASSWORD_SERVICES_NAMES: { [key: string]: string } = {
    "AU": "Audioguía",
    "IT": "Internet",
}
