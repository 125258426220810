import { AxiosResponse } from "axios";
import { apiErrorCallback, helperAxios } from "../utils/ApiBaseConfig";
import { SearchNotices, Notice, NoticeConfiguration } from "../interfaces/Notice";
import { NOTICE_STATES } from "../const/Notice";
import { cleanProperty } from "../utils/Formatter";
import { API_PREFIX } from "../const/Routes";

export async function findNotices(search: SearchNotices): Promise<Notice[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/notice/find',
        data: {
            ...search
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findNotices');
            return response.data.map(parseNotices)
        })
        .catch(apiErrorCallback);
}

export async function findNoticesConf(search: SearchNotices): Promise<NoticeConfiguration[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/notice/conf',
        data: {
            ...search
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findNoticesConf');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export async function getNoticeConf(confId: string): Promise<NoticeConfiguration> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/notice/conf/' + confId,
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findNoticesConf');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export async function updateNoticeConf(noticeConf: NoticeConfiguration): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/notice/conf/edit',
        data: {
            correlative: noticeConf.correlative,
            noticeType: noticeConf.noticeType,
            branchOffice: noticeConf.branchOffice,
            independentMails: noticeConf.independentMails,
            userMails: noticeConf.userMails,
            roleMails: noticeConf.roleMails
        }
    })
        .then(() => {
            console.log('[OK] updateNoticeConf');
            return true;
        })
        .catch(apiErrorCallback);
}

export function parseNotices(notice: Notice): Notice {
    return {
        ...notice,
        noticeDate: new Date(notice.noticeDate),
        updateDate: new Date(notice.updateDate),
        noticeStatus: NOTICE_STATES[cleanProperty(notice.noticeStatus)]
    }
}