import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { SHIPMENT_CANCEL_PERM, SHIPMENT_REQUEST_PERM, SHIPMENT_SEND_PERM, SHIPMENT_RECEIVE_PERM, SHIPMENT_SHOW_PERM } from "../../../const/Permission";
import { getShipments, cancelShipments, requestShipments, sendShipments } from "../../../services/Shipment";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { ShipmentSummary, ShipmentState, ShipmentAPI } from "../../../interfaces/Shipment";
import { shipmentStateFormatter } from "../../../utils/Formatter";
import { MESSAGE_SUCCESS } from "../../../const/Message";
import { SHIPMENT_STATE_CANCELED, SHIPMENT_STATE_ASSIGNED, SHIPMENT_STATE_REQUESTED, SHIPMENT_STATE_SENT, SHIPMENT_STATE_RECEIVED, CURUS_SHIPMENT_STATES } from "../../../const/Shipment";
import { DEVICES_SHIPPING_INFO_ROUTE, DEVICES_SHIPPING_ID_PARAM, DEVICES_SHIPPING_RECEIVE_ROUTE, DEVICES_SHIPPING_GENERATE_ROUTE } from "../../../const/Routes";

export interface ShipmentsProps extends RouteComponentProps, AppContextProps { }

export interface ShipmentsState {
    shipments: ShipmentSummary[];
    selected: ShipmentSummary[];
    tableOptions: any;
}

export class ShipmentsMain extends React.Component<ShipmentsProps, ShipmentsState> {

    constructor(props: ShipmentsProps) {
        super(props);
        this.fetchShipments = this.fetchShipments.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.show = this.show.bind(this);
        this.cancel = this.cancel.bind(this);
        this.request = this.request.bind(this);
        this.send = this.send.bind(this);
        this.receive = this.receive.bind(this);
        this.commonState = this.commonState.bind(this);
        this.canCancel = this.canCancel.bind(this);
        this.canSend = this.canSend.bind(this);
        this.canReceive = this.canReceive.bind(this);
        this.cleanSelectedShipments = this.cleanSelectedShipments.bind(this);

        this.state = {
            shipments: [],
            selected: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            }
        }
    }

    onSelect(shipment: ShipmentSummary, isSelected: boolean) {
        const selected: ShipmentSummary[] = this.state.selected.slice();
        if (isSelected) {
            selected.push(shipment);
        } else {
            const index: number = selected.findIndex((sm: ShipmentSummary) => sm.shipmentCorrelative === shipment.shipmentCorrelative);
            selected.splice(index, 1);
        }
        this.setState({ selected });
    }

    onSelectAll(isSelected: boolean): any {
        const all: ShipmentSummary[] = this.state.shipments.slice();
        this.setState({ selected: isSelected ? all : [] });
    }

    componentDidMount() {
        this.fetchShipments();
    }

    fetchShipments() {
        this.props.showLoading(true);
        getShipments()
            .then((shipments: ShipmentSummary[]) => {
                this.setState({ shipments }, () => this.props.showLoading(false));
                if (this.state.selected.length > 0) {
                    const selected: ShipmentSummary[] = shipments.filter((shipment: ShipmentSummary) => {
                        return this.state.selected.some((summary: ShipmentSummary) => summary.shipmentCorrelative === shipment.shipmentCorrelative)
                    });
                    this.setState({ selected });
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    cleanSelectedShipments(): ShipmentAPI[] {
        const selected: ShipmentSummary[] = this.state.selected.slice();
        const clean: ShipmentAPI[] = [];
        for (let i = 0; i < selected.length; i++) {
            const shipment: ShipmentSummary = Object.assign({}, selected[i]);
            clean.push({ 
                ...shipment, 
                shipmentState: CURUS_SHIPMENT_STATES[shipment.shipmentState], 
                sendDate: null,
                receptionDate: null,
                updateDate: 0,
                details: [],
                types: []
            });
        }

        return clean;
    }

    show() {
        this.props.history.push(DEVICES_SHIPPING_INFO_ROUTE.replace(DEVICES_SHIPPING_ID_PARAM, String(this.state.selected![0].shipmentCorrelative)));
    }

    cancel() {
        var result = confirm(i18n.t('shipments-canceled-confirm'));
        if (result) {
        this.props.showLoading(true);
        cancelShipments(this.cleanSelectedShipments())
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-canceled'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.fetchShipments();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
        }
    }

    request() {
        this.props.showLoading(true);
        requestShipments(this.cleanSelectedShipments())
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-requested'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.fetchShipments();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    send() {
        this.props.showLoading(true);
        sendShipments(this.cleanSelectedShipments())
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-sent'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.fetchShipments();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    receive() {
        this.props.history.push(DEVICES_SHIPPING_RECEIVE_ROUTE.replace(DEVICES_SHIPPING_ID_PARAM, String(this.state.selected![0].shipmentCorrelative)));
    }

    commonState() {
        if (this.state.selected.length === 0) return SHIPMENT_STATE_CANCELED;
        const state: ShipmentState = this.state.selected[0].shipmentState;
        const equal: boolean = this.state.selected.every((shipment: ShipmentSummary) => shipment.shipmentState === state);
        return equal ? state : SHIPMENT_STATE_CANCELED;
    }

    canCancel() {
        const state: ShipmentState = this.commonState();
        const originCode: string = this.state.selected[0].originCode;
        return ![SHIPMENT_STATE_RECEIVED, SHIPMENT_STATE_CANCELED].includes(state) && originCode === this.props.authUser!.branchOffice!.code;
    }

    canSend() {
        return this.state.selected.every((shipment: ShipmentSummary) => shipment.originCode === this.props.authUser!.branchOffice!.code);
    }

    canReceive() {
        return this.state.selected.every((shipment: ShipmentSummary) => shipment.destinationCode === this.props.authUser!.branchOffice!.code);
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('shipments-status')}</div>
                    </div>
                </div>
                <div className="card-body">
                    {this.props.havePermission(SHIPMENT_CANCEL_PERM) && <button className="btn btn-primary" disabled={this.state.selected.length === 0 || !this.canCancel()} onClick={this.cancel}>{i18n.t('cancel')}</button>}
                    {this.props.havePermission(SHIPMENT_REQUEST_PERM) && <button className="btn btn-primary" disabled={this.commonState() != SHIPMENT_STATE_ASSIGNED || !this.canSend()} onClick={this.request}>{i18n.t('request')}</button>}
                    {this.props.havePermission(SHIPMENT_SEND_PERM) && <button className="btn btn-primary" disabled={this.commonState() != SHIPMENT_STATE_REQUESTED || !this.canSend()} onClick={this.send}>{i18n.t('send')}</button>}
                    {this.props.havePermission(SHIPMENT_RECEIVE_PERM) && <button className="btn btn-primary" disabled={this.state.selected.length !== 1 || this.commonState() != SHIPMENT_STATE_SENT || !this.canReceive()} onClick={this.receive}>{i18n.t('receive')}</button>}
                    {this.props.havePermission(SHIPMENT_SHOW_PERM) && <button className="btn btn-primary" disabled={this.state.selected.length !== 1} onClick={this.show}>{i18n.t('show')}</button>}

                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">{i18n.t('shipment-select')}</div>
                        <BootstrapTable
                            data={this.state.shipments}
                            selectRow={{ 
                                mode: 'checkbox',
                                onSelect: this.onSelect, 
                                onSelectAll: this.onSelectAll
                            }}
                            bordered={true}
                            options={this.state.tableOptions}>
                            <TableHeaderColumn dataField='shipmentCorrelative' isKey={true} dataSort={true} dataAlign="center" width="60">{i18n.t('id-list')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='shipmentState' dataSort={true} dataFormat={shipmentStateFormatter} dataAlign="center" width="140">{i18n.t('status')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='sender' dataSort={true} dataAlign="center" width="160">{i18n.t('sender')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='receiver' dataSort={true} dataAlign="center" width="160">{i18n.t('receiver')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceType' dataSort={true} width="220">{i18n.t('device-type')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='origin' dataSort={true} width="220">{i18n.t('origin')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='destination' dataSort={true} width="220">{i18n.t('destination')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='sendDate' dataSort={true} dataAlign="center" width="180">{i18n.t('date-send')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='receptionDate' dataSort={true} dataAlign="center" width="180">{i18n.t('date-reception')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='updateDate' dataSort={true} dataAlign="center" width="180">{i18n.t('date-update')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='comment' dataSort={true} width="300">{i18n.t('comment')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    }
}

export const Shipments = withAppContext(ShipmentsMain);