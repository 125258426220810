import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { ExportCSVButton } from "react-bootstrap-table";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { ShipmentEvent } from "../../../interfaces/Event";
import { getShipmentsEvents } from "../../../services/Event";
import { ShipmentEventsTable } from "./event/List";


export interface ShipmentEventsProps extends RouteComponentProps, AppContextProps { }

export interface ShipmentEventsState {
    events: ShipmentEvent[];
    tableOptions: any;
 }

export class ShipmentEventsMain extends React.Component<ShipmentEventsProps, ShipmentEventsState> {

    constructor(props: ShipmentEventsProps) {
        super(props);
        this.state = {
            events: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 5,
                paginationSize: 3,
                pageStartIndex: 1,
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('csv-save')} btnContextual='btn-info' onClick={onClick} /> }
            }
        }
    }

    componentDidMount() {
        this.props.showLoading(true);
        getShipmentsEvents()
            .then((events: ShipmentEvent[]) => {
                this.setState({ events }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {
        return (
            <div className="panel-body">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('recent-activity')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ShipmentEventsTable events={this.state.events} />
                    </div>
                </div>
            </div>
        )
    }
}

export const ShipmentEvents = withAppContext(ShipmentEventsMain);