import * as React from "react";
import { RouteComponentProps } from "react-router";
import i18n from "../../../i18n";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { DeviceTypeParams } from "../../../const/Routes";
import { AssignOffice } from "../../../interfaces/DeviceType";
import { MESSAGE_WARNING } from "../../../const/Message";

interface AssignGroupProps extends RouteComponentProps<DeviceTypeParams>, AppContextProps {
    assignedOffices: AssignOffice[];
    allOffices: AssignOffice[];
    addAssigns: Function;
    removeAssigns: Function;
}

interface AssignGroupState {
    availableOffices: AssignOffice[];
    itemsCurrent: string[];
    itemsAvailable: string[];
}

export class AssignGroupMain extends React.Component<AssignGroupProps, AssignGroupState>{
    currentRef: any;
    availableRef: any;

    constructor(props: AssignGroupProps) {
        super(props);
        this.setGroupItems = this.setGroupItems.bind(this);
        this.addAssigns = this.addAssigns.bind(this);
        this.removeAssigns = this.removeAssigns.bind(this);
        this.onSelectCurrent = this.onSelectCurrent.bind(this);
        this.onSelectAvailable = this.onSelectAvailable.bind(this);

        this.state = {
            availableOffices: [],
            itemsCurrent: [],
            itemsAvailable: [],
        }

        this.currentRef = React.createRef();
        this.availableRef = React.createRef();
    }


    componentWillReceiveProps(props: AssignGroupProps) {
        if (props.allOffices !== this.props.allOffices || props.assignedOffices !== this.props.assignedOffices) {
            this.setGroupItems(props.allOffices, props.assignedOffices);
        }
    }

    setGroupItems(allOffices: AssignOffice[], assignedOffices: AssignOffice[]) {
        const availableOffices: AssignOffice[] = allOffices.filter(assign => {
            return assignedOffices.find(cur => cur.code === assign.code) === undefined
        });
        this.setState({ availableOffices });
    }

    addAssigns() {
        const selected: AssignOffice[] = this.state.availableOffices.filter((assign: AssignOffice) => this.state.itemsAvailable.includes(assign.code));
        this.props.addAssigns(selected);
    }

    removeAssigns() {
        const selected: AssignOffice[] = this.props.assignedOffices.filter((assign: AssignOffice) => this.state.itemsCurrent.includes(assign.code));
        const aux = selected.find((assign: AssignOffice) => assign.code === "1");
        if (aux != undefined) {
            const offName: string = aux.name;
            this.props.setMessage({ message: i18n.t('cant-remove-central-office', { offName }), type: MESSAGE_WARNING });
        } else {
            this.props.removeAssigns(selected);
        }
    }

    onSelectCurrent(e: any) {
        const options = e.target.selectedOptions || Array.from(this.currentRef.current.options).filter((option: any) => option.selected);
        const itemsCurrent: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsCurrent.push(options[i].value)
        }
        this.setState({ itemsCurrent });
    }

    onSelectAvailable(e: any) {
        const options = e.target.selectedOptions || Array.from(this.availableRef.current.options).filter((option: any) => option.selected);
        const itemsAvailable: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsAvailable.push(options[i].value)
        }
        this.setState({ itemsAvailable });
    }

    render() {
        const currentItems: JSX.Element[] = this.props.assignedOffices.map((item: AssignOffice) => {
            return <option key={item.code} value={item.code}>{item.code} - {item.name}</option>
        });
        const availableItems: JSX.Element[] = this.state.availableOffices.map((item: AssignOffice) => {
            return <option key={item.code} value={item.code}>{item.code} - {item.name}</option>
        });

        return (
            <div className="form-group">
                <div className="col-md-3">
                    <select ref={this.currentRef} multiple={true} className="form-control" id="current" onChange={this.onSelectCurrent}>
                        {currentItems}
                    </select>
                </div>
                <div className="col-md-2">
                    <button type="button" id="addModulo" className="btn btn-success btn-block" onClick={this.addAssigns} disabled={this.state.itemsAvailable.length === 0}>
                        <span className="glyphicon glyphicon-chevron-left"></span>{i18n.t('add')}
                    </button>
                    <button type="button" id="subModulo" className="btn btn-danger btn-block" onClick={this.removeAssigns} disabled={this.state.itemsCurrent.length === 0}>
                        {i18n.t('remove')}<span className="glyphicon glyphicon-chevron-right"></span>
                    </button>
                </div>
                <div className="col-md-3">
                    <select ref={this.availableRef} multiple={true} className="form-control" id="available" onChange={this.onSelectAvailable}>
                        {availableItems}
                    </select>
                </div>
            </div>
        )
    }
}

export const AssignGroup = withAppContext(AssignGroupMain);