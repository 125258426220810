import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "./../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { Role } from "../../interfaces/Role";
import { NoticeConfParams, TYPE_USER } from "../../const/Routes";
import { withAppUserContext } from "../../context/UserContext";
import { NoticeConfiguration } from "../../interfaces/Notice";
import { getNoticeConf, updateNoticeConf } from "../../services/Notice";
import { getRolesList } from "../../services/Role";
import { getAllNamesAndCodes } from "../../services/User";
import { IDENTITY_ADMIN_GROUP } from "../../const/Group";

interface FormConfProps extends RouteComponentProps<NoticeConfParams>, AppContextProps { }

interface FormConfState {
    noticeConfiguration: NoticeConfiguration | null;
    roleList: Role[];
    assignedRoles: string[];
    userList: string[];
    assignedUsers: string[];
    mails: string;
    itemsCurrent: string[];
    itemsAvailable: string[];
}

export class FormConfMain extends React.Component<FormConfProps, FormConfState>{
    currentRef: any;
    availableRef: any;

    constructor(props: FormConfProps) {
        super(props);
        this.fetchNoticeConf = this.fetchNoticeConf.bind(this);
        this.fetchRoles = this.fetchRoles.bind(this);
        this.fetchNamesAndCodes = this.fetchNamesAndCodes.bind(this);
        this.changeRoles = this.changeRoles.bind(this);
        this.onSelectCurrent = this.onSelectCurrent.bind(this);
        this.onSelectAvailable = this.onSelectAvailable.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.removeUsers = this.removeUsers.bind(this);
        this.handleMailsChange = this.handleMailsChange.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.back = this.back.bind(this);

        this.state = {
            noticeConfiguration: null,
            roleList: [],
            assignedRoles: [],
            userList: [],
            assignedUsers: [],
            mails: '',
            itemsCurrent: [],
            itemsAvailable: [],
        }

        this.currentRef = React.createRef();
        this.availableRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.match.params.noticeConfId)
            this.fetchNoticeConf(this.props.match.params.noticeConfId);
    }

    fetchNoticeConf(noticeConfId: string) {
        this.props.showLoading(true);
        getNoticeConf(noticeConfId)
            .then((conf: NoticeConfiguration) => {
                this.setState({
                    noticeConfiguration: conf,
                    assignedRoles: conf.roleMails.split(",").filter(e => e),
                    assignedUsers: conf.userMails.split(",").filter(e => e),
                    mails: conf.independentMails
                }, () => {
                    this.props.showLoading(false);
                    this.fetchRoles();
                    this.fetchNamesAndCodes();
                });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
                this.props.history.goBack();
            });
    }

    fetchRoles() {
        getRolesList()
            .then((roleList: Role[]) => {
                this.setState({ roleList }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    changeRoles(e: any) {
        let role = e.target.value;
        if (this.state.assignedRoles.indexOf(role) == -1) {
            this.setState({ assignedRoles: this.state.assignedRoles.concat(role) });
        } else {
            this.setState({ assignedRoles: this.state.assignedRoles.filter((elem) => elem !== role) });
        }
    }

    fetchNamesAndCodes() {
        this.props.showLoading(true);
        getAllNamesAndCodes(IDENTITY_ADMIN_GROUP, TYPE_USER)
            .then((response: string[]) => {
                this.props.showLoading(false);
                this.setState({ userList: response.filter((item) => !this.state.assignedUsers.includes(item)) });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    onSelectCurrent(e: any) {
        const options = e.target.selectedOptions || Array.from(this.currentRef.current.options).filter((option: any) => option.selected);
        const itemsCurrent: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsCurrent.push(options[i].value)
        }
        this.setState({ itemsCurrent });
    }

    onSelectAvailable(e: any) {
        const options = e.target.selectedOptions || Array.from(this.availableRef.current.options).filter((option: any) => option.selected);
        const itemsAvailable: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsAvailable.push(options[i].value)
        }
        this.setState({ itemsAvailable });
    }

    addUsers() {
        let assigned = this.state.assignedUsers.concat(this.state.itemsAvailable);
        let availables = this.state.userList.filter((item) => !this.state.itemsAvailable.includes(item));
        this.setState({ itemsAvailable: [], assignedUsers: assigned, userList: availables });
    }

    removeUsers() {
        let availables = this.state.userList.concat(this.state.itemsCurrent);
        let assigned = this.state.assignedUsers.filter((item) => !this.state.itemsCurrent.includes(item));
        this.setState({ itemsCurrent: [], assignedUsers: assigned, userList: availables });
    }

    handleMailsChange(e: any) {
        let value = e.target.value;
        this.setState({ mails: value })
    }

    back() {
        this.props.history.goBack();
    }

    updateAction() {

        if (this.state.noticeConfiguration != null) {
            let conf = this.state.noticeConfiguration;
            conf.roleMails = this.state.assignedRoles.join(",");
            conf.userMails = this.state.assignedUsers.join(",");
            conf.independentMails = this.state.mails;
            updateNoticeConf(conf)
                .then((response: boolean) => {
                    this.props.setMessage({ message: i18n.t('notice-update-success'), title: "", type: "alert-success" });
                    this.props.showLoading(false);
                    this.fetchNoticeConf(this.props.match.params.noticeConfId);
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    render() {

        return (
            <div>
                {this.state.noticeConfiguration != null && <div>

                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('notice-update')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="form-inline">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label className="control-label">{i18n.t('type')}:</label>
                                        <span className="form-control">{this.state.noticeConfiguration.noticeType.name}</span>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label className="control-label">{i18n.t('region')}:</label>
                                        <span className="form-control">{this.state.noticeConfiguration.branchOffice.region.regionName}</span>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label className="control-label">{i18n.t('branch-office')}:</label>
                                        <span className="form-control">{this.state.noticeConfiguration.branchOffice.name}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <hr />
                                    <h4>{i18n.t('role-user')}</h4>
                                    <hr />

                                    {
                                        this.state.roleList.map(item => (
                                            <div className="form-group col-sm-3" key={item.codeRole}>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" value={item.codeRole} onChange={this.changeRoles} checked={this.state.assignedRoles.indexOf(item.codeRole) > -1} /> {item.roleName}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                                <div className="row">
                                    <hr />
                                    <h4>{i18n.t('users')}</h4>
                                    <hr />
                                    <div className="col-sm-4">
                                        <label className="control-label">{i18n.t('assigned')}:</label>
                                        <select ref={this.currentRef} multiple={true} id="current" onChange={this.onSelectCurrent} className="form-control full-width">
                                            {
                                                this.state.assignedUsers.map(item => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label className="control-label">&nbsp;</label>
                                        <button type="button" id="addModulo" className="btn btn-success btn-block" onClick={this.addUsers} disabled={this.state.itemsAvailable.length === 0}>
                                            <span className="glyphicon glyphicon-chevron-left"></span>{i18n.t('add')}
                                        </button>
                                        <button type="button" id="subModulo" className="btn btn-danger btn-block" onClick={this.removeUsers} disabled={this.state.itemsCurrent.length === 0}>
                                            {i18n.t('remove')}<span className="glyphicon glyphicon-chevron-right"></span>
                                        </button>
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="control-label">{i18n.t('any')}:</label>
                                        <select ref={this.availableRef} multiple={true} id="available" onChange={this.onSelectAvailable} className="form-control full-width">
                                            {
                                                this.state.userList.map(item => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <hr />
                                    <h4>{i18n.t('mails')}</h4>
                                    <hr />

                                    <div className="form-group col-sm-12">
                                        <label className="control-label">{i18n.t('mails-sub')}:</label>
                                        <input className="form-control full-width" value={this.state.mails} onChange={this.handleMailsChange} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" onClick={this.back} className="btn btn-primary">{i18n.t('back')}</button>
                                        <button type="button" onClick={this.updateAction} className="btn btn-info ">{i18n.t('update')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

export const FormConf = withAppUserContext(FormConfMain);