import * as React from "react";
import { SoftToken } from "../../../interfaces/SoftToken";
import i18n from "../../../i18n";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getListSoftToken } from "../../../services/SoftToken";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { RouteUserParams } from "../../../const/Routes";
import { UserContextProps, withAppUserContext } from "../../../context/UserContext";
import { translate } from "../../../utils/Formatter";

interface SoftTokenListProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps {
    changeChecked?: Function;
    refresh?: boolean;
}
interface SoftTokenListState {
    list: SoftToken[];
    selected: SoftToken | null;
    tableOptions: any;
}

export class SoftTokenListMain extends React.Component<SoftTokenListProps, SoftTokenListState> {
    constructor(props: SoftTokenListProps) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.changeChecked = this.changeChecked.bind(this);

        this.state = {
            list: [],
            selected: null,
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            }
        }
    }

    componentWillMount() {
        this.refreshList();
    }

    componentWillReceiveProps(props: SoftTokenListProps) {
        if(this.props.refresh !== props.refresh) this.refreshList(props);
    }

    refreshList(props?: SoftTokenListProps) {
        if (props === undefined || props.refresh !== this.props.refresh) {
            this.props.showLoading(true);
            getListSoftToken(this.props.match.params.userId, this.props.match.params.userGroup)
                .then((list: SoftToken[]) => {
                    this.setState({ list }, () => this.props.showLoading(false));
                    if (this.props.changeChecked && this.state.selected) {
                        const updated = list.find(item => { return item.serialNumber === this.state.selected!.serialNumber });
                        this.changeChecked(updated!);
                    }
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    changeChecked(selected: SoftToken) {
        this.setState({ selected });
        this.props.changeChecked!(selected);
    }

    render() {
        return (
            <BootstrapTable 
                bordered={false} 
                data={this.state.list} 
                selectRow={this.props.changeChecked ? { mode: 'radio', onSelect: this.changeChecked } : { mode: 'none' }}
                options={this.state.tableOptions}>
                <TableHeaderColumn dataField='serialNumber' isKey={true} dataAlign={"center"} headerAlign="center">{i18n.t('serial-number')}</TableHeaderColumn>
                <TableHeaderColumn dataField='name' dataAlign={"center"} headerAlign="center">{i18n.t('name')}</TableHeaderColumn>
                <TableHeaderColumn dataField='state' dataAlign={"center"} headerAlign="center" dataFormat={translate}>{i18n.t('status')}</TableHeaderColumn>
                <TableHeaderColumn dataField='mobilePlatform' dataAlign={"center"} headerAlign="center">{i18n.t('mobile-platform')}</TableHeaderColumn>
                <TableHeaderColumn dataField='mobileVersion' dataAlign={"center"} headerAlign="center">{i18n.t('mobile-version')}</TableHeaderColumn>
                <TableHeaderColumn dataField='loadDate' dataAlign={"center"} headerAlign="center">{i18n.t('date-creation')}</TableHeaderColumn>
                <TableHeaderColumn dataField='registrationDate' dataAlign={"center"} headerAlign="center">{i18n.t('date-activation')}</TableHeaderColumn>
                <TableHeaderColumn dataField='lastUsedDate' dataAlign={"center"} headerAlign="center">{i18n.t('date-last-use')}</TableHeaderColumn>
            </BootstrapTable>
        )
    }
}

export const SoftTokenList = withAppUserContext(SoftTokenListMain);