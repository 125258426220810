import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import { FORBIDDEN_ROUTE, RouteRoleParams, DOCUMENTS_ROUTE, DOCUMENT_MODIFY_ROUTE, DOCUMENT_CREATE_ROUTE } from "../../const/Routes";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { Documents } from "../../components/documents/List";
import { DOCUMENT_LIST_PERM, DOCUMENT_UPLOAD_PERM } from "../../const/Permission";
import { DocumentContextProvider, DocumentContextProps } from "../../context/DocumentContext";
import { FormDocument } from "../../components/documents/Form";
import { DocumentTemplate, DocumentTemplateVersion } from "../../interfaces/Document";

interface DocumentContainerProps extends RouteComponentProps<RouteRoleParams>, AppContextProps { }
interface DocumentContainerState {
    documentContext: DocumentContextProps;
 }

export class DocumentContainerMain extends React.Component<DocumentContainerProps, DocumentContainerState>{
    constructor(props: DocumentContainerProps) {
        super(props);
        this.setDocument = this.setDocument.bind(this);

        this.state = {
            documentContext: {
                document: null,
                version: null,
                setDocument: this.setDocument,
            }
        }
    }

    setDocument(document: DocumentTemplate, version: DocumentTemplateVersion) {
        this.setState(state => ({ ...state, documentContext: { ...state.documentContext, document, version } }));
    }

    render() {
        return (
            <DocumentContextProvider value={this.state.documentContext}>
                <Switch>
                    {this.props.havePermission(DOCUMENT_UPLOAD_PERM) && <Route path={DOCUMENT_MODIFY_ROUTE} exact component={FormDocument} />}
                    {this.props.havePermission(DOCUMENT_UPLOAD_PERM) && <Route path={DOCUMENT_CREATE_ROUTE} exact component={FormDocument} />}
                    {this.props.havePermission(DOCUMENT_LIST_PERM) &&<Route path={DOCUMENTS_ROUTE} exact component={Documents} />}
                    <Redirect to={FORBIDDEN_ROUTE} />
                </Switch>
            </DocumentContextProvider>
        )
    }
}

export const DocumentContainer = withAppContext(DocumentContainerMain);