import * as React from "react";
import { RouteComponentProps } from "react-router";
import { RouteUserParams } from "../../../const/Routes";
import { AppContextProps } from "../../../context/AppContext";
import { UserContextProps, withAppUserContext } from "../../../context/UserContext";
import { Password } from "../../../interfaces/Password";
import i18n from "../../../i18n";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getUserPasswords } from "../../../services/Password";
import { translate } from "../../../utils/Formatter";

interface PasswordListProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps {
    changeChecked?: Function;
    refresh?: boolean;
}
interface PasswordListState {
    list: Password[];
    selected: Password | null;
    tableOptions: any;
}

export class PasswordListMain extends React.Component<PasswordListProps, PasswordListState> {
    constructor(props: PasswordListProps) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.changeChecked = this.changeChecked.bind(this);

        this.state = {
            list: [],
            selected: null,
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            }
        }
    }

    componentWillMount() {
        this.refreshList();
    }

    componentWillReceiveProps(props: PasswordListProps) {
        if(this.props.refresh !== props.refresh) this.refreshList(props);
    }

    refreshList(props?: PasswordListProps) {
        if (props === undefined || props.refresh !== this.props.refresh) {
            this.props.showLoading(true);
            getUserPasswords(this.props.match.params.userId, this.props.match.params.userGroup)
                .then((list: Password[]) => {
                    this.setState({ list }, () => this.props.showLoading(false));
                    if (this.props.changeChecked && this.state.selected) {
                        const updated = list.find(item => { return item.name === this.state.selected!.name });
                        this.changeChecked(updated!);
                    }
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    changeChecked(selected: Password) {
        this.setState({ selected });
        this.props.changeChecked!(selected);
    }

    render() {
        return (
            <BootstrapTable data={this.state.list} 
                bordered={false}
                options={this.state.tableOptions} 
                selectRow={this.props.changeChecked ? { mode: 'radio', onSelect: this.changeChecked } : { mode: 'none' }}>
                <TableHeaderColumn isKey={true} dataField='name' dataAlign={'center'} dataSort={true}>{i18n.t('name')}</TableHeaderColumn>
                <TableHeaderColumn dataField='state' dataAlign={'center'} dataSort={true} dataFormat={translate}>{i18n.t('status')}</TableHeaderColumn>
                <TableHeaderColumn dataField='lockoutDate' dataAlign={'center'} dataSort={true}>{i18n.t('date-lock')}</TableHeaderColumn>
                <TableHeaderColumn dataField='lastAuth' dataAlign={'center'} dataSort={true}>{i18n.t('date-last-use')}</TableHeaderColumn>
                <TableHeaderColumn dataField='failureCount' dataAlign={'center'} dataSort={true}>{i18n.t('attempts')}</TableHeaderColumn>
            </BootstrapTable>
        )
    }
}

export const PasswordList = withAppUserContext(PasswordListMain);