import { ShipmentState } from "../interfaces/Shipment";
import { CURUS_SHIPMENT_STATE_REQUESTED, CURUS_SHIPMENT_STATE_SENT, CURUS_SHIPMENT_STATE_RECEIVED, CURUS_SHIPMENT_STATE_CANCELED, CURUS_SHIPMENT_STATE_GENERATED, CURUS_SHIPMENT_STATE_ASSIGNED, CURUS_SHIPMENT_STATE_GENERATING, CURUS_SHIPMENT_STATE_RECEIVING } from "./Identity";

export const SHIPMENT_STATE_ASSIGNED = "assigned";
export const SHIPMENT_STATE_GENERATED = "generated";
export const SHIPMENT_STATE_GENERATING = "generating";
export const SHIPMENT_STATE_REQUESTED = "requested";
export const SHIPMENT_STATE_SENT = "sent";
export const SHIPMENT_STATE_RECEIVED = "received";
export const SHIPMENT_STATE_RECEIVING = "receiving";
export const SHIPMENT_STATE_CANCELED = "canceled";

export const SHIPMENT_STATES: { [key: string]: ShipmentState } = {
    [CURUS_SHIPMENT_STATE_GENERATED]: SHIPMENT_STATE_GENERATED,
    [CURUS_SHIPMENT_STATE_GENERATING]: SHIPMENT_STATE_GENERATING,
    [CURUS_SHIPMENT_STATE_ASSIGNED]: SHIPMENT_STATE_ASSIGNED,
    [CURUS_SHIPMENT_STATE_REQUESTED]: SHIPMENT_STATE_REQUESTED,
    [CURUS_SHIPMENT_STATE_SENT]: SHIPMENT_STATE_SENT,
    [CURUS_SHIPMENT_STATE_RECEIVED]: SHIPMENT_STATE_RECEIVED,
    [CURUS_SHIPMENT_STATE_RECEIVING]: SHIPMENT_STATE_RECEIVING,
    [CURUS_SHIPMENT_STATE_CANCELED]: SHIPMENT_STATE_CANCELED,
}

export const CURUS_SHIPMENT_STATES: { [key: string]: string } = {
    [SHIPMENT_STATE_GENERATED]: CURUS_SHIPMENT_STATE_GENERATED,
    [SHIPMENT_STATE_GENERATING]: CURUS_SHIPMENT_STATE_GENERATING,
    [SHIPMENT_STATE_ASSIGNED]: CURUS_SHIPMENT_STATE_ASSIGNED,
    [SHIPMENT_STATE_REQUESTED]: CURUS_SHIPMENT_STATE_REQUESTED,
    [SHIPMENT_STATE_SENT]: CURUS_SHIPMENT_STATE_SENT,
    [SHIPMENT_STATE_RECEIVED]: CURUS_SHIPMENT_STATE_RECEIVED,
    [SHIPMENT_STATE_RECEIVING]: CURUS_SHIPMENT_STATE_RECEIVING,
    [SHIPMENT_STATE_CANCELED]: CURUS_SHIPMENT_STATE_CANCELED,
}
