import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { ActivationCode, SoftToken, SoftTokenAPI, SoftTokenActivationState } from "../interfaces/SoftToken";
import i18n from "../i18n";
import { SOFTTOKEN_STATES } from "../const/SoftToken";
import { checkDate } from "../utils/Formatter";
import { STATE_CURRENT, STATE_HOLD } from "../const/Identity";


export function requestForActivationCode(userId: string, group: string): Promise<ActivationCode> {
    return helperAxios({ method: 'POST', url: '/api/v2/users/' + group + '/' + userId.toUpperCase() + '/softtokens/activation' })
        .then((response: AxiosResponse) => {
            const sessionUser: ActivationCode = {
                code: response.data.code,
                serialNumber: response.data.serialNumber,
                type: response.data.type,
            };
            console.log('[OK] requestForActivationCode');
            return sessionUser;
        })
        .catch(apiErrorCallback);
}

export function getListSoftToken(userId: string, group: string): Promise<SoftToken[]> {
    return helperAxios({ method: 'GET', url: '/api/v2/users/' + group + '/' + userId.toUpperCase() + '/softtokens/' })
        .then((response: AxiosResponse) => {
            console.log('[OK] getListSoftToken');
            return response.data.map(parseSoftToken);
        })
        .catch(apiErrorCallback);
}

export function updateSoftTokenState(userId: string, group: string, serialNumber: string, state: string, comment?: string): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/users/' + group + '/' + userId.toUpperCase() + "/softtokens/" + serialNumber + "/",
        data: { serialNumber, state, comment }
    })
        .then(() => {
            console.log('[OK] updateSoftTokenState: to ' + state);
            return true;
        })
        .catch(apiErrorCallback);
}


export function deleteSoftToken(userId: string, group: string, serialNumber: string, reason: string): Promise<boolean> {
    return helperAxios({
        method: 'DELETE', url: '/api/v2/users/' + group + '/' + userId.toUpperCase() + "/softtokens/" + serialNumber + "/",
        data: {
            serialNumber,
            state: STATE_HOLD,
            comment: reason
        }
    })
        .then(() => {
            console.log('[OK] deleteSoftToken');
            return true;
        })
        .catch(apiErrorCallback);
}


export function activateSoftToken(userId: string, group: string, serialNumber: string): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/users/' + group + '/' + userId.toUpperCase() + "/softtokens/" + serialNumber + "/activation",
        data: { serialNumber, state: STATE_CURRENT }
    })
        .then(() => {
            console.log('[OK] activateSoftToken');
            return true;
        })
        .catch(apiErrorCallback);
}

function parseSoftToken(item: SoftTokenAPI): SoftToken {
    return {
        serialNumber: replaceValue(item.serialNumber),
        name: replaceValue(item.tokenSet),
        state: getSoftTokenState(item.state, item.activationState),
        mobilePlatform: replaceValue(item.mobilePlatform),
        mobileVersion: replaceValue(item.mobileVersion),
        loadDate: checkDate(item.loadDate),
        registrationDate: checkDate(item.registrationDate),
        lastUsedDate: checkDate(item.lastUsedDate)
    }
}

function replaceValue(value: string): string {
    let finalValue: string = value;
    if (finalValue === "" || finalValue === null || finalValue === "0") {
        finalValue = i18n.t('no-data');
    }
    if (finalValue.includes("created")) {
        finalValue = "No asignado";
    }
    return finalValue;
}

function getSoftTokenState(state: string, activationState: string): SoftTokenActivationState {
    const globalState: string = activationState + "_" + state;
    return SOFTTOKEN_STATES[globalState];
}
