import * as React from "react";
import { RolePermissionGroup, RolePermission, RolePermissionDepencendy } from "../../../interfaces/Role";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import i18n from "../../../i18n";
import { PERMISSION_TYPES } from "../../../const/Role";
import { ROLE_ID_PARAM, RouteRoleParams } from "../../../const/Routes";
import { AdminCodeRole } from "../../../utils/Config";
import { MESSAGE_ERROR } from "../../../const/Message";

interface PermissionGroupProps extends RouteComponentProps<RouteRoleParams>, AppContextProps {
    permissionGroup: RolePermissionGroup;
    prevPermissions: RolePermission[];
    allPermissions: RolePermission[];
    addPermissions: Function;
    removePermissions: Function;
}
interface PermissionGroupState {
    available: RolePermission[];
    current: RolePermission[];
    itemsCurrent: string[];
    itemsAvailable: string[];
    permissionType: string | undefined;
}

export class PermissionGroupMain extends React.Component<PermissionGroupProps, PermissionGroupState>{
    currentRef: any;
    availableRef: any;

    constructor(props: PermissionGroupProps) {
        super(props);
        this.setGroupItems = this.setGroupItems.bind(this);
        this.addPermissions = this.addPermissions.bind(this);
        this.removePermissions = this.removePermissions.bind(this);
        this.onSelectCurent = this.onSelectCurent.bind(this);
        this.onSelectAvailable = this.onSelectAvailable.bind(this);
        this.checkDependencies = this.checkDependencies.bind(this);
        this.checkIsDependency = this.checkIsDependency.bind(this);
        this.checkPermissionDependencies = this.checkPermissionDependencies.bind(this);

        this.state = {
            available: this.props.permissionGroup.permissions,
            current: [],
            itemsCurrent: [],
            itemsAvailable: [],
            permissionType: PERMISSION_TYPES[this.props.permissionGroup.permissionType] || this.props.permissionGroup.permissionType
        }

        this.currentRef = React.createRef();
        this.availableRef = React.createRef();
    }

    componentWillReceiveProps(props: PermissionGroupProps) {
        if (props.prevPermissions !== this.props.prevPermissions) {
            this.setGroupItems(props.prevPermissions);
        }
    }

    setGroupItems(prevPermissions: RolePermission[]) {
        const current: RolePermission[] = this.props.permissionGroup.permissions.filter(permission => {
            return prevPermissions.find(prev => prev.permissionCode === permission.permissionCode) !== undefined
        });
        const available: RolePermission[] = this.props.permissionGroup.permissions.filter(permission => {
            return current.find(cur => cur.permissionCode === permission.permissionCode) === undefined
        });
        this.setState({ available, current });
    }

    addPermissions() {
        const conflict = this.checkDependencies(this.state.itemsAvailable, false);
        if (conflict) {
            this.props.setMessage({ message: i18n.t('permission-dependency', { 
                permission: conflict.permission.permissionName, 
                dependency: conflict.dependency.permissionName 
            }), type: MESSAGE_ERROR, time: 5000 });
        } else {
            const selected: RolePermission[] = this.state.available.filter((permission: RolePermission) => this.state.itemsAvailable.includes(permission.permissionCode));
            const current: RolePermission[] = [...this.state.current, ...selected];
            const available: RolePermission[] = this.state.available.filter((permission: RolePermission) => !this.state.itemsAvailable.includes(permission.permissionCode));
            this.setState({ current, available, itemsAvailable: [] });
            this.props.addPermissions(selected);
        }
    }

    removePermissions() {
        const conflict = this.checkDependencies(this.state.itemsCurrent, true);
        if (conflict) {
            this.props.setMessage({ message: i18n.t('permission-is-dependency', { 
                permission: conflict.permission.permissionName, 
                dependency: conflict.dependency.permissionName 
            }), type: MESSAGE_ERROR, time: 5000 });
        } else {
            const selected: RolePermission[] = this.state.current.filter((permission: RolePermission) => this.state.itemsCurrent.includes(permission.permissionCode));
            const available: RolePermission[] = [...this.state.available, ...selected];
            const current: RolePermission[] = this.state.current.filter((permission: RolePermission) => !this.state.itemsCurrent.includes(permission.permissionCode));
            this.setState({ current, available, itemsCurrent: [] });
            this.props.removePermissions(selected);
        }
    }

    onSelectCurent(e: any) {
        const options = e.target.selectedOptions || Array.from(this.currentRef.current.options).filter((option: any) => option.selected);
        const itemsCurrent: string[] = [];
        for(let i = 0; i < options.length; i++) {
            itemsCurrent.push(options[i].value)
        }
        this.setState({ itemsCurrent });
    }

    onSelectAvailable(e: any) {
        const options = e.target.selectedOptions || Array.from(this.availableRef.current.options).filter((option: any) => option.selected);
        const itemsAvailable: string[] = [];
        for(let i = 0; i < options.length; i++) {
            itemsAvailable.push(options[i].value)
        }
        this.setState({ itemsAvailable });
    }

    checkDependencies(codes: string[], is: boolean): RolePermissionDepencendy | undefined {
        const base = is ? this.checkIsDependency : this.checkPermissionDependencies
        for(let i = 0; i < codes.length; i++) {
            const code: string = codes[i];
            const check = base(codes, code);
            if (check) return check;
        }
        return undefined;
    }

    checkPermissionDependencies(codes: string[], current: string): RolePermissionDepencendy | undefined {
        const permission: RolePermission | undefined = this.state.available.find(permission => permission.permissionCode === current)
        const depCodes: string[] = permission!.permissionDependenciesCodes.split(',');
        for(let j = 0; j < depCodes.length; j++) {
            const depCode: string = depCodes[j];
            if (depCode === current || this.props.prevPermissions.find(permission => permission.permissionCode === depCode)) {
                continue;
            } else if(codes.includes(depCode)) {
                const dependency = this.checkPermissionDependencies(codes, depCode);
                if(dependency) return dependency;
            } else {
                const dependency = this.props.allPermissions.find(permission => permission.permissionCode === depCode);
                return { permission: permission!, dependency: dependency! };
            }
        }
        return undefined;
    }

    checkIsDependency(codes: string[], current: string): RolePermissionDepencendy | undefined {
        const permission: RolePermission | undefined = this.props.prevPermissions.find(permission => {
            return permission.permissionCode !== current && !codes.includes(permission.permissionCode) && permission.permissionDependenciesCodes.includes(current);
        });
        if(permission) {
            const dependency = this.state.current.find(permission => permission.permissionCode === current);
            return { permission: permission!, dependency: dependency! };
        } else {
            return undefined;
        }
    }

    render() {
        const currentItems: JSX.Element[] = this.state.current.map((item: RolePermission) => {
            return <option key={item.permissionCode} value={item.permissionCode}>{item.permissionName}</option>
        });
        const availableItems: JSX.Element[] = this.state.available.map((item: RolePermission) => {
            return <option key={item.permissionCode} value={item.permissionCode}>{item.permissionName}</option>
        });

        return (
            <div className="form-group">
                <label className="col-sm-2 control-label" >{this.state.permissionType}:</label>
                <div className="col-md-3">
                    <select ref={this.currentRef} multiple={true} className="form-control" id="current" onChange={this.onSelectCurent} disabled={this.props.match.path.endsWith(ROLE_ID_PARAM) || this.props.match.params.roleId === AdminCodeRole}>
                        {currentItems}
                    </select>
                </div>
                <div className="col-md-2">
                    <button type="button" id="addModulo" className="btn btn-success btn-block" onClick={this.addPermissions} disabled={this.state.itemsAvailable.length === 0}>
                        <span className="glyphicon glyphicon-chevron-left"></span>{i18n.t('add')}
                    </button>
                    <button type="button" id="subModulo" className="btn btn-danger btn-block" onClick={this.removePermissions} disabled={this.state.itemsCurrent.length === 0}>
                        {i18n.t('remove')}<span className="glyphicon glyphicon-chevron-right"></span>
                    </button>
                </div>
                <div className="col-md-3">
                    <select ref={this.availableRef} multiple={true} className="form-control" id="available" onChange={this.onSelectAvailable} disabled={this.props.match.path.endsWith(ROLE_ID_PARAM) || this.props.match.params.roleId === AdminCodeRole}>
                        {availableItems}
                    </select>
                </div>
            </div>
        )
    }
}

export const PermissionGroup = withAppContext(PermissionGroupMain);