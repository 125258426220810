import { CreditCardType } from "../interfaces/CreditCard";
import { CURUS_CREDIT_CARD_GOLD, CURUS_CREDIT_CARD_PLATINUM, CURUS_CREDIT_CARD_DIAMOND } from "./Identity";

export const CREDIT_CARD_GOLD = 'gold';
export const CREDIT_CARD_PLATINUM = 'platinum';
export const CREDIT_CARD_DIAMOND = 'diamond';

export const CREDIT_CARD_TYPES: { [key: string]: CreditCardType } = {
    [CURUS_CREDIT_CARD_GOLD]: CREDIT_CARD_GOLD,
    [CURUS_CREDIT_CARD_PLATINUM]: CREDIT_CARD_PLATINUM,
    [CURUS_CREDIT_CARD_DIAMOND]: CREDIT_CARD_DIAMOND,
}
