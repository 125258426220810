import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../context/AppContext";
import { RouteDocumentParams } from "../../const/Routes";
import { DocumentContextProps, withAppDocumentContext } from "../../context/DocumentContext";
import { DOCUMENT_UPLOAD_PERM } from "../../const/Permission";
import { DocumentTemplate, DocumentTemplateVersion } from "../../interfaces/Document";
import { uploadDocument } from "../../services/Document";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { MESSAGE_SUCCESS } from "../../const/Message";

interface FormDocumentProps extends RouteComponentProps<RouteDocumentParams>, AppContextProps, DocumentContextProps { }

interface FormDocumentState {
    document: DocumentTemplate | null;
    version: DocumentTemplateVersion;
}

export class FormDocumentMain extends React.Component<FormDocumentProps, FormDocumentState>{
    constructor(props: FormDocumentProps) {
        super(props);
        this.handleVersName = this.handleVersName.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.submit = this.submit.bind(this);
        this.back = this.back.bind(this);
        
        this.state = {
            document: this.props.document,
            version: this.props.version ? Object.assign({}, this.props.version) : {
                versCorrelative: 0,
                versName: '',
                active: true,
                creationDate: new Date(),
                source: '',
            },
        }
    }

    componentDidMount() {
        if(this.state.document === null) this.back();
    }

    back() {
        this.props.history.goBack();
    }

    handleVersName(e: any) {
        const versName: string = e.target.value;
        this.setState(state => ({ ...state, version: { ...state.version!, versName } }));
    }

    handleActive(e: any) {
        const active: boolean = e.target.checked;
        this.setState(state => ({ ...state, version: { ...state.version!, active } }));
    }
    
    handleFile(e: any) {
        const files: FileList = e.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            const source: string = String(reader.result);
            this.setState(state => ({ ...state, version: { ...state.version, source }}));
        };
    }

    submit() {
        this.props.showLoading(true);
        uploadDocument(this.state.document!.docCode, this.state.version)
            .then(() => {
                this.props.setMessage({ message: i18n.t('document-version-' + (this.props.match.params.docId ? 'updated' : 'created')), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.back();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t(this.props.match.params.docId ? 'modify' : 'create')} {i18n.t('version').toLowerCase()}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal">
                            {this.state.document && <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('document')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" readOnly={true} name="docName" className="form-control" id="docName" defaultValue={this.state.document.docName} />
                                </div>
                            </div>}
                            <br />
                            {this.state.document && <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('device')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" readOnly={true} name="deviceType" className="form-control" id="deviceType" defaultValue={i18n.t(this.state.document.deviceType)} />
                                </div>
                            </div>}
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('version')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" name="versName" className="form-control" id="versName" value={this.state.version.versName} onChange={this.handleVersName} placeholder={i18n.t('description-enter')} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('active')}:</label>
                                <div className="col-sm-4">
                                    <div className="form-control">
                                        {this.props.match.params.docId && !this.props.version!.active && <input type="checkbox" name="docActive" id="docActive" onChange={this.handleActive} checked={this.state.version.active}></input>}
                                        {this.state.version.active ? i18n.t('yes') : i18n.t('no')}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('file')}:</label>
                                <div className="col-sm-4">
                                    <input type="file" accept="application/pdf" name="file" className="form-control" id="file" onChange={this.handleFile} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <div className="col-sm-offset-2 col-sm-10">
                                    <button type="button" onClick={this.back} className="btn btn-primary">{i18n.t('back')}</button>
                                    {this.props.match.params.docId && this.props.havePermission(DOCUMENT_UPLOAD_PERM) && <button type="button" onClick={this.submit} className="btn btn-info">{i18n.t('update')}</button>}
                                    {this.props.match.params.docId === undefined && this.props.havePermission(DOCUMENT_UPLOAD_PERM) && <button type="button" onClick={this.submit} className="btn btn-info">{i18n.t('create')}</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export const FormDocument = withAppDocumentContext(FormDocumentMain);