import * as React from "react";
import { MessageTypes } from "../const/Message";

export interface MessageModel {
    title?: string;
    message?: string;
    type?: MessageTypes;
    time?: number;
}

interface MessageProps {
    hide?: Function;
    show: boolean;
    message: MessageModel;
    position?: string;
    closable: boolean;
    working?: boolean;
}
interface MessageState {
    opacity: number;
    bottom: number;
    show: boolean;
}

export class Message extends React.Component<MessageProps, MessageState> {
    element: HTMLDivElement | null = null;
    timeout: any = null;

    constructor(props: MessageProps) {
        super(props);
        this.animate = this.animate.bind(this);
        this.animateMount = this.animateMount.bind(this);
        this.animateUnmount = this.animateUnmount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setMessageTimeout = this.setMessageTimeout.bind(this);

        this.state = {
            opacity: 0,
            bottom: 0,
            show: false,
        }
    }

    componentWillMount() {
        if (this.props.message.time !== undefined && this.props.message.time === 0) {
            this.setState({ show: true });
        }
    }

    setMessageTimeout() {
        clearTimeout(this.timeout);
        if (this.props.closable === false && (this.props.message.time === undefined || this.props.message.time > 0)) {
            this.timeout = setTimeout(this.props.hide!, this.props.message.time || 3000);
        }
    }

    animateMount() {
        if (this.props.position && this.props.position === 'fixed') {
            let bottom = -this.element!.clientHeight;
            const interval = setInterval(() => {
                bottom = bottom + 1;
                this.setState({ bottom })
                if (bottom === 0) {
                    clearInterval(interval);
                    this.setMessageTimeout();
                }
            }, 3);
        } else {
            const interval = setInterval(() => {
                const opacity = this.state.opacity + 0.05;
                this.setState({ opacity });
                if (opacity >= 1) {
                    clearInterval(interval);
                    this.setMessageTimeout();
                }
            }, 5);
        }
    }

    animateUnmount() {
        if (this.props.position && this.props.position === 'fixed') {
            let bottom = 0;
            const to = -this.element!.clientHeight;
            const interval = setInterval(() => {
                bottom = bottom - 1;
                this.setState({ bottom })
                if (bottom === to) {
                    clearInterval(interval);
                    this.setState({ show: false });
                }
            }, 3);
        } else {
            const interval = setInterval(() => {
                const opacity = this.state.opacity - 0.05;
                this.setState({ opacity });
                if (this.state.opacity <= 0) {
                    clearInterval(interval);
                    this.setState({ show: false });
                }
            }, 5);
        }
    }

    animate(element: HTMLDivElement | null) {
        if (element) {
            this.element = element;
            this.animateMount();
        }
    }

    componentDidUpdate(prevProps: MessageProps) {
        if (prevProps.message !== this.props.message) {
            this.setMessageTimeout();
        }

        if (this.props.show !== this.state.show && this.props.show !== prevProps.show) {
            if (this.props.show) {
                this.setState({ show: true });
            } else {
                this.animateUnmount();
            }
        }
    }

    handleClose() {
        this.props.hide!();
    }

    render() {
        return (
            <div>
                {this.state.show && <div className={"alert " + (this.props.position ? 'alert-' + this.props.position + ' ' : '') + this.props.message.type} ref={this.animate} style={{ bottom: this.state.bottom, opacity: this.state.opacity }}>    
                    {this.props.working && <div><i className="fa fa-2x fa-cog fa-spin"></i>&ensp;&ensp;</div>}
                    {this.props.closable && <button type="button" className="close" onClick={this.handleClose}>&times;</button>}
                    {this.props.message.message}
                </div>}
            </div>
        )
    }
}