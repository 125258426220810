import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import i18n from "../i18n";
import { MAIN_ROUTE } from "../const/Routes";

interface ForbiddenProps extends RouteComponentProps { }
interface ForbiddenState { }

export class ForbiddenMain extends React.Component<ForbiddenProps, ForbiddenState>{
    constructor(props: ForbiddenProps) {
        super(props);
        this.goHome = this.goHome.bind(this);
    }

    goHome() {
        this.props.history.replace(MAIN_ROUTE);
    }

    render() {
        return (
            <div className="forbidden">
                <div>
                    <h1>{i18n.t('forbidden')}</h1>
                    <h3>{i18n.t('forbidden-subtitle')}</h3>
                    <i className="fa fa-exclamation-triangle forbidden-icon"></i>
                </div>
                <button type="button"className="btn btn-info" onClick={this.goHome}>{i18n.t('go-home')}</button>
            </div>
        )
    }
}

export const Forbidden = withRouter(ForbiddenMain);