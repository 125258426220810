import { ReportOfficeStatus, SearchOfficeStatusReport } from "../interfaces/ReportOfficeStatus";
import { AxiosResponse } from "axios";
import { apiErrorCallback, helperAxios } from "../utils/ApiBaseConfig";

export async function findOfficeStatus(search: SearchOfficeStatusReport): Promise<ReportOfficeStatus> {
    return helperAxios({
        method: 'POST', url: '/api/v2/reports/stateoffices',
        data: {
            ...search
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findOfficeStatus');
            return response.data;
        })
        .catch(apiErrorCallback);
}