import * as React from "react";
import i18n from "../../i18n";
import { ExportCSVButton, BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { formatBranchOfficeForList, formatNameForList, noticeStatusFormatter, formatNoticeNameForList } from "../../utils/Formatter";
import { withAppContext, AppContextProps } from "../../context/AppContext";
import { Notice, SearchNotices, NoticeConfiguration } from "../../interfaces/Notice";
import { Region } from "../../interfaces/ReportOfficeStatus";
import { BranchOffice } from "../../interfaces/Office";
import { RouteComponentProps } from "react-router-dom";
import { getRegionList, getBranchOfficeList } from "../../services/Stock";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { MESSAGE_WARNING, MESSAGE_ERROR } from "../../const/Message";
import { findNotices, findNoticesConf } from "../../services/Notice";
import { NOTICE_CONFIGURATION_EDIT_PERM } from "../../const/Permission";
import { NOTICE_EDIT_ROUTE, NOTICE_CONF_ID_PARAM } from "../../const/Routes";

export interface NoticesConfTableProps extends RouteComponentProps, AppContextProps { }
export interface NoticesConfTableState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    showSelectRegionAndLocation: boolean;
    data: NoticeConfiguration[];
    selected: NoticeConfiguration | null;
    tableOptions: any;
}

export class NoticesConfTableMain extends React.Component<NoticesConfTableProps, NoticesConfTableState> {

    constructor(props: NoticesConfTableProps) {
        super(props);

        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.findNoticesConf = this.findNoticesConf.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.editConf = this.editConf.bind(this);

        this.state = {
            data: [],
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            showSelectRegionAndLocation: false,

            selected: null,

            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 5,
                paginationSize: 3,
                pageStartIndex: 1,
            }
        }
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
                this.findNoticesConf();
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.findNoticesConf);
            }
        }
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ office: officeSelected });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, this.props.showLoading(false));
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    findNoticesConf() {
        this.props.showLoading(true);

        if (this.state.region !== "" && this.state.office == "") {
            this.props.setMessage({ message: i18n.t('branch-office-select'), type: MESSAGE_WARNING });
            this.props.showLoading(false);
            return;
        }

        const search: SearchNotices = {
            firstResult: 0,
            maxResults: 300,
            reportDate: '',
            reportOffice: this.state.office
        };

        findNoticesConf(search)
            .then((response: NoticeConfiguration[]) => {
                this.setState({ data: response }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    onSelect(selected: NoticeConfiguration) {
        this.setState({ selected });
    }

    editConf(e: any) {
        e.preventDefault();
        if (this.state.selected === null) {
            this.props.setMessage({ message: i18n.t('option-select'), type: MESSAGE_ERROR });
        } else {
            this.props.history.push(NOTICE_EDIT_ROUTE
                .replace(NOTICE_CONF_ID_PARAM, "" + this.state.selected.correlative));
        }
    }

    render() {

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        return (
            <div className="card margin-card-top">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('notices-conf')}</div>
                    </div>
                </div>
                <div className="card-body">
                    {this.state.showSelectRegionAndLocation &&
                        <div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('region')}:</label>
                                <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                    <option value="NONE">{i18n.t('option-select')}</option>
                                    {regions}
                                </select>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('branch-office')}:</label>
                                <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                    <option value="NONE">{i18n.t('option-select')}</option>
                                    {branchOffices}
                                </select>
                            </div>
                        </div>
                    }
                    {!this.state.showSelectRegionAndLocation &&
                        <div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('region')}:</label>
                                <p className="form-control">{this.state.regionName}</p>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('branch-office')}:</label>
                                <p className="form-control">{this.state.officeName}</p>
                            </div>
                        </div>
                    }
                    <div className="form-group col-sm-3">
                        <br></br>
                        <button type="button" className="btn btn-info" onClick={this.findNoticesConf}>{i18n.t('search')}</button>
                    </div>
                    <div className="form-group col-sm-12">

                        {this.props.havePermission(NOTICE_CONFIGURATION_EDIT_PERM) && <button type="button" onClick={this.editConf} id="findButton" disabled={this.state.selected === null} className="btn btn-primary">{i18n.t('show')}</button>}

                        <BootstrapTable
                            data={this.state.data}
                            options={this.state.tableOptions}
                            bordered={true}
                            pagination hover
                            selectRow={{ mode: 'radio', onSelect: this.onSelect }} >

                            <TableHeaderColumn dataField='correlative' isKey hidden>{i18n.t('code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='noticeType' dataFormat={formatNoticeNameForList} dataSort={true} dataAlign={'left'}>{i18n.t('type')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='branchOffice' dataFormat={formatBranchOfficeForList} dataSort={true} dataAlign={'left'}>{i18n.t('branch-office')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='independentMails' dataAlign={'right'}>{i18n.t('mails')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='userMails' dataAlign={'right'}>{i18n.t('users')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='roleMails' dataAlign={'right'}>{i18n.t('role-user')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    }
}

export const NoticesConfList = withAppContext(NoticesConfTableMain);