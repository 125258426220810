import * as React from "react";
import i18n from "../../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { ShipmentGenerated, ShipmentGeneratedObject, OfficesByDeviceType, Office } from "../../../../interfaces/Shipment";
import { SHIPMENT_ASSIGN_PERM, STOCK_DEVICES_LOADED_PERM } from "../../../../const/Permission";
import { getShipmentsGenerated, assignShipments } from "../../../../services/Shipment";
import { ResponseError, apiHandleErrorCode } from "../../../../utils/ApiBaseConfig";
import { BranchOffice } from "../../../../interfaces/Office";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../../const/Message";
import Select from "react-select";
import { SelectOption } from "../../../../interfaces/Identity";
import { DeviceContextProps, withAppDeviceContext } from "../../../../context/DeviceContext";
import { cleanProperty } from "../../../../utils/Formatter";

export interface ShipmentsGeneratedProps extends RouteComponentProps, AppContextProps, DeviceContextProps {
    next: Function;
}

export interface ShipmentsGeneratedState {
    shipmentsGenerated: ShipmentGenerated[];
    officesByDeviceType: OfficesByDeviceType[];
    selected: ShipmentGenerated | null;
    offices: SelectOption[];
    tableOptions: any;
}

export class ShipmentsGeneratedMain extends React.Component<ShipmentsGeneratedProps, ShipmentsGeneratedState> {

    constructor(props: ShipmentsGeneratedProps) {
        super(props);
        this.shipmentEdit = this.shipmentEdit.bind(this);
        this.assign = this.assign.bind(this);
        this.cleanShipments = this.cleanShipments.bind(this);
        this.formatOfficeCode = this.formatOfficeCode.bind(this);
        this.beforeShipmentEdit = this.beforeShipmentEdit.bind(this);
        this.createSelectOptions = this.createSelectOptions.bind(this);
        this.state = {
            shipmentsGenerated: [],
            officesByDeviceType: [],
            selected: null,
            offices: this.props.branchOffices.map((branchOffice: BranchOffice) => {
                const code: string = cleanProperty(branchOffice.code);
                const name: string = cleanProperty(branchOffice.name);
                return { label: code + ' - ' + name, value: code };
            }),
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            }
        }
    }

    componentDidMount() {
        if (this.props.havePermission(STOCK_DEVICES_LOADED_PERM)) {
            this.props.showLoading(true);
            getShipmentsGenerated()
                .then((shipmentsGenerated: ShipmentGeneratedObject) => {
                    shipmentsGenerated.shipments = shipmentsGenerated.shipments.map((shipment: ShipmentGenerated) => ({ ...shipment, destinationCode: '' }));
                    this.setState({ shipmentsGenerated: shipmentsGenerated.shipments, officesByDeviceType: shipmentsGenerated.types }, () => this.props.showLoading(false));
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    shipmentEdit(row: any, cellName: any, cellValue: any, props: any) {
        const shipmentsGenerated: ShipmentGenerated[] = this.state.shipmentsGenerated.slice();
        if (cellName == 'destinationCode') {
            shipmentsGenerated[props.rowIndex].destinationCode = cellValue.value;
        } else {
            shipmentsGenerated[props.rowIndex].comment = cellValue;
        }
        this.setState({ shipmentsGenerated });
    }

    beforeShipmentEdit(row: any, cellName: any, cellValue: any, props: any): boolean {
        if (cellName == 'destinationCode') {
            const found: boolean = this.state.officesByDeviceType.some((deviceType: OfficesByDeviceType) => {
                if (deviceType.name === row.deviceType) {
                    return deviceType.offices.some((office: Office) => {
                        if (office.code === cellValue.value)
                            return true;
                        return false;
                    });
                }
                return false;
            });
            if (!found && cellValue.value)
                this.props.setMessage({ message: i18n.t('shipment-cannot-assing'), type: MESSAGE_WARNING });
            return found;
        }
        return true;
    }

    assign() {
        const defaultOffice: string = process.env.REACT_APP_DEFAULT_BRANCH_OFFICE!;
        const shipments: ShipmentGenerated[] = this.state.shipmentsGenerated.filter((shipment: ShipmentGenerated) => shipment.destinationCode && shipment.destinationCode.length > 0 && shipment.destinationCode !== defaultOffice);
        if (shipments.length === 0) {
            this.props.setMessage({ message: i18n.t('shipments-generate-set-data'), type: MESSAGE_WARNING });
            return;
        }

        this.props.showLoading(true);
        assignShipments(shipments)
            .then((generated: ShipmentGenerated[]) => {
                this.props.setMessage({ message: i18n.t('shipments-assigned'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.props.next(generated);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    cleanShipments() {
        const defaultOffice: string = process.env.REACT_APP_DEFAULT_BRANCH_OFFICE!;
        const shipmentsGenerated: ShipmentGenerated[] = this.state.shipmentsGenerated.map((shipment: ShipmentGenerated) => {
            return {
                ...shipment,
                destinationCode: defaultOffice,
                comment: '',
            }
        });
        this.setState({ shipmentsGenerated });
    }

    formatOfficeCode(code: string) {
        const office: BranchOffice | undefined = this.props.branchOffices.find((office: BranchOffice) => office.code === code);
        return office ? office.code + ' - ' + office.name : '';
    }

    createSelectOptions(onUpdate: any, props: any): any {
        var options: any = [];
        this.state.officesByDeviceType.forEach((deviceType: OfficesByDeviceType) => {
            if (deviceType.name === props.row.deviceType) {
                options = deviceType.offices.map((office: Office) => {
                    const code: string = cleanProperty(office.code);
                    const name: string = cleanProperty(office.name);
                    return { label: code + ' - ' + name, value: code };
                });
            }
        });
        return <Select options={options} onChange={onUpdate} placeholder={i18n.t('option-select')} {...props} />
    }

    render() {
        return (
            <div className="card overflow-card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('step')} 1: {i18n.t('shipments-generate')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <button className="btn btn-primary" onClick={this.cleanShipments}>{i18n.t('clean')}</button>
                    {this.props.havePermission(SHIPMENT_ASSIGN_PERM) && <button className="btn btn-info" onClick={this.assign}>{i18n.t('assign')}</button>}
                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading"></div>
                        <BootstrapTable
                            data={this.state.shipmentsGenerated}
                            bordered={true}
                            tableBodyClass="table-min-height"
                            cellEdit={{
                                mode: 'click',
                                blurToSave: true,
                                afterSaveCell: this.shipmentEdit,
                                beforeSaveCell: this.beforeShipmentEdit
                            }}
                            options={this.state.tableOptions}
                        >
                            <TableHeaderColumn dataField='boxCode' isKey={true} dataSort={true}>{i18n.t('box-code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceType' dataSort={true} editable={false} dataAlign={'center'}>{i18n.t('device-type')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='initialSerialNumber' hidden dataSort={true} editable={false} dataAlign={'center'}>{i18n.t('serial-number-initial')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='finalSerialNumber' hidden dataSort={true} editable={false} dataAlign={'center'}>{i18n.t('serial-number-final')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceQuantity' dataSort={true} editable={false} dataAlign={'center'}>{i18n.t('devices-quantity')}</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='destinationCode'
                                editColumnClassName='td-dropdown'
                                customEditor={{
                                    getElement: (onUpdate, props) => this.createSelectOptions(onUpdate, props)
                                }}
                                dataFormat={this.formatOfficeCode}>{i18n.t('destination')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='comment' dataSort={true}>{i18n.t('observation')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    }
}

export const ShipmentsGenerated = withAppDeviceContext(ShipmentsGeneratedMain);