import { SystemParameter, SystemParameterAPI, License, LicenseAPI } from "../interfaces/System";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import i18n from "../i18n";
import { LICENSE_TYPES } from "../const/System";
import { API_PREFIX } from "../const/Routes";

export function getSystemWorkStatus(): Promise<SystemParameter> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/system/parameters/bussy' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getSystemWorkStatus");
            return parseSystemParameter(response.data);
        })
        .catch(apiErrorCallback);
}

export function getSystemParameters(): Promise<SystemParameter[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/system/parameters' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getSystemParameters");
            return response.data.map(parseSystemParameter);
        })
        .catch(apiErrorCallback);
}

export function syncSystemParameters(): Promise<boolean> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/system/parameters/sync' })
        .then((response: AxiosResponse) => {
            console.log("[OK] syncSystemParameters");
            return true;
        })
        .catch(apiErrorCallback);
}

export function getLicenses(): Promise<License[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/licenses/' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getLicenses");
            return response.data.map(parseLicense);
        })
        .catch(apiErrorCallback);
}

export function isIntelliTrustEnabled(): Promise<boolean> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/system/parameters/intellitrust' })
        .then((response: AxiosResponse) => {
            console.log("[OK] isIntelliTrustEnabled");
            return response.data;
        })
        .catch(apiErrorCallback);
}

function parseSystemParameter(parameter: SystemParameterAPI): SystemParameter {
    return {
        key: parameter.name,
        value: parameter.value,
        description: parameter.description,
        updateDate: parameter.updateDate ? new Date(parameter.updateDate) : i18n.t('no-data'),
    }
}

function parseLicense(license: LicenseAPI): License {
    return {
        type: LICENSE_TYPES[license.type],
        maximumNumber: license.maximumNumber,
        currentNumber: license.currentNumber,
        expiryDate: license.expiryDate ? new Date(license.expiryDate) : i18n.t('no-expire'),
    }
}
