import { SoftTokenActivationState } from "../interfaces/SoftToken";

export const SOFTTOKEN_ACTIVE = 'active';
export const SOFTTOKEN_CREATED = 'created';
export const SOFTTOKEN_DELETED = 'deleted';
export const SOFTTOKEN_IDLE = 'idle';
export const SOFTTOKEN_IN_ACTIVATION = 'in-activation';
export const SOFTTOKEN_LOCKED = 'locked';
export const SOFTTOKEN_PENDING = 'pending';

export const SOFTTOKEN_STATES: { [activation: string]: SoftTokenActivationState } = {
    "ACTIVATED_ACTIVE": SOFTTOKEN_ACTIVE,
    "ACTIVATED_DELETED": SOFTTOKEN_DELETED,
    "ACTIVATED_IN_ACTIVATION": SOFTTOKEN_PENDING,
    "ACTIVATED_LOCKED": SOFTTOKEN_LOCKED,
    "ACTIVATION_REQUESTED_DELETED": SOFTTOKEN_DELETED,
    "ACTIVATION_REQUESTED_LOCKED": SOFTTOKEN_LOCKED,
    "ACTIVATION_REQUESTED_PENDING": SOFTTOKEN_IN_ACTIVATION,
    "CREATED_ACTIVE": SOFTTOKEN_PENDING,
    "CREATED_DELETED": SOFTTOKEN_DELETED,
    "CREATED_LOCKED": SOFTTOKEN_LOCKED,
    "CREATED_PENDING": SOFTTOKEN_CREATED,
};
