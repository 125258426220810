import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

interface ToggleLinkProps extends LinkProps {
    disabled?: boolean;
}
interface ToggleLinkState { }

export class ToggleLink extends React.Component<ToggleLinkProps, ToggleLinkState> {
    constructor(props: ToggleLinkProps) {
        super(props);
    }

    render() {
        if (this.props.disabled) {
            return <button className={this.props.className} disabled>{this.props.children}</button>
        }
        return <Link {...this.props}>{this.props.children}</Link>
    }
}