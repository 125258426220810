import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../../context/AppContext";
import { withAppDeviceContext, DeviceContextProps } from "../../../context/DeviceContext";
import { loadStock } from "../../../services/Stock";
import { apiHandleErrorCode, ResponseError } from "../../../utils/ApiBaseConfig";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import { SystemParameter } from "../../../interfaces/System";
import { getSystemWorkStatus } from "../../../services/System";
import { Message } from "../../../utils/Message";
import { ShipmentEventsTable } from "../shipment/event/List";
import { ShipmentEvent } from "../../../interfaces/Event";
import { getStockLoadEvents } from "../../../services/Event";
import { IDENTITY_CONFIG } from "../../../const/Identity";
import { StockDeviceUpload } from "../../../interfaces/Stock";
import { getDeviceTypeList } from "../../../services/DeviceType";
import { DeviceType } from "../../../interfaces/DeviceType";

export interface StockLoadProps extends RouteComponentProps, AppContextProps, DeviceContextProps { }

export interface StockLoadState {
    source: string;
    status: SystemParameter | null;
    events: ShipmentEvent[];
    deviceTypes: DeviceType[];
    deviceTypeId: string;
    batchProviderId: string;
    stockDesc: string;
    stockDeviceUpload: StockDeviceUpload | null;
    showLoadStock: boolean;
}

export class StockLoadMain extends React.Component<StockLoadProps, StockLoadState> {
    check: boolean = true;

    constructor(props: StockLoadProps) {
        super(props);
        this.fetchStockLoadEvents = this.fetchStockLoadEvents.bind(this);
        this.fetchSystemWorkStatus = this.fetchSystemWorkStatus.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.handleBatchProviderId = this.handleBatchProviderId.bind(this);
        this.send = this.send.bind(this);

        this.state = {
            source: '',
            status: null,
            events: [],
            deviceTypes: [],
            deviceTypeId: "",
            batchProviderId: "",
            stockDesc: "",
            stockDeviceUpload: null,
            showLoadStock: false
        };
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showLoadStock: true });
            }
        }

        this.fetchSystemWorkStatus();
        this.fetchStockLoadEvents();
        this.getDeviceTypesFromAPI();
    }

    componentWillUnmount() {
        this.check = false;
    }

    handleStock(e: any) {
        e.preventDefault();
        const index = e.target.selectedIndex;
        this.setState({ deviceTypeId: e.target.value, stockDesc: e.target[index].text });
    }


    fetchStockLoadEvents() {
        this.props.showLoading(true);
        getStockLoadEvents()
            .then((events: ShipmentEvent[]) => {
                this.setState({ events }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    fetchSystemWorkStatus() {
        getSystemWorkStatus()
            .then((status: SystemParameter) => {
                this.setState({ status });
                if (this.check = (status.value === 'true'))
                    setTimeout(this.fetchSystemWorkStatus, 3000);
                else
                    this.fetchStockLoadEvents();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getDeviceTypesFromAPI(): void {
        getDeviceTypeList().then((deviceTypeArray: DeviceType[]) => {
            this.setState({ deviceTypes: deviceTypeArray });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    handleFile(e: any) {
        const files: FileList = e.target.files;
        if (files.length === 0) {
            this.setState({ source: '' });
            return;
        };
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            const base64: string = String(reader.result).split(';')[1];
            const source: string = 'data:text/csv;' + base64;
            this.setState(state => ({ ...state, source }));
        };
    }

    handleBatchProviderId(e: any): void {
        e.preventDefault();
        const batchProviderId = e.target.value;
        this.setState({ batchProviderId });
    }

    send() {
        this.props.showLoading(true);
        getSystemWorkStatus()
            .then((status: SystemParameter) => {
                this.props.showLoading(false);
                this.setState({ status });
                if (status.value === 'false') {
                    this.props.showLoading(true);

                    this.setState({
                        stockDeviceUpload:
                        {
                            documentSource: { source: this.state.source },
                            deviceType: { code: this.state.deviceTypeId },
                            providerId: this.state.batchProviderId
                        }
                    });


                    loadStock(this.state.stockDeviceUpload!)
                        .then(() => {
                            this.props.setMessage({ message: i18n.t('stock-load-in-progress'), type: MESSAGE_SUCCESS, time: 5000 });
                            this.setState(state => ({ ...state, status: { ...(state.status || { key: 'workInProgress', description: '', updateDate: new Date() }), value: 'true' } }));
                            this.check = true;
                            this.fetchSystemWorkStatus();
                            this.props.showLoading(false);
                        })
                        .catch((response: ResponseError) => {
                            apiHandleErrorCode(response.status, this.props.history);
                            this.props.setMessage(response.message);
                            this.props.showLoading(false);
                        });
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {
        const csvColumns: JSX.Element[] = IDENTITY_CONFIG.stock.file.fields.map((column: any) => {
            return <li style={{ marginBottom: "4px" }}>
                <span key={column.field} className="label label-info">{column.field}</span> : {column.description}
            </li>
        });

        const STOCK: { [key: string]: string } = this.state.deviceTypes.reduce((map: { [key: string]: string }, deviceType: DeviceType) => {
            map[deviceType.codeDeviceType] = deviceType.nameDeviceType;
            return map;
        }, {});

        const stocks: JSX.Element[] = Object.keys(STOCK).map((codeStock: string) => {
            return <option key={codeStock} value={codeStock}>{i18n.t(STOCK[codeStock])}</option>;
        });

        return (
            <div>
                {this.state.showLoadStock && <div className="card" >
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('stock-load')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.status === null && <Message closable={false} show={true} message={{ message: i18n.t('system-bussy-checking'), type: MESSAGE_WARNING, time: 0 }} />}
                        <form className="form-horizontal">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul>
                                        <li>
                                            {i18n.t('stock-load-instruction-1')}:
                                            <ol>{csvColumns}</ol>
                                        </li>
                                        <li>{i18n.t('stock-load-instruction-2')}</li>
                                        <li>{i18n.t('stock-load-instruction-3')}</li>
                                        <li>{i18n.t('stock-load-instruction-4')}</li>
                                    </ul>
                                </div>
                            </div>
                            {this.state.status && this.state.status.value === 'true' &&
                                <Message closable={false} show={true} working={true} message={{
                                    message: i18n.t('system-bussy', {
                                        process: this.state.status!.description,
                                        date: this.state.status!.updateDate.toString()
                                    }), type: MESSAGE_WARNING, time: 0
                                }} />}
                            {this.state.status && this.state.status.value === 'false' && <div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">{i18n.t('file-select')}: </label>
                                    <div className="col-sm-5">
                                        <input accept=".csv" type="file" className="form-control-file" onChange={this.handleFile} />
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <div className="col-sm-offset-1 col-sm-3">
                                        <label>{i18n.t('device-type')}:</label>
                                        <select className="form-control" onChange={this.handleStock} value={this.state.deviceTypeId}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {stocks}
                                        </select>
                                    </div>
                                    <div className="col-sm-offset-1 col-sm-3">
                                        <label>{i18n.t('batch-provider-id')}:</label>
                                        <input type="text" name="providerId" className="form-control" id="providerId" autoComplete="off" value={this.state.batchProviderId} onChange={this.handleBatchProviderId} />
                                    </div>
                                    <div className="col-sm-offset-1 col-sm-3"><br></br>
                                        <button type="button" className="btn btn-info" onClick={this.send}
                                            disabled={this.state.source.length === 0 || this.state.batchProviderId === "" || this.state.deviceTypeId === "" || this.state.deviceTypeId === "NONE"}>
                                            {i18n.t('upload')}
                                        </button>
                                    </div>
                                </div>
                            </div>}
                        </form>
                    </div>
                </div>}
                <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('recent-activity')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ShipmentEventsTable events={this.state.events} />
                    </div>
                </div>
            </div>
        )
    }
}

export const StockLoad = withAppDeviceContext(StockLoadMain);