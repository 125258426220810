import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import {
    FORBIDDEN_ROUTE, DEVICES_STOCK_ROUTE, DEVICES_SHIPPING_ROUTE, DEVICES_SHIPPING_INFO_ROUTE, DEVICES_EVENTS_ROUTE, DEVICES_ROUTE,
    DEVICES_SHIPPING_ASSIGN_ROUTE, DEVICES_FIND_DEVICE_ROUTE, DEVICES_STOCK_LOAD_ROUTE, DEVICES_SHIPPING_RECEIVE_ROUTE, DEVICES_SHIPPING_GENERATE_ROUTE,
    DEVICES_BALANCE_MOVEMENT_ROUTE, DEVICES_BALANCE_STOCKTAKING_ROUTE, DEVICES_TYPE_DEVICE_ROUTE, DEVICES_TYPE_ASSIGN_ROUTE, DEVICES_TYPE_DEVICE_CREATE_ROUTE, DEVICES_TYPE_EDIT_ROUTE
} from "../../const/Routes";
import { StockSummaryList } from "../../components/devices/stock/List";
import { MainMenu } from "../../components/MainMenu";
import { DEVICE_MANAGE_MENU_ITEMS } from "../../const/Menu";
import { FindDevice } from "../../components/devices/Find";
import { ShipmentEvents } from "../../components/devices/shipment/Events";
import { StockLoad } from "../../components/devices/stock/Load";
import { DeviceContextProvider, DeviceContextProps } from "../../context/DeviceContext";
import { DEVICE_TYPES } from "../../const/Device";
import { Shipments } from "../../components/devices/shipment/List";
import { ShipmentAssign } from "../../components/devices/shipment/Assign";
import { ShipmentInfo } from "../../components/devices/shipment/Info";
import { FormShipment } from "../../components/devices/shipment/Form";
import { getOfficesList } from "../../services/Office";
import { BranchOffice } from "../../interfaces/Office";
import { cleanProperty } from "../../utils/Formatter";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { MovementDevice } from "../../components/devices/movement/Movement";
import { DeviceTypeList } from "../../components/devices/type/List";
import { BalanceDevice } from "../../components/devices/balance/Balance";
import { DeviceTypeAssign } from "../../components/devices/type/Assign";
import { DeviceTypeForm } from "../../components/devices/type/Form";
import { STOCK_DEVICE_LOAD_SECTION_ACCESS, STOCK_DEVICE_STOCK_SECTION_ACCESS, STOCK_DEVICE_SHIPMENT_SECTION_ACCESS, STOCK_DEVICE_ASSIGN_SECTION_ACCESS, STOCK_DEVICE_EVENT_SECTION_ACCESS, STOCK_DEVICE_MOVEMENT_SECTION_ACCESS, STOCK_DEVICE_BALANCE_SECTION_ACCESS } from "../../const/Permission";


interface DeviceContainerProps extends RouteComponentProps, AppContextProps { }
interface DeviceContainerState {
    deviceContext: DeviceContextProps;
}

export class DeviceContainerMain extends React.Component<DeviceContainerProps, DeviceContainerState> {
    constructor(props: DeviceContainerProps) {
        super(props);
        this.setDeviceType = this.setDeviceType.bind(this);

        this.state = {
            deviceContext: {
                deviceType: DEVICE_TYPES[process.env.REACT_APP_DEFAULT_DEVICE_TYPE!],
                branchOffices: [],
                setDeviceType: this.setDeviceType,
            }
        }
    }

    componentWillMount() {
        this.props.showLoading(true);
        getOfficesList()
            .then((branchOffices: BranchOffice[]) => {
                const notInclude: string[] = [this.props.authUser!.branchOffice!.code, process.env.REACT_APP_DEFAULT_BRANCH_OFFICE!];
                branchOffices = branchOffices.filter((office: BranchOffice) => !notInclude.includes(cleanProperty(office.code)));
                this.setState(state => ({ ...state, deviceContext: { ...state.deviceContext, branchOffices } }));
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    setDeviceType(deviceType: string) {
        this.setState(state => ({ ...state, deviceContext: { ...state.deviceContext, deviceType } }));
    }

    render() {
        return (
            <DeviceContextProvider value={this.state.deviceContext}>
                <Switch>
                    {this.props.havePermission(STOCK_DEVICE_LOAD_SECTION_ACCESS) && <Route path={DEVICES_STOCK_LOAD_ROUTE} exact component={StockLoad} />}
                    {this.props.havePermission(STOCK_DEVICE_STOCK_SECTION_ACCESS) && <Route path={DEVICES_STOCK_ROUTE} exact component={StockSummaryList} />}
                    {this.props.havePermission(STOCK_DEVICE_SHIPMENT_SECTION_ACCESS) && <Route path={DEVICES_SHIPPING_ROUTE} exact component={Shipments} />}
                    {this.props.havePermission(STOCK_DEVICE_ASSIGN_SECTION_ACCESS) && <Route path={DEVICES_SHIPPING_GENERATE_ROUTE} exact component={FormShipment} />}
                    {this.props.havePermission(STOCK_DEVICE_ASSIGN_SECTION_ACCESS) && <Route path={DEVICES_SHIPPING_ASSIGN_ROUTE} exact component={ShipmentAssign} />}
                    {this.props.havePermission(STOCK_DEVICE_ASSIGN_SECTION_ACCESS) && <Route path={DEVICES_SHIPPING_RECEIVE_ROUTE} exact component={ShipmentInfo} />}
                    {this.props.havePermission(STOCK_DEVICE_ASSIGN_SECTION_ACCESS) && <Route path={DEVICES_SHIPPING_INFO_ROUTE} exact component={ShipmentInfo} />}
                    {this.props.havePermission(STOCK_DEVICE_EVENT_SECTION_ACCESS) && <Route path={DEVICES_EVENTS_ROUTE} exact component={ShipmentEvents} />}
                    {this.props.havePermission(STOCK_DEVICE_MOVEMENT_SECTION_ACCESS) && <Route path={DEVICES_BALANCE_MOVEMENT_ROUTE} exact component={MovementDevice} />}
                    {this.props.havePermission(STOCK_DEVICE_BALANCE_SECTION_ACCESS) && <Route path={DEVICES_BALANCE_STOCKTAKING_ROUTE} exact component={BalanceDevice} />}
                    <Route path={DEVICES_ROUTE} render={() => <MainMenu menuItems={DEVICE_MANAGE_MENU_ITEMS} />} />
                    <Redirect to={FORBIDDEN_ROUTE} />
                </Switch>
            </DeviceContextProvider>
        )
    }

}
export const DeviceContainer = withAppContext(DeviceContainerMain);