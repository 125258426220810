import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { Shipment, ShipmentState, ShipmentDetail } from "../../../interfaces/Shipment";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { getShipment, receiveShipment, printShipmentVoucher } from "../../../services/Shipment";
import { RouteShipmentParams, DEVICES_SHIPPING_RECEIVE_ROUTE, DEVICES_SHIPPING_ROUTE } from "../../../const/Routes";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { shipmentStateFormatter, getTimestamp } from "../../../utils/Formatter";
import { SHIPMENT_STATE_RECEIVED, SHIPMENT_STATE_SENT } from "../../../const/Shipment";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import { SHIPMENT_RECEIVE_PERM, SHIPMENT_EVENTS_PERM, SHIPMENT_SUMMARY_PRINT_PERM } from "../../../const/Permission";
import { ShipmentEventsTable } from "./event/List";
import { ShipmentEvent } from "../../../interfaces/Event";
import { getShipmentEvents } from "../../../services/Event";
import { validateRange } from "../../../utils/Validator";
import { DocumentSource } from "../../../interfaces/Document";
import { Movement } from "../../../interfaces/Movement";
import { sendMovementListCW } from "../../../services/Movement";

export interface ShipmentInfoProps extends RouteComponentProps<RouteShipmentParams>, AppContextProps { }
export interface ShipmentInfoState {
    shipment: Shipment | null;
    events: ShipmentEvent[];
    tableOptions: any;
}

export class ShipmentInfoMain extends React.Component<ShipmentInfoProps, ShipmentInfoState>{
    constructor(props: ShipmentInfoProps) {
        super(props);
        this.back = this.back.bind(this);
        this.fetchShipment = this.fetchShipment.bind(this);
        this.fetchShipmentEvents = this.fetchShipmentEvents.bind(this);
        this.showDefectiveDevices = this.showDefectiveDevices.bind(this);
        this.receive = this.receive.bind(this);
        this.printDetails = this.printDetails.bind(this);
        this.csvFileName = this.csvFileName.bind(this);

        this.state = {
            shipment: null,
            events: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('shipment-summary-export')} btnContextual='btn-info' onClick={onClick} /> }
            },
        }
    }
    
    componentDidMount() {
        if (this.props.match.params.shipmentId) {
            this.fetchShipment();
            this.fetchShipmentEvents();
            return;
        }

        this.back();
    }

    back() {
        this.props.history.goBack();
    }

    fetchShipment() {
        this.props.showLoading(true);
        getShipment(Number(this.props.match.params.shipmentId))
            .then((shipment: Shipment) => {
                if (this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE && (shipment.shipmentState !== SHIPMENT_STATE_SENT || 
                    this.props.authUser!.branchOffice!.code !== shipment.destinationCode)) {
                        
                    this.props.history.replace(DEVICES_SHIPPING_ROUTE);
                    this.props.showLoading(false);
                    return;
                }

                this.setState({ shipment }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    fetchShipmentEvents() {

        if (!this.props.havePermission(SHIPMENT_EVENTS_PERM)) return;

        this.props.showLoading(true);
        getShipmentEvents(Number(this.props.match.params.shipmentId))
            .then((events: ShipmentEvent[]) => {
                this.setState({ events }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    showDefectiveDevices() {
        const state: ShipmentState = this.state.shipment!.shipmentState;
        return this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE && (state === SHIPMENT_STATE_RECEIVED || state === SHIPMENT_STATE_SENT);
    }

    receive() {
        const shipment: Shipment = Object.assign({}, this.state.shipment);
        var total: number = 0;
        for (let i = 0; i < shipment.details.length; i++) {
            const detail: ShipmentDetail = shipment.details[i];
            const range: string = detail.comment;
            const initial: number = +detail.initialSerialNumber;
            const final: number = +detail.finalSerialNumber;
            console.log("deviceQuantity: " + detail.deviceQuantity);
            total+= detail.deviceQuantity;
            console.log("total: " + total);
            if (!validateRange(range, initial, final)) {
                this.props.setMessage({ message: i18n.t('devices-defective-invalid-range', { range, initial, final }), type: MESSAGE_WARNING });
                return;
            }
        }

        delete shipment['shipmentState'];
        delete shipment['receptionDate'];
        
        this.props.showLoading(true);
        receiveShipment(shipment)
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-received'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.back();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    printDetails() {
        this.props.showLoading(true);
        printShipmentVoucher([this.state.shipment!])
            .then((document: DocumentSource) => {
                this.props.showLoading(false);
                if (window.navigator.msSaveOrOpenBlob) {
                    const userId = this.props.authUser!.group + '/' + this.props.authUser!.username;
                    const fileName: string = 'ENVIO_' + this.state.shipment!.originCode + '_' + this.state.shipment!.destinationCode + '_' + getTimestamp() + '.pdf';
                    const url = window.API_URL + '/api/v2/admin/documents/legacy?userId=' + userId + '&fileName=' + fileName;
                    window.open(encodeURI(url));
                } else {
                    const url = window.URL.createObjectURL(document.blob);
                    window.open(url);
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    csvFileName() {
        return 'RESUMEN_ENVIO_' + this.state.shipment!.originCode + '_' + this.state.shipment!.destinationCode + '_' + getTimestamp() + '.csv';
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('shipment-info')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.shipment && <table className="table table-custom">
                            <tbody className="data">
                                <tr>
                                    <th className="col-lg-3">{i18n.t('code')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.shipmentCorrelative}</td>
                                    <th className="col-lg-3"> {i18n.t('status')}:</th>
                                    <td className="col-lg-3">{shipmentStateFormatter(this.state.shipment.shipmentState)}</td>
                                </tr>
                                <tr>
                                    <th className="col-lg-3"> {i18n.t('origin')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.originCode}</td>
                                    <th className="col-lg-3"> {i18n.t('date-send')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.sendDate.toString()}</td>
                                </tr>
                                <tr>
                                    <th className="col-lg-3">{i18n.t('destination')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.destinationCode}</td>
                                    <th className="col-lg-3"> {i18n.t('date-reception')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.receptionDate.toString()}</td>
                                </tr>
                                <tr>
                                    <th className="col-lg-3"> {i18n.t('observation')}:</th>
                                    <td colSpan={3}>{this.state.shipment.comment}</td>
                                </tr>
                            </tbody>
                        </table>}
                    </div>
                </div>
                {this.state.shipment && <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('details')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <BootstrapTable
                            data={this.state.shipment.details}
                            bordered={true}
                            cellEdit={this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE ? {
                                mode: 'click',
                                blurToSave: true,
                            } : { mode: 'none' }}
                            options={this.state.tableOptions}
                            exportCSV csvFileName={this.csvFileName()}>
                            <TableHeaderColumn dataField='boxCode' isKey={true} dataSort={true} width="150">{i18n.t('box-code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceType' dataSort={true} editable={false} dataAlign={'center'} width="150">{i18n.t('device-type')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceQuantity' dataSort={true} editable={false} dataAlign={'center'} width="200">{i18n.t('devices-quantity')}</TableHeaderColumn>
                        </BootstrapTable>
                        <br />
                        {/*this.props.havePermission(SHIPMENT_SUMMARY_PRINT_PERM) && <button type="button" onClick={this.printDetails} className="btn btn-primary">{i18n.t('print-detail')}</button>*/}
                    </div>
                </div>}
                {this.props.havePermission(SHIPMENT_EVENTS_PERM) && <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('recent-activity')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ShipmentEventsTable events={this.state.events} />
                    </div>
                </div>}
                <div className="card margin-card-top">
                    <div className="card-body">
                        <button type="button" onClick={this.back} className="btn btn-info">{i18n.t('back')}</button>
                        {this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE && this.props.havePermission(SHIPMENT_RECEIVE_PERM) && <button type="button" onClick={this.receive} className="btn btn-info">{i18n.t('receive')}</button>}
                    </div>
                </div>
            </div>
        )
    }
}

export const ShipmentInfo = withAppContext(ShipmentInfoMain);