import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../../context/AppContext";
import i18n from "../../../i18n";
import { formatUserId } from "../../../utils/Formatter";
import { RouteUserParams } from "../../../const/Routes";
import { UserContextProps, withAppUserContext } from "../../../context/UserContext";
import { updatePassword } from "../../../services/User";
import { MESSAGE_SUCCESS, MESSAGE_ERROR } from "../../../const/Message";
import Modal from 'react-modal';

export interface UserSelfProfileProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps { }
export interface UserSelfProfileState {
    userId: string,
    userOldPwd: string,
    userPwd: string;
    userPwdRepeat: string;
    modalIsOpen: boolean;
    showError: boolean;
    textError: string;
}

export class UserSelfProfileMain extends React.Component<UserSelfProfileProps, UserSelfProfileState>{
    subtitle: HTMLHeadingElement | null | undefined;
    constructor(props: UserSelfProfileProps) {
        super(props);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.sendDataAndCloseModal = this.sendDataAndCloseModal.bind(this);
        this.showError = this.showError.bind(this);
        this.handleUserPwdRepeat = this.handleUserPwdRepeat.bind(this);
        this.handleUserPwd = this.handleUserPwd.bind(this);
        this.handleUserOldPwd = this.handleUserOldPwd.bind(this);

        this.state = {
            userId: this.props.authUser != null ? this.props.authUser.username : "",
            userOldPwd: "",
            userPwd: "",
            userPwdRepeat: "",
            modalIsOpen: false,
            showError: false,
            textError: ""
        }
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    handleUserOldPwd(e: any) {
        e.preventDefault();
        const userOldPwd = e.target.value;
        this.setState({ userOldPwd });
    }

    handleUserPwd(e: any) {
        e.preventDefault();
        const userPwd = e.target.value;
        this.setState({ userPwd });
    }

    handleUserPwdRepeat(e: any) {
        e.preventDefault();
        const userPwdRepeat = e.target.value;
        this.setState({ userPwdRepeat });
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        if (this.subtitle)
            this.subtitle.style.color = '#f00';
    }

    showError(text: string) {
        this.setState({ showError: true, textError: text }, () => setTimeout(() => {
            this.setState({ showError: false, textError: '' });
        }, 2000));
    }

    sendDataAndCloseModal() {
        if (this.state.userPwd === "" || this.state.userPwdRepeat === "" || this.state.userOldPwd === "") {
            this.showError(i18n.t('password-update-text-required'));
        }
        else {
            if (this.state.userPwd !== this.state.userPwdRepeat) {
                this.showError(i18n.t('password-update-text-notmatch'));
            } else {
                this.props.showLoading(true);
                this.setState({ modalIsOpen: false });
                updatePassword(this.state.userId, this.state.userOldPwd, this.state.userPwd)
                    .then(() => {
                        this.props.showLoading(false);
                        this.props.setMessage({ message: i18n.t('password-updated'), type: MESSAGE_SUCCESS });
                    })
                    .catch(() => {
                        this.props.showLoading(false);
                        this.props.setMessage({ message: i18n.t('password-updated-failed'), type: MESSAGE_ERROR });
                    });
            }
        }
    }

    closeModal() {
        this.setState({ userPwd: '', userPwdRepeat: '', modalIsOpen: false });
    }

    render() {

        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        return (
            <div>
                {this.props.authUser && <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('user-data')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.props.authUser &&
                            <div className="">
                                <div className=""></div>
                                <table className="table table-custom">
                                    <tbody className="data">
                                        <tr>
                                            <th className="col-lg-3">{i18n.t('id')}:</th>
                                            <td colSpan={3}>{formatUserId(this.props.authUser.username, this.props.authUser.group)}</td>
                                        </tr>
                                        <tr>
                                            <th className="col-lg-3"> {i18n.t('name')}:</th>
                                            <td colSpan={3}>{this.props.authUser.fullName}</td>
                                        </tr>
                                        {this.props.authUser.role && <tr>
                                            <th className="col-lg-3">{i18n.t('role')}:</th>
                                            <td colSpan={3}>{this.props.authUser.role.roleName}</td>
                                        </tr>}
                                        {this.props.authUser.branchOffice && <tr>
                                            <th className="col-lg-3">{i18n.t('branch-office')}:</th>
                                            <td colSpan={3}>{this.props.authUser.branchOffice.name}</td>
                                        </tr>}
                                        {this.props.authUser.branchOffice && this.props.authUser.branchOffice.region && <tr>
                                            <th className="col-lg-3">{i18n.t('region')}:</th>
                                            <td colSpan={3}>{this.props.authUser.branchOffice.region.regionName}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>}
                        <button className="btn btn-info" onClick={this.openModal}>{i18n.t('password-update')}</button>
                    </div>
                </div>}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel=""
                >
                    <h2 ref={subtitle => this.subtitle = subtitle}>{i18n.t('password-update')}</h2>
                    <div className="card-body">
                        <label>{i18n.t('password-update-text')}</label>
                    </div>
                    <div className="form-group">
                        <div className="form-group">
                            <label className="control-label">{i18n.t('password-current')}:</label>
                            <input autoComplete="new-password" type="password" className="form-control" onChange={this.handleUserOldPwd}></input>
                        </div>
                        <div className="form-group">
                            <label className="control-label">{i18n.t('password-new')}:</label>
                            <input autoComplete="new-password" type="password" className="form-control" onChange={this.handleUserPwd}></input>
                        </div>
                        <div className="form-group">
                            <label className="control-label">{i18n.t('password-new-repeat')}:</label>
                            <input autoComplete="new-password" type="password" className="form-control" onChange={this.handleUserPwdRepeat}></input>
                        </div>
                        <div className={this.state.showError ? "alert alert-danger" : "alert alert-danger hidden"}>
                            <label>{this.state.textError}</label>
                        </div>
                        <button className="btn btn-info" onClick={this.sendDataAndCloseModal}>{i18n.t('update')}</button>
                        <button className="btn btn-default" onClick={this.closeModal}>{i18n.t('close')}</button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export const UserSelfProfile = withAppUserContext(UserSelfProfileMain);