import { CardActivationState } from "../interfaces/GridCard";
import { STATE_CURRENT, STATE_PENDING, STATE_HOLD, STATE_CANCELED,STATE_CANCELED_2, STATE_HOLD_PENDING } from "./Identity";

export const CARD_LOCKED = 'hold';
export const CARD_PENDING = 'pending';
export const CARD_ACTIVE = 'active';
export const CARD_CANCELED = 'canceled';
export const CARD_IDLE = 'idle';

export const CARD_STATES: { [key: string]: CardActivationState } = {
    [STATE_CURRENT]: CARD_ACTIVE,
    [STATE_PENDING]: CARD_PENDING,
    [STATE_HOLD]: CARD_LOCKED,
    [STATE_HOLD_PENDING]: CARD_IDLE,
    [STATE_CANCELED]: CARD_CANCELED,
    [STATE_CANCELED_2]: CARD_CANCELED,
};
