import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { NOTICE_LIST_PERM, NOTICE_CONFIGURATION_PERM } from "../../const/Permission";
import { NoticesList } from "./List";
import { NoticesConfList } from "./Conf";


export interface NoticeProps extends RouteComponentProps, AppContextProps { }

export interface NoticeState {
    step: number;
}

export class NoticeMain extends React.Component<NoticeProps, NoticeState> {
    lockGeneralRequests: boolean = false;
    lockSystemRequests: boolean = false;

    constructor(props: NoticeProps) {
        super(props);

        this.state = {
            step: -1,
        }
    }

    render() {
        return (
            <div className="panel-body">
                {this.props.havePermission(NOTICE_LIST_PERM) && <NoticesList props={this.props} />}
                {this.props.havePermission(NOTICE_CONFIGURATION_PERM) && <NoticesConfList props={this.props} />}
            </div>
        )
    }
}

export const Notice = withAppContext(NoticeMain);