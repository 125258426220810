import * as React from "react";
import { MainMenuItem } from "../interfaces/Menu";
import { Link, RouteComponentProps } from "react-router-dom";
import { AppContextProps, withAppContext } from "../context/AppContext";

interface MainMenuProps extends RouteComponentProps, AppContextProps {
    menuItems: MainMenuItem[]
 }
interface MainMenuState { }

export class MainMenuMain extends React.Component<MainMenuProps, MainMenuState> {
    render() {
        const menuItems = this.props.menuItems.map((menuItem: MainMenuItem) => {
            if(this.props.havePermission(...menuItem.permissions)) {
                return (
                    <Link key={menuItem.id} className="col-lg-4 col-md-6 col-sm-6 col-xs-12" id={menuItem.id} to={menuItem.path}>
                        <div className={"card " + menuItem.color + " summary-inline"} >
                            <div className="card-body">
                                <i className={"icon fa " + menuItem.icon + " fa-3x"}></i>
                                <div className="content">
                                    <div className="title">{menuItem.title}</div>
                                    <div className="sub-title">{menuItem.text}</div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </Link>
                )
            } else {
                return <div key={menuItem.id}></div>
            }
        });

        return (
            <div className="card">
                <div className="card-body row">{menuItems}</div>
            </div>
        )
    }
}

export const MainMenu = withAppContext(MainMenuMain);